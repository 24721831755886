import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { Spacer } from 'components/Layout';
import Loader from 'components/Loader/Loader';

import React, { Key, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CategoriesQuery, Category, } from 'generated/gql/gql';
import CategoryListItem from '../CategoryListItem';
// import { useCategoriesQuery } from 'features/categories/queries';
type Props = {
    scrollTargetId?: string;
    parent?: Category;
    selected?: Category;
    onSelect: (category: Category, checked?: boolean) => void;
    searchTerm?: string
    query: UseInfiniteQueryResult<CategoriesQuery, unknown>

}
const CategoryList: React.FC<Props> = ({ scrollTargetId, onSelect, query,
    selected, searchTerm }) => {
    const { t } = useTranslation(['translation', 'product']);
    const { data, status, fetchNextPage, hasNextPage } = query

    const modal = document.querySelector('.modal__content');
    useLayoutEffect(() => {
        if (
            hasNextPage &&
            (modal?.scrollHeight || 0) <=
            (modal?.clientHeight || 0)
        ) {

            fetchNextPage();
        }
    }, [hasNextPage]
    );

    return <div className="categories__modal-inner">
        <InfiniteScroll
            scrollableTarget={scrollTargetId}
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            dataLength={data?.pages[0]?.categories?.totalCount || 0}
            loader={<Loader small />}
        >
            {(data?.pages[0]?.categories?.totalCount ?? 0) === 0 &&
                status === 'success' && (
                    <Spacer className="c-black-50">
                        {t('product:categories.error.notFound')}
                    </Spacer>
                )}
            {status === 'error' && (
                <Spacer className="c-black-50">
                    {t('product:categories.error.searchError')}
                </Spacer>
            )}
            {(data?.pages
                .map((i) => i.categories?.edges)
                .flat().length ?? 0) > 0
                && (
                    <ul className="categories__list">
                        {data?.pages
                            .map((page, i) => (
                                <React.Fragment key={('' + page.categories?.pageInfo?.endCursor + i) as Key}>
                                    {page.categories?.edges
                                        .map(({ node: treeItem }) => (
                                            <CategoryListItem
                                                key={treeItem.id}
                                                category={treeItem as Category}
                                                selected={selected}
                                                onSelect={onSelect} />

                                        ))
                                    }
                                </React.Fragment>
                            ))}
                    </ul>
                )}
        </InfiniteScroll>
    </div>
}
export default CategoryList