import classNames from 'classnames';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Headline from 'components/Headline/Headline';
import IconButton from 'components/IconButton/IconButton';
import LabelText from 'components/LabelText/LabelText';
import LabeledInfoText from 'components/LabeledInfoText/LabeledInfoText';
import { Spacer } from 'components/Layout/Layout';
import ListItem from 'components/ListItem/ListItem';
import Modal from 'components/Modal/Modal';
import { ChannelDetailTab } from 'container/ChannelVideoContainer/ChannelVideoContainer';
import { utcToZonedTime } from 'date-fns-tz';
import { isAvailable } from 'features/availability';
import ChannelActivateContainer from 'features/channel-activate';
import VoucherContainer from 'features/voucher';
import {
  ChannelWithVideosDTO,
  KeyValueDTO,
  KeyValueStoreService,
} from 'generated';
import useOpen from 'hooks/useOpen';
import placeholder from 'images/placeholder-video.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useReduxSelector } from 'redux/hooks';
import {
  selectLanguageCode,
  selectSupportedLanguages,
  selectTimezone,
} from 'redux/slices/i18n/i18nSlice';
import Color from 'types/Color';
import { useDeleteChannel } from '../queries';
import VideoList from './VideoList';
import './channel-info.scss';
import { textInfosToEditorData, } from 'components/LabeledInfoText/util';

export interface Props {
  activeItem: ChannelWithVideosDTO;
}

const ChannelInfo = ({ activeItem }: Props) => {
  const deleteChannelMutation = useDeleteChannel(activeItem.id);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const {
    id,
    name,
    iconUrl,
    paginatedVideoDTO,
    availability,
    introVideo,
    channelLocalisations,
    voucher,

  } = activeItem;
  const { t } = useTranslation(['translation', 'channel', 'video']);
  const [available, setAvailable] = useState<boolean>(
    isAvailable(availability),
  );
  const { open, close, isOpen } = useOpen(false);
  const timeZone = useReduxSelector(selectTimezone);
  const languageCode = useReduxSelector(selectLanguageCode);
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  const [info, setInfo] = useState(
    channelLocalisations?.find((i) => i.language === languageCode),
  );
  // TODO take from kvstore Redux
  const [showVoucher, setShowVoucher] = useState<boolean>(false);

  const getVoucherShow = async (): Promise<boolean> => {
    const keyValueDto = await KeyValueStoreService.getKeyValueStoreItem(
      KeyValueDTO.key.CREATOR_SHOW_VOUCHER,
    );

    return keyValueDto.value === 'true';
  };

  useEffect(() => {
    setAvailable(isAvailable(availability));
  }, [availability]);

  useEffect(() => {
    const find = channelLocalisations?.find((i) => i.language === languageCode);
    setInfo(find || channelLocalisations?.[0]);
  }, [languageCode, channelLocalisations]);

  useEffect(() => {
    if (deleteChannelMutation.isSuccess) {
      setDeleteModalIsOpen(false);
    }
  }, [deleteChannelMutation.isSuccess]);

  useEffect(() => {
    const voucher = async () => {
      const showVoucher = await getVoucherShow();
      setShowVoucher(showVoucher);
    };
    voucher();
  }, []);

  return (
    <div className="channel-info">
      <div className="channel-info__controls">
        <div className="channel-info__controls-left">
          <div
            className={classNames(
              'channel-info__av',
              available && 'channel-info__av--active',
            )}
          >
            <IconButton
              icon={available ? 'check' : 'block'}
              color={available ? Color.confirm : Color.warning}
              appearance={'filled'}
              tooltip={t('channel:available.change')}
              tooltipPlace="left"
              onClick={open}
              big
            />

            <Modal
              isOpen={isOpen}
              onClose={close}
              variant="wide"
              headline={t('channel:status.caption')}
            >
              <div className="modal__text">
                {t('channel:status.description')}
              </div>
              <ChannelActivateContainer
                onClose={close}
                channelId={activeItem.id}
                availability={activeItem.availability}
              />
            </Modal>

            <span className="channel-info__av-text">
              {available
                ? t('channel:available.active')
                : t('channel:available.deactive')}
            </span>
          </div>
        </div>
        <div className="channel-info__controls-right">
          <IconButton
            tooltip={t('channel:deleteChannel')}
            icon="delete"
            onClick={() => setDeleteModalIsOpen(true)}
            big
          />
          <ConfirmModal
            isOpen={deleteModalIsOpen}
            headline={t('delete')}
            text={t('channel:really_delete', {
              title: activeItem.name,
            })}
            onCancelClick={() => setDeleteModalIsOpen(false)}
            onConfirmClick={() =>
              deleteChannelMutation.mutate({ id: activeItem.id })
            }
            confirmLoading={deleteChannelMutation.isLoading}
            confirmText={t('delete')}
          />

          <IconButton
            tooltip={t('channel:detail')}
            icon="edit"
            link={`/channels/${id}`}
            appearance="filled"
            big
          />
        </div>
      </div>
      <div className="channel-info__content">
        <div className="channel-info__header">
          <div className="channel-info__image">
            <img src={iconUrl} alt={t('channel:info.logoAlt')} loading="lazy" />
          </div>
          <Headline headingLevel="h2" size={2} className="channel-info__title">
            {name}
          </Headline>
        </div>

        <Spacer tag="section" marginBottom={4}>
          <VideoList paginatedVideoDTO={paginatedVideoDTO} channelId={id} />
        </Spacer>

        {/* <Spacer tag="section" marginBottom={4}>
          <LabelText label={t('video:introVideo')}>
            {introVideo ? (
              <ListItem
                highlightImage
                key={introVideo.id}
                image={
                  introVideo.videoPreviews?.find((i) => i.size === 'small')?.uri
                }
                subtitle={t('video:introVideo')}
                title={introVideo.name}
                language={introVideo.language}
                border={true}
                placeholder={placeholder}
                languageNotSupportedError={
                  !supportedLanguages?.some(
                    (i) => i.iso === introVideo.language,
                  )
                }
                tag="div"
              />
            ) : (
              t('channel:introVideo.empty')
            )}
            <div className="info-video-list__more">
              <Link
                className="info-video-list__more-link"
                to={`/channels/${id}/${ChannelDetailTab.INTRO}`}
              >
                {t('edit')}
              </Link>
            </div>
          </LabelText>
        </Spacer> */}

        {availability && (availability.start || availability.end) && (
          <Spacer tag="section" margin={[4, 0]} borderTop={1} paddingTop={4}>
            <LabelText
              label={`${t('channel:status.caption')} (${isAvailable(availability)
                ? t('channel:available.active')
                : t('channel:available.deactive')
                })`}
              className="channel-info__block--av"
            >
              <dl className="channel-info__avdl">
                {[availability.start, availability.end].map(
                  (date, i) =>
                    date && (
                      <div key={date}>
                        <dt>
                          {`${i === 0
                            ? t('channel:date.start.label')
                            : t('channel:date.end.label')
                            }:`}
                        </dt>
                        <dd>
                          {t('date', {
                            date: date && utcToZonedTime(date, timeZone),
                          })}
                        </dd>
                      </div>
                    ),
                )}
              </dl>
            </LabelText>
          </Spacer>
        )}

        {showVoucher && (
          <Spacer tag="section" margin={[4, 0]} borderTop={1} paddingTop={4}>
            <LabelText label={t('channel:voucher.caption')}>
              <VoucherContainer voucher={voucher} channelId={id} />
            </LabelText>
          </Spacer>
        )}

        <Spacer tag="section" margin={[4, 0]} borderTop={1} paddingTop={4}>
          <LabelText label={t('channel:info.caption.legal')}>

            {info?.legalInfo && info.legalInfo?.blocks.length > 0 ? (
              <LabeledInfoText staticContent={info.legalInfo?.blocks} />
            ) : (
              <p className="channel-info__text channel-info__text--error">
                {t('channel:error')}
              </p>
            )}
          </LabelText>
        </Spacer>

        <Spacer tag="section" marginBottom={8}>
          <LabelText
            label={t('channel:info.caption.description')}
            className="channel-info__block"
          >
            {info?.description && info.description?.blocks.length > 0 ? (
              <LabeledInfoText staticContent={info.description?.blocks} />
            ) : (
              <p className="channel-info__text channel-info__text--error">
                {t('channel:error')}
              </p>
            )}
          </LabelText>
        </Spacer>
      </div>
    </div>
  );
};

export default ChannelInfo;
