import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  AddVendorAgentWithRoleWithoutSuperAdminDTO,
  ApiError,
  ChannelsService,
  LatestAppVersionDTO,
  InternalPaginatedReferralResponseDto,
  VendorReferralService,
  VendorAgentResponseDTO,
  VendorAgentsService,
  VersionService,
} from '../generated';
import { queryClient } from '../index';
import { useReduxSelector } from '../redux/hooks';
import { selectVendorAgent } from '../redux/slices/auth/authSlice';
import useAppStatus from './useAppStatus';
import QUERY_KEYS from 'types/QueryKeys';

export const useGetVendorAgents = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const {
    isLoading: isLoadingVendorAgents,
    isError: isErrorVendorAgents,
    data: vendorAgents = [],
    error: errorVendorAgents,
  } = useQuery<VendorAgentResponseDTO[], ApiError>(
    [QUERY_KEYS.getVendorAgents, vendorAgent?.currentVendor?.id],
    VendorAgentsService.getVendorAgents,
  );

  return {
    isLoadingVendorAgents,
    isErrorVendorAgents,
    vendorAgents,
    errorVendorAgents,
  };
};

export const useAddVendorAgent = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const { setAppStatus } = useAppStatus();

  const mutation = useMutation<
    VendorAgentResponseDTO,
    ApiError,
    AddVendorAgentWithRoleWithoutSuperAdminDTO
  >(VendorAgentsService.addVendorAgent, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries([QUERY_KEYS.getVendorAgents]);
      setAppStatus(t('settings:success.add_vendor_agent'), 'success');
    },
    onError: (error) => {
      if ((error as ApiError).status === 409) {
        setAppStatus(
          t('settings:error.add_vendor_agent_duplicate', {
            email: mutation.variables?.email,
          }),
          'error',
          true,
        );
      } else {
        setAppStatus(t('settings:error.add_vendor_agent'), 'error', true);
      }
    },
  });

  return mutation;
};

export const useGetRoles = () => {
  const {
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
    data: rolesData,
    error: errorRoles,
  } = useQuery(['getRoles'], VendorAgentsService.getRoles);

  return { isLoadingRoles, isErrorRoles, rolesData, errorRoles };
};

export const useGetMinimalChannels = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const {
    isLoading: isLoadingMinimalChannels,
    isError: isErrorMinimalChannels,
    data: minimalChannelsData,
    error: minimalChannelsError,
  } = useQuery(
    [QUERY_KEYS.getMinimalChannels, vendorAgent?.currentVendor?.id],
    ChannelsService.getMinimalChannels,
  );

  return {
    isLoadingMinimalChannels,
    isErrorMinimalChannels,
    minimalChannelsData,
    minimalChannelsError,
  };
};

export const useGetRefLinks = (
  searchTerm?: string,
  source?: string,
  medium?: string,
) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useInfiniteQuery<InternalPaginatedReferralResponseDto, ApiError>(
    [
      QUERY_KEYS.getRefLinks,
      vendorAgent?.currentVendor?.id,
      searchTerm,
      source,
      medium,
    ],
    ({ pageParam }) =>
      VendorReferralService.getReferralLinks(
        pageParam, // offset
        undefined, // limit
        searchTerm,
        source,
        medium,
      ),
    {
      getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
    },
  );
};

export const useGetAppVersions = () => {
  const [latestAndroidVersion, setLatestAndroidVersion] =
    useState<LatestAppVersionDTO>({
      platform: 'android',
      version: '',
    });
  const [latestIOSVersion, setLatestIOSVersion] = useState<LatestAppVersionDTO>(
    {
      platform: 'ios',
      version: '',
    },
  );

  const vendorAgent = useReduxSelector(selectVendorAgent);
  const response = useQuery(
    [QUERY_KEYS.getAppVersions, vendorAgent?.currentVendor?.id],
    VersionService.getLatestAppVersions,
  );

  useEffect(() => {
    response.data?.latestAppVersions.forEach((latestAppVersion) => {
      switch (latestAppVersion.platform) {
        case 'ios':
          setLatestIOSVersion(latestAppVersion);
          break;
        case 'android':
          setLatestAndroidVersion(latestAppVersion);
          break;
        default:
          break;
      }
    });
  }, [response]);

  return {
    ...response,
    data: { ios: latestIOSVersion, android: latestAndroidVersion },
  };
};
