import ProductSelect, {
  ProductOption,
} from 'components/ProductSelect/ProductSelect';
import useVideoFilter from 'features/video-filter/hooks/useVideoFilter';
import { FilterItem } from 'features/video-filter/types';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCode, selectLanguageCodeEnum } from 'redux/slices/i18n/i18nSlice';
import FilterDetail from './FilterDetail';
import { pickProductPrice } from 'api/GraphQL/util';
import { Product, } from 'generated/gql/gql';
import { useMemo } from 'react';
import { useInfiniteProducts } from 'features/product-list';
import { selectSaleorChannel } from 'redux/slices/global/globalSlice';

const ProductFilter = () => {
  const { t } = useTranslation(['translation', 'video']);
  const { setFilter, filter } = useVideoFilter();
  const saleorChannel = useReduxSelector(selectSaleorChannel);


  const languageCode = useReduxSelector(selectLanguageCodeEnum);
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data: { pages = [],
  } = {}, status, fetchNextPage: fetchNext, hasNextPage } = useInfiniteProducts({
    search: debouncedSearchTerm ?? '', languageCode: languageCode,
    saleorChannel: saleorChannel!,

  });
  const language = useReduxSelector(selectLanguageCode);
  const products = useMemo(
    () => pages.map((page) => (page?.products?.edges ?? [])
      .map(({ node }) => node))?.flat()?.filter(Boolean) as Product[] ?? [],
    [pages]
  )
  const handleChange = (newValue: MultiValue<ProductOption>) => {
    setFilter(
      'product',
      newValue?.map(({ value: _value, label }) => {
        const findProduct = products
          .find((i) => i.id === _value);
        const {
          translation,
          isAvailable,
          name,
          // pricing,
          defaultVariant,
          // channelListings,
          externalReference: vendorProductId,
        } = findProduct || {};
        return {
          id: _value,
          name: label,
          // Add product props to fill the custom ProductOption
          // The already selected products, that may not be found in the filtered data array (findProduct = undefined),
          // will be populated by the previous state in the useVideoFilter hook in setFilterByKey
          productProps: findProduct
            ? {
              label: translation?.name ?? name,
              //  getProductTitleByLanguage(
              //   productLocalisations,
              //   language,
              // ),
              price: pickProductPrice({ defaultVariant }),
              // getProductPriceByLanguage(
              //   productLocalisations,
              //   language,
              // ),
              isAvailable,
              subtitle: vendorProductId,
            }
            : undefined,
        } as FilterItem;
      }),
    );
  };

  const value = useMemo(() => (
    (filter?.product
      ?.map(({ id, name, productProps }) => {
        return {
          value: id.toString(),
          ...productProps,
          label: productProps?.label || name,
        };
      })
      .filter((i) => i !== undefined) as ProductOption[]) || []
  )
    , [filter?.product]);

  const infiniteProps = {
    onLoadMore: () => fetchNext(),
    hasNextPage,
    filterOption: () => true,
    ...searchableProps,
  };

  return (
    <FilterDetail>
      <ProductSelect
        {...infiniteProps}
        isMulti
        variant="open"
        products={products}
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={value}
        language={language}
        optionTheme="select"
        name="product-filter"
      />
    </FilterDetail>
  );
};

export default ProductFilter;
