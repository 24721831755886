/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppApiCustomerAuthDto } from './AppApiCustomerAuthDto';

export type AppCustomerPropertiesDto = {
    token: string;
    recommendation: AppCustomerPropertiesDto.recommendation;
    appCustomerKey: string;
    customerNo?: string;
    requestOnboarding?: boolean;
    authApiCustomer?: AppApiCustomerAuthDto | null;
};

export namespace AppCustomerPropertiesDto {

    export enum recommendation {
        DEFAULT = 'default',
        NONE = 'none',
        MINI = 'mini',
        AI = 'ai',
    }


}
