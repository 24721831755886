/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ShipRocketGetOrderAwbDataResponseDto } from './ShipRocketGetOrderAwbDataResponseDto';
import type { ShipRocketGetOrderExtraInfoResponseDto } from './ShipRocketGetOrderExtraInfoResponseDto';
import type { ShipRocketGetOrderOrderInsuranceResponseDto } from './ShipRocketGetOrderOrderInsuranceResponseDto';
import type { ShipRocketGetOrderOthersResponseDto } from './ShipRocketGetOrderOthersResponseDto';
import type { ShipRocketGetOrderProductQcResponseDto } from './ShipRocketGetOrderProductQcResponseDto';
import type { ShipRocketGetOrderProductResponseDto } from './ShipRocketGetOrderProductResponseDto';
import type { ShipRocketGetOrderRefundDetailResponseDto } from './ShipRocketGetOrderRefundDetailResponseDto';
import type { ShipRocketGetOrderReturnPickupDataResponseDto } from './ShipRocketGetOrderReturnPickupDataResponseDto';
import type { ShipRocketGetOrderShipmentsResponseDto } from './ShipRocketGetOrderShipmentsResponseDto';

export type ShipRocketGetOrderResponseDto = {
    id: number;
    channel_id: number;
    channel_name?: string | null;
    base_channel_code?: string | null;
    is_international?: number | null;
    is_document?: number | null;
    channel_order_id?: string | null;
    customer_name?: string | null;
    customer_email?: string | null;
    customer_phone?: string | null;
    customer_address?: string | null;
    customer_address_2?: string | null;
    customer_city?: string | null;
    customer_state?: string | null;
    customer_pincode?: string | null;
    customer_country?: string | null;
    pickup_code?: string | null;
    pickup_location?: string | null;
    pickup_location_id?: number | null;
    pickup_id?: string | null;
    ship_type?: number | null;
    courier_mode?: number | null;
    currency?: string | null;
    country_code?: number | null;
    exchange_rate_usd?: number | null;
    exchange_rate_inr?: number | null;
    state_code?: number | null;
    payment_status?: string | null;
    delivery_code?: string | null;
    total?: number | null;
    total_inr?: number | null;
    total_usd?: number | null;
    net_total?: string | null;
    other_charges?: string | null;
    other_discounts?: string | null;
    giftwrap_charges?: string | null;
    expedited?: number | null;
    sla?: string | null;
    cod?: number | null;
    tax?: number | null;
    total_kerala_cess?: string | null;
    discount?: number | null;
    status: ShipRocketGetOrderResponseDto.status;
    sub_status?: string | null;
    status_code?: number | null;
    master_status?: string | null;
    payment_method?: string | null;
    purpose_of_shipment?: number | null;
    channel_created_at?: string | null;
    created_at: string;
    order_date?: string | null;
    updated_at?: string | null;
    products?: Array<ShipRocketGetOrderProductResponseDto> | null;
    invoice_no?: string | null;
    shipments?: ShipRocketGetOrderShipmentsResponseDto | null;
    awb_data?: ShipRocketGetOrderAwbDataResponseDto | null;
    order_insurance?: ShipRocketGetOrderOrderInsuranceResponseDto | null;
    return_pickup_data?: ShipRocketGetOrderReturnPickupDataResponseDto | null;
    company_logo?: string | null;
    allow_return?: number | null;
    is_return?: number | null;
    is_incomplete?: number | null;
    errors?: Array<string> | null;
    payment_code?: string | null;
    coupon_is_visible?: boolean | null;
    coupons?: string | null;
    billing_city?: string | null;
    billing_name?: string | null;
    billing_email?: string | null;
    billing_phone?: string | null;
    billing_alternate_phone?: string | null;
    billing_state_name?: string | null;
    billing_address?: string | null;
    billing_country_name?: string | null;
    billing_pincode?: string | null;
    billing_address_2?: string | null;
    billing_mobile_country_code?: string | null;
    isd_code?: string | null;
    billing_state_id?: string | null;
    billing_country_id?: string | null;
    freight_description?: string | null;
    reseller_name?: string | null;
    shipping_is_billing?: number | null;
    company_name?: string | null;
    shipping_title?: string | null;
    allow_channel_order_sync?: boolean | null;
    'uib-tooltip-text'?: string | null;
    api_order_id?: string | null;
    allow_multiship?: number | null;
    other_sub_orders?: Array<Record<string, any>> | null;
    others?: ShipRocketGetOrderOthersResponseDto | null;
    is_order_verified?: number | null;
    extra_info?: ShipRocketGetOrderExtraInfoResponseDto | null;
    dup?: number | null;
    is_blackbox_seller?: boolean | null;
    shipping_method?: string | null;
    refund_detail?: ShipRocketGetOrderRefundDetailResponseDto | null;
    pickup_address?: Array<Record<string, any>> | null;
    eway_bill_number?: string | null;
    eway_bill_url?: string | null;
    eway_required?: boolean | null;
    irn_no?: string | null;
    engage?: Record<string, any> | null;
    seller_can_edit?: boolean | null;
    seller_can_cancell?: boolean | null;
    is_post_ship_status?: boolean | null;
    order_tag?: string | null;
    qc_status?: string | null;
    qc_reason?: string | null;
    qc_image?: string | null;
    product_qc?: Array<ShipRocketGetOrderProductQcResponseDto> | null;
    seller_request?: Record<string, any> | null;
    change_payment_mode?: boolean | null;
    etd_date?: string | null;
    out_for_delivery_date?: string | null;
    delivered_date?: string | null;
    remittance_date?: string | null;
    remittance_utr?: string | null;
    remittance_status?: string | null;
    insurance_excluded?: boolean | null;
    can_edit_dimension?: boolean | null;
};

export namespace ShipRocketGetOrderResponseDto {

    export enum status {
        SHIPPED = 'SHIPPED',
        PICKED_UP = 'PICKED UP',
        RTO_INITIATED = 'RTO INITIATED',
        RTO_DELIVERED = 'RTO DELIVERED',
        DELIVERED = 'DELIVERED',
        NEW = 'NEW',
        PICKUP_RESCHEDULED = 'PICKUP RESCHEDULED',
        PICKUP_SCHEDULED = 'PICKUP SCHEDULED',
        PICKUP_EXCEPTION = 'PICKUP EXCEPTION',
        IN_TRANSIT = 'IN TRANSIT',
        REACHED_AT_DESTINATION_HUB = 'REACHED AT DESTINATION HUB',
        MISROUTED = 'MISROUTED',
        OUT_FOR_DELIVERY = 'OUT FOR DELIVERY',
        DELIVERY_DELAYED = 'DELIVERY DELAYED',
        UNDELIVERED = 'UNDELIVERED',
        OUT_FOR_PICKUP = 'OUT FOR PICKUP',
        RTO_IN_TRANSIT = 'RTO IN TRANSIT',
        RTO_OFD = 'RTO_OFD',
        RTO_NDR = 'RTO_NDR',
        CANCELED = 'CANCELED',
        DAMAGED = 'DAMAGED',
        LOST = 'LOST',
        READY_TO_SHIP = 'READY TO SHIP',
        RETURN_DELIVERED = 'RETURN DELIVERED',
        RETURN_IN_TRANSIT = 'RETURN IN TRANSIT',
        RETURN_OUT_FOR_DELIVERY = 'RETURN OUT FOR DELIVERY',
        RETURN_OUT_FOR_PICKUP = 'RETURN OUT FOR PICKUP',
        RETURN_PENDING = 'RETURN PENDING',
        RETURN_PICKED_UP = 'RETURN PICKED UP',
        RETURN_PICKUP_EXCEPTION = 'RETURN PICKUP EXCEPTION',
        RETURN_PICKUP_GENERATED = 'RETURN PICKUP GENERATED',
        RETURN_UNDELIVERED = 'RETURN UNDELIVERED',
    }


}
