import { useInfiniteProducts } from "features/product-list/queries";
import { useDeleteProductMutation, useCreateProductMutation, useUpdateProductMutation, useUpdateProductChannelListingsMutation, ProductCountableConnection, Product } from "generated/gql/gql";
import { useEffect, useMemo, useRef } from "react";
import { useReduxSelector } from "redux/hooks";
import { selectVendorAgent } from "redux/slices/auth/authSlice";
import { selectSaleorChannel } from "redux/slices/global/globalSlice";
import { selectLanguageCodeEnum } from 'redux/slices/i18n/i18nSlice';
import { useDebounce } from "use-debounce";

const useProducts = ({ search: undebounced, setActiveItem, activeItem }: { search: string, activeItem?: Product, setActiveItem?: (param: Product) => void }) => {
    const [search] = useDebounce(undebounced, 500);
    const vendorAgent = useReduxSelector(selectVendorAgent);
    const languageCode = useReduxSelector(selectLanguageCodeEnum);
    const totalCount = useRef(0)

    const saleorChannel = useReduxSelector(selectSaleorChannel);
    const { ...query } = useInfiniteProducts({
        search,
        languageCode,
        saleorChannel: saleorChannel!,

    })
    const { mutate: deleteProduct, isLoading: deleting, ...deleteProductMutationRest } = useDeleteProductMutation({ onSuccess: (data) => { /* query?.remove();*/ query?.refetch() } })
    const { mutate: createProduct, isLoading: creating, ...createProductMutationRest } = useCreateProductMutation({ onSuccess: (data) => { /* query?.remove();*/ query?.refetch() } })
    const { mutate: updateProduct, isLoading: updating, ...updateProductMutationRest } = useUpdateProductMutation({ onSuccess: (data) => { /* query?.remove();*/ query?.refetch() } })
    const { mutate: updateProductChannelListings, isLoading: activating, ...updateProductAvailabilityMutationRest } = useUpdateProductChannelListingsMutation({ onSuccess: (data) => { query?.refetch() } })
    const products = useMemo(() =>
        (query?.data?.pages
            ?.filter(Boolean)
            || [])?.map(({ products }) => (products?.edges ?? [])
                ?.map(({ node }) => node as Product))
            .flat() ?? []
        , [query?.data?.pages])
    const productRecord = useMemo(() => products.reduce((acc, product) => ({ ...acc, [product.id]: product }), {}), [products])
    useEffect(() => {
        if (!activeItem && setActiveItem && products.length) {
            setActiveItem?.(products[0])
        } else if (activeItem && setActiveItem) {
            if (products.find(({ id }) => id === activeItem.id)) {
                setActiveItem?.(products.find(({ id }) => id === activeItem.id)!)
            } else {
                setActiveItem?.(products[0])
            }
        }
    }, [products, activeItem, setActiveItem])
    totalCount.current = (query?.data?.pages?.[0]?.products?.totalCount ?? 0)
    useEffect(() => {
        if (vendorAgent?.currentVendor) {
            query.remove()
            query.refetch()

        }
    }, [vendorAgent?.currentVendor])
    return {
        ...query,
        deleteProduct,
        products,
        totalCount: totalCount.current,
        createProduct,
        updateProduct,
        updateProductAvailability: updateProductChannelListings,
        deleting,
        creating,
        updating,
        activating,
        deleteProductMutationRest,
        createProductMutationRest,
        updateProductMutationRest,
        updateProductAvailabilityMutationRest,
        productRecord
    }
}

export default useProducts