import OrderDetailOutlet from 'features/order-detail';
import { ShareOutlet } from 'features/share';
import { VideoDeleteOutlet } from 'features/video-delete';
import {
  VideoDetailNavigatorOutlet,
  VideoDetailOutlet,
} from 'features/video-detail';
import { VideoFilterOutlet } from 'features/video-filter';
import { VideoLinkProductOutlet } from 'features/video-link-product';
import { VideoTypeOutlet } from 'features/video-type';
import OrdersPage from 'pages/OrdersPage';
import VideosPage from 'pages/VideosPage';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import AddChannelPage from '../pages/AddChannelPage';
import AddProductPage from '../pages/AddProductPage';
import ChannelDetailPage from '../pages/ChannelDetailPage';
import ChannelsPage from '../pages/ChannelsPage';
import Layout from '../pages/Layout';
import LoginPage from '../pages/LoginPage';
import NewPasswordPage from '../pages/NewPasswordPage';
import ProductDetailPage from '../pages/ProductDetailPage';
import ProductsPage from '../pages/ProductsPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import SettingsAdminTab from '../pages/Settings/SettingsAdminTab';
import SettingsProfilTab from '../pages/Settings/SettingsProfilTab';
import SettingsReferralsTab from '../pages/Settings/SettingsReferralsTab';
import SettingsSuperAdminTab from '../pages/Settings/SettingsSuperAdminTab';
import SettingsPage from '../pages/SettingsPage';
import StatisticsPage from '../pages/StatisticsPage';
import StatusTestPage from '../pages/StatusTestPage';
import VoucherPage from 'pages/VoucherPage';

const DetailPageBreadcrumb = ({ match }: BreadcrumbComponentProps) => {
  const { t } = useTranslation();

  return <span>{t('breadcrumbs.detail', { id: match.params.id })}</span>;
};

export type Route = {
  index?: boolean;
  path?: string;
  element: JSX.Element;
  children?: Route[];
  breadcrumb?: (({ match }: BreadcrumbComponentProps) => JSX.Element) | null;
};

const useRoutesObject = (): Route[] => {
  const { t } = useTranslation();

  return [
    {
      path: '/new-password/:token',
      element: <NewPasswordPage />,
    },
    {
      path: '/reset-password',
      element: <ResetPasswordPage />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="/channels" />,
        },
        {
          path: '/products',
          element: <ProductsPage />,
          breadcrumb: t('breadcrumbs.products'),
        },
        {
          path: '/statustest',
          element: <StatusTestPage />,
          breadcrumb: t('breadcrumbs.videos'),
        },
        {
          path: '/products/new',
          element: <AddProductPage />,
          breadcrumb: t('breadcrumbs.new'),
        },
        {
          path: '/products/:id',
          element: <ProductDetailPage />,
          breadcrumb: DetailPageBreadcrumb,
        },
        {
          path: '/channels',
          element: <ChannelsPage />,
          breadcrumb: t('breadcrumbs.channels'),
        },
        {
          path: '/channels/new',
          element: <AddChannelPage />,
          breadcrumb: t('breadcrumbs.new'),
        },
        {
          path: '/channels/:id',
          element: <ChannelDetailPage />,
          breadcrumb: DetailPageBreadcrumb,
        },
        {
          path: '/channels/:id/*',
          element: <ChannelDetailPage />,
          breadcrumb: null,
        },
        {
          path: '/statistics',
          element: <StatisticsPage />,
        },
        {
          path: '/settings',
          element: <SettingsPage />,
          children: [
            {
              index: true,
              element: <Navigate to="/settings/profil" />,
            },
            {
              path: 'profil',
              element: <SettingsProfilTab />,
            },
            {
              path: 'admin',
              element: <SettingsAdminTab />,
            },
            {
              path: 'referrals',
              element: <SettingsReferralsTab />,
            },
            {
              path: 'superAdmin',
              element: <SettingsSuperAdminTab />,
            },
          ],
        },
        {
          path: '/videos',
          element: <VideosPage />,
          breadcrumb: t('breadcrumbs.videos'),
          children: [
            {
              path: ':id',
              element: <VideoDetailNavigatorOutlet />,
              children: [
                {
                  index: true,
                  element: <VideoDetailOutlet />,
                },
                {
                  path: 'share',
                  element: <ShareOutlet />,
                  breadcrumb: t('breadcrumbs.share'),
                },
                {
                  path: 'delete',
                  element: <VideoDeleteOutlet />,
                  breadcrumb: t('breadcrumbs.delete'),
                },
                {
                  path: 'change',
                  element: <VideoLinkProductOutlet />,
                  breadcrumb: t('breadcrumbs.change'),
                },
                {
                  path: 'type',
                  element: <VideoTypeOutlet />,
                  breadcrumb: t('breadcrumbs.type'),
                },
              ],
            },
            {
              path: 'filter',
              element: <VideoFilterOutlet />,
            },
          ],
        },
        {
          path: '/orders',
          element: <OrdersPage />,
          breadcrumb: t('breadcrumbs.orders'),
          children: [
            { path: ':id', element: <OrderDetailOutlet /> },
            // fake path orders/filter used to open filter sidebar in OrderList to avoid two outlets with the same nesting depth on the page
          ],
        },
        // {
        //   path: '/voucher',
        //   element: <VoucherPage />,

        // }
      ],
    },
  ];
};

export default useRoutesObject;
