import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelDetailTab } from 'container/ChannelVideoContainer/ChannelVideoContainer';
import { PaginatedVideoResponseDTO } from 'generated';
import placeholder from 'images/placeholder-video.svg';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCodeEnum, selectSupportedLanguages } from 'redux/slices/i18n/i18nSlice';
// import getProductTitleByLanguage from 'utils/get-product-title-by-language';
import InfoVideoList from 'components/InfoVideoList/InfoVideoList';
import ListItem from 'components/ListItem/ListItem';
import { Product } from 'generated/gql/gql';
import { handleVideoProducts } from 'features/product-info/queries';
import { selectSaleorChannel } from 'redux/slices/global/globalSlice';

const VideoList = ({
  paginatedVideoDTO,
  channelId,
}: {
  channelId: number;
  paginatedVideoDTO: PaginatedVideoResponseDTO;
}) => {
  const { t } = useTranslation(['translation', 'channel', 'video']);
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  const saleorChannel = useReduxSelector(selectSaleorChannel);
  const LanguageCodeEnum = useReduxSelector(selectLanguageCodeEnum);
  const [productMap, setProductMap] = useState<Record<string, Product>>({});
  useEffect(() => {
    if (paginatedVideoDTO) {
      handleVideoProducts(paginatedVideoDTO, LanguageCodeEnum, setProductMap, () => { }, saleorChannel);
    }
  }, [paginatedVideoDTO, LanguageCodeEnum, saleorChannel])

  const moreLink = useMemo(() => {
    if (paginatedVideoDTO.total - paginatedVideoDTO.links.current.limit > 0) {
      return {
        link: `/channels/${channelId}`,
        text: t('video:count', {
          count:
            paginatedVideoDTO.total - paginatedVideoDTO.links.current.limit,
        }),
      };
    }

    if (paginatedVideoDTO.total === 0) {
      return {
        link: `/channels/${channelId}/${ChannelDetailTab.PRODUCT}`,
        text: t('channel:productVideoVideo.add'),
      };
    }
  }, [paginatedVideoDTO, channelId, t]);
  return (
    <InfoVideoList
      moreLink={moreLink}
      label={t('video:videoWithCount', {
        count: paginatedVideoDTO.total,
      })}
      videos={paginatedVideoDTO.data}
      renderItem={(video) => (
        <ListItem
          key={video.id}
          image={video.videoPreviews?.find((i) => i.size === 'small')?.uri}
          title={
            productMap[video.saleorProductId!]?.translation?.name
            ?? productMap[video.saleorProductId!]?.name
            ?? t('video:introVideo')
          }
          subtitle={
            productMap[video.saleorProductId!]?.externalReference
              ? `ID: ${productMap[video.saleorProductId!]?.externalReference} - ${t(
                'video:name.label',
              )}: ${video.name}`
              : `${t('video:name.label')}: ${video.name}`
          }
          language={video.language}
          border={true}

          placeholder={placeholder}
          languageNotSupportedError={
            !supportedLanguages?.some((i) => i.iso === video.language)
          }
        />
      )}
    />
  );
};

export default VideoList;
