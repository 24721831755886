/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BankingDetailDTO } from './BankingDetailDTO';
import type { InternalVendorAddressDto } from './InternalVendorAddressDto';

export type InternalVendorDto = {
    createdAt?: string | null;
    createdBy?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    deletedDate?: string | null;
    id: number;
    threeQProjectId?: number | null;
    bankingDetail?: BankingDetailDTO | null;
    company: string;
    type?: InternalVendorDto.type;
    email: string | null;
    defaultPickupAddressId: number | null;
    defaultPickupAddress?: InternalVendorAddressDto | null;
    defaultInvoiceAddressId: number | null;
    defaultInvoiceAddress?: InternalVendorAddressDto | null;
};

export namespace InternalVendorDto {

    export enum type {
        DEFAULT = 'default',
        SALES = 'sales',
    }


}
