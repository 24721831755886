/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InternalOrderBasicChannelDto } from './InternalOrderBasicChannelDto';
import type { InternalVendorAgentDto } from './InternalVendorAgentDto';

export type InternalVideoDto = {
    id: number;
    name: string;
    status: InternalVideoDto.status;
    productId: number;
    productName: string;
    vendorId: number;
    vendorCompany: string;
    thumbnailUrl?: string | null;
    highResUrl?: string | null;
    uploadedDate: string;
    deletedDate?: string | null;
    nameOfTheUploader: string;
    channels: Array<InternalOrderBasicChannelDto>;
    createdByVendorAgent: InternalVendorAgentDto;
    updatedByVendorAgent: InternalVendorAgentDto | null;
};

export namespace InternalVideoDto {

    export enum status {
        DRAFT = 'draft',
        LIVE = 'live',
        IDLE = 'idle',
    }


}
