import { pickProductIsAvailable } from 'api/GraphQL/util';
import Button from 'components/Button/Button';
import { FlexBox } from 'components/Layout';
import Mediathek from 'components/Mediathek/Mediathek';
import MediathekFooter from 'components/Mediathek/MediathekFooter';
import MediathekHeader from 'components/Mediathek/MediathekHeader';
import MediathekItem from 'components/MediathekItem/MediathekItem';
import Modal from 'components/Modal/Modal';
import Search from 'components/Search/Search';
import { localeStringToLanguageCodeEnum, localeTolanguageCodeEnum } from 'container/ProductDetailContainer/utils';
import VideoUploadContainer from 'container/VideoUploadContainer/VideoUploadContainer';
import { useInfiniteSaleorProducts } from 'features/product-list/queries';
import { useLinkProductToVideo } from 'features/video-link-product';
import {
  ApiError,
  ChannelsService,
  VideoResponseDTO,
  VendorVideoService,
} from 'generated';
import { Product } from 'generated/gql/gql';
import useAppStatus from 'hooks/useAppStatus';
import useList from 'hooks/useList';
import { useListSelection } from 'hooks/useListSelection';
import { useEffect, useMemo, useState } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import videoListReducer from 'reducer/reducer-video-list';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import { logOut, } from 'redux/slices/auth/authSlice';
import {
  selectLocaleIso,
  selectSupportedLanguages,
} from 'redux/slices/i18n/i18nSlice';
import Color from 'types/Color';
import useDebouncedEffect from 'use-debounced-effect';
import notAuthenticated from 'utils/not-authenticated';
import { isSetEqual } from './util';
import { selectSaleorChannel } from 'redux/slices/global/globalSlice';

export interface Props {
  open: boolean;
  onClose: () => void;
  channelId?: number;
  productId?: string
}

const MediathekContainer = ({ open, onClose, channelId, productId }: Props) => {
  const { t } = useTranslation(['translation', 'video', 'channel']);

  const saleorChannel = useReduxSelector(selectSaleorChannel);
  const { listState, listFunctions, activeItem } = useList<VideoResponseDTO>({
    reducer: videoListReducer,
    options: { disableSetActiveItem: true },
  });
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [preselectedVideos, setPreselectedVideos] = useState<number[]>();
  const { data } = useInfiniteSaleorProducts({
    ids: (listState.items?.map((i) => i.saleorProductId) ?? []).filter(Boolean) as string[],
    languageCode: localeStringToLanguageCodeEnum(listState?.items?.[0]?.language),
    saleorChannel: saleorChannel!,
  });
  const productsRecord = useMemo(
    () => (data?.pages?.map(({ products }) => products?.edges?.map(({ node }) => node) ?? []).flat() ?? [])
      .reduce((acc, product) => ({ ...acc, [product.id]: product as Product }), {} as Record<string, Product>),
    [data?.pages]
  )
  const { selectedIds, setSelectedItems, toggleSelectItem } =
    useListSelection(preselectedVideos);
  const [searchTerm, setSearchTerm] = useState('');
  const [channelName, setChannelName] = useState(undefined as string | undefined);
  const linkProductMutation = useLinkProductToVideo();
  const [itemChangedCollapseState, setItemChangedCollapseState] =
    useState(true);
  const [count, setCount] = useState(
    selectedIds.filter((i) => !preselectedVideos?.includes(i)).length || 0,
  );
  const localeIso = useReduxSelector(selectLocaleIso);
  const dispatch = useReduxDispatch();

  const { setAppStatus } = useAppStatus();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);

  const { get, setActiveItem } = listFunctions;

  const getVideos = () => {
    get({
      service: !!channelId ? VendorVideoService.getVideos : VendorVideoService.getVideosV2,
      props: {
        initial: true,
        onSuccess: async (response) => {
          if (channelId) {
            try {
              const res = await ChannelsService.getChannelVideoIds(channelId);
              if (res) {
                setPreselectedVideos(res.videoIds);
              }
            } catch (error) {
              setPreselectedVideos([]);
            }
          } else if (productId) {
            const res = await VendorVideoService.getVideos(0, 0, undefined, undefined, [productId]);
            if (res) {
              const ids = res.data.map((i) => i.id) ?? []
              setPreselectedVideos(ids)
              setSelectedItems(ids);
            }

          }
          return response.data
        },
      },
    });
  };

  useEffect(() => {
    if (channelId) {
      const getChannel = async () => {
        try {
          const res = await ChannelsService.getChannel(channelId);

          setChannelName(res.name);
        } catch (error) {
          if (notAuthenticated(error as ApiError)) {
            dispatch(logOut());
          } else {
            setChannelName(t('no_title'));
          }
        }
      };
      getChannel();
    }
  }, [channelId]);

  useEffect(() => {
    setCount(selectedIds.filter((i) => !preselectedVideos?.includes(i)).length);
  }, [selectedIds, preselectedVideos]);

  useDebouncedEffect(
    () => {
      try {
        listFunctions.get({
          props: {
            initial: true,
          },
          service: () =>
            VendorVideoService.getVideos(
              undefined, // offset
              undefined, // limit
              searchTerm,
              localeIso,
            ),
        });
      } catch (error) {
        console.log(error);
      }
    },
    400,
    [searchTerm],
  );

  const handleLoadMore = () => {
    get({
      service: () =>
        channelId
          ? VendorVideoService.getVideos(
            listState.items?.length, // offset
            undefined, // limit
            searchTerm,
            localeIso,
            productId ? [productId] : undefined
          )
          : VendorVideoService.getVideosV2(
            listState.items?.length, // offset
            undefined, // limit
            undefined,
            undefined,
            [productId!],
          )
      ,
    });
  };

  const handleSelectChange = (id: number) => {
    if (!!channelId && preselectedVideos?.includes(id)) {
      return;
    }
    toggleSelectItem(id);
  };

  const handleSelectAll = () => {
    setSelectedItems(listState.items?.map((i) => i.id) || []);
  };

  const handleSave = async () => {
    if (channelId) {
      const save = async () => {
        try {
          if (channelId) {
            const res = await ChannelsService.addVideos(channelId, {
              videoIds: selectedIds.filter(
                (i) => !preselectedVideos?.includes(i),
              ),
            });
            if (res) {
              onClose();
              setAppStatus(t('channel:videoAdd.success', { count }), 'success');
            }
          }
        } catch (error) {
          setAppStatus(t('channel:videoAdd.error'), 'error');
          console.log(error); //TODO
        }
      };
      save();
    } else if (productId) {
      const idsToAdd = selectedIds.filter(
        (i) => !preselectedVideos?.includes(i),
      );
      const idsToRemove = preselectedVideos?.filter(
        (i) => !(selectedIds.includes(i)),
      ) ?? [];
      const addReqs = idsToAdd.map((id) =>
        linkProductMutation.mutateAsync({
          saleorProductId: productId,
          videoId: id,
        }))
      const removeReqs = idsToRemove.map((id) =>
        linkProductMutation.mutateAsync({
          saleorProductId: null,
          videoId: id,
        }))
      try {
        await Promise.all([...addReqs, ...removeReqs]);
        setAppStatus(t('channel:videoAdd.productSuccess', { count }), 'success');
        onClose();
      } catch (error) {
        setAppStatus(t('channel:videoAdd.productError'), 'error');
        console.log(error); //TODO
      }

    }
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleResetSelection = () => {
    setSelectedItems(
      channelId ? [] : (preselectedVideos ?? []));
  };

  const handleItemCollapsed = () => {
    setItemChangedCollapseState(!itemChangedCollapseState);
  };

  const handleSetActivePlayer = (
    isActive: boolean,
    item?: VideoResponseDTO,
  ) => {
    setActiveItem(isActive ? item : undefined);
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleModalClose}
      variant="full"
      scrollTargetId="infiniteScrollTarget"
      onAfterOpen={getVideos}
      headline={t('channel:mediathek.headline')}
    >
      <MediathekHeader
        title={
          channelId
            ? t('channel:mediathek.title')
            : t('channel:mediathek.productTitle')}
        subtitle={channelName ?? productsRecord[productId!]?.externalReference!}
      >
        <Search
          searchTerm={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          placeholder={t('video:searchPlaceholder')}
        />
        {!channelId ? <Button
          text={t('video:upload.title')}
          onClick={() => { setUploadModalOpen(true) }}
          color={Color.primary}
          appearance="ghost"
        />
          : <Button
            text={t('list:selectAll')}
            onClick={handleSelectAll}
            color={Color.primary}
            appearance="ghost"
          />}
      </MediathekHeader>

      <Flipper
        flipKey={`flip--${listState.items?.length
          }--${itemChangedCollapseState.toString()}-${searchTerm}
        )}`}
        spring="stiff"
        decisionData={itemChangedCollapseState}
      >
        <Mediathek
          count={{
            itemsVisible: listState.items?.length,
            total: listState.totalCount,
          }}
          loading={listState.loading}
          error={listState.error}
          onSelectAll={handleSelectAll}
          hasMore={listState.loadMore}
          onLoadMore={handleLoadMore}
          onResetSelection={handleResetSelection}
          showHowTo={listState.items?.length === 0}
        >
          {listState.items &&
            listState.items.map((item, index) => (
              <MediathekItem
                key={(item?.saleorProductId ?? '') + item.id + index}
                deactivated={
                  !!channelId && (productsRecord[item.saleorProductId!] && !pickProductIsAvailable(productsRecord[item.saleorProductId!]))
                }
                selectable={{
                  selected: !!(channelId ? (
                    selectedIds?.includes(item.id) ||
                    preselectedVideos?.includes(item.id))
                    : selectedIds?.includes(item.id)),
                  onSelectChange: () => handleSelectChange(item.id),
                  disabled: (!!channelId && preselectedVideos?.includes(item.id)) || false,
                }}
                item={item}
                onCollapseChange={handleItemCollapsed}
                activePlayer={activeItem?.id === item.id}
                setActivePlayer={handleSetActivePlayer}
                product={productsRecord[item.saleorProductId!]}
                languageNotSupportedError={
                  !supportedLanguages.some((i) => i.iso === item.language)
                }
              />
            ))}
        </Mediathek>
        <VideoUploadContainer
          onClose={() => {
            getVideos();
            setUploadModalOpen(false);
          }}
          isOpen={uploadModalOpen}
          productId={productId}
        />
        <MediathekFooter>
          <Button
            text={t('cancel')}
            appearance="ghost"
            onClick={handleModalClose}
          />
          <Button
            text={
              channelId ?
                t('channel:addWithCount', {
                  count,
                })
                : t('video:upload.product.update')
            }
            onClick={handleSave}
            disabled={channelId
              ? count === 0
              : isSetEqual(new Set(selectedIds), new Set(preselectedVideos))

            }
          />
        </MediathekFooter>
      </Flipper>
    </Modal>
  );
};

export default MediathekContainer;
