import Chip from 'components/Chip/Chip';
import { OrderStatus, } from 'generated/gql/gql';
import { useTranslation } from 'react-i18next';
import {
  doesOrderActionNeedConfirmation,
  getOrderActionColorByStatus,
} from 'utils/order-action';

const OrderActionChip = ({
  status,
  showActionIcon = true,
  label,
}: {
  status: OrderStatus;
  showActionIcon?: boolean;
  /**  allows another label instead of the status translation. This is neccessary because some statuses have the same translation, but neeed to be differentiated in the filter */
  label?: string;
}) => {
  const { t } = useTranslation(['translation', 'orders']);

  return (
    <Chip
      color={getOrderActionColorByStatus(status)}
      icon={
        doesOrderActionNeedConfirmation(status) && showActionIcon
          ? 'error-plain'
          : undefined
      }
    >
      {label ?? t(`orders:status.${status}`)}
    </Chip>
  );
};

export default OrderActionChip;
