import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Icon, { IconType } from '../Icon/Icon';
import './activate-toggle.scss';
import Tooltip from 'components/Tooltip/Tooltip';

export interface Props {
  active: boolean;
  onToggle: () => void;
  ariaDescribedBy?: string;
  loading?: boolean;
  disabled?: boolean;
  activeIcon?: IconType;
  inactiveIcon?: IconType;
  activeText?: string;
  inactiveText?: string;
  hideText?: boolean;
  appearance?: 'warning' | 'toggle';
  error?: string;
  success?: string;
  textPosition?: 'bottom' | 'top';
  tooltip?: string;
}

const ActivateToggle = ({
  active = true,
  onToggle,
  loading = false,
  disabled,
  ariaDescribedBy,
  activeIcon,
  inactiveIcon,
  activeText,
  inactiveText,
  hideText,
  appearance = 'warning',
  error,
  success,
  textPosition = 'bottom',
  tooltip = ''
}: Props) => {
  const { t } = useTranslation(['video']);
  const desc = ariaDescribedBy || 'activate-description';
  const button = <button
    aria-describedby={desc}
    type="button"
    className="activate-toggle__button"
    onClick={onToggle}
    disabled={disabled || loading}
  >
    <div className="activate-toggle__inner">
      {active ? (
        <div className="activate-toggle__check">
          <Icon icon={activeIcon || 'check'} />
        </div>
      ) : (
        <div className="activate-toggle__block">
          <Icon icon={inactiveIcon || 'block'} />
        </div>
      )}
    </div>
  </button>
  return (
    <div
      className={classNames(
        'activate-toggle',
        active && 'activate-toggle--active',
        appearance && `activate-toggle--${appearance}`,
        textPosition &&
        ` input--msg-${textPosition} activate-toggle--${textPosition}`,
        loading && 'activate-toggle--loading',
        disabled && 'activate-toggle--disabled',
        error && 'activate-toggle--error',
        success && 'activate-toggle--success',
      )}
    >{
        tooltip
          ? <Tooltip content={tooltip} >
            {button}
          </Tooltip>
          : button
      }
      {!hideText && !error && !success && (
        <div className="activate-toggle__desc" id={desc}>
          {active
            ? activeText || t('channel:available.active')
            : inactiveText || t('channel:available.deactive')}
        </div>
      )}

      {error && (
        <div role="alert" className="input__error" id={desc}>
          {error}
        </div>
      )}
      {success && (
        <div role="alert" className="input__error input__success" id={desc}>
          {success}
        </div>
      )}
    </div>
  );
};

export default ActivateToggle;
