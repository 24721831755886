import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { AnimatedRoute } from 'features/animated-routing';
import { ProductAsyncSelectField } from 'features/product-list';
import { Formik, useFormikContext } from 'formik';
import { debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetVideo } from '../../video-detail/queries';
import useLinkProductToVideoWithContext from '../hooks/useLinkProductToVideoWithContext';
import { Product } from 'generated/gql/gql';
import { pickJbeProductId } from 'api/GraphQL/util';
import { useProductForm, useSaleorProduct } from 'api/GraphQL/queries.gql';

const Observer = ({ videoId }: { videoId: number }) => {
  const { values, initialValues, isValid, resetForm } = useFormikContext<{
    product?: Product;
  }>();
  const [lastValues, updateState] = useState(values);
  const linkProductMutation = useLinkProductToVideoWithContext();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    debounce(
      (): void => {
        if (values.product) {
          navigate(`/videos/${videoId}`, { state: { from: 'change' } });
          linkProductMutation.mutate(
            { saleorProductId: values?.product?.id, videoId, productId: pickJbeProductId(values.product) },
            // isError,isSuccess, isLoading will be handled in VideoDetailContainer -> VideoDetailProduct
            // as this is a mutation inside a context provider
          );
        }
      },
      500,
      { maxWait: 1500 },
    ),
    [values.product],
  );

  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, values);
    const valuesEqualInitialValues = isEqual(values, initialValues);

    if (!valuesEqualLastValues) {
      updateState(values);
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues && isValid) {
      handleSubmit();
    }
  }, [values, isValid]);

  useEffect(() => {
    if (linkProductMutation.isError) {
      resetForm();
    }
  }, [linkProductMutation.isError]);

  useEffect(() => {
    linkProductMutation.reset();
  }, []);

  return <ProductAsyncSelectField />;
};

const VideoLinkProduct = ({ videoId }: { videoId: number }) => {
  const { data } = useGetVideo(videoId);
  const { jbeProductId, params, ...product } = useSaleorProduct(data?.saleorProductId!)
  return (
    <AnimatedRoute>
      <div className="video-detail__child video-link-product">
        <ErrorBoundary>
          <Formik
            initialValues={{
              product: product,
            }}
            onSubmit={() => { }}
            enableReinitialize
          >
            {(formik) => <Observer videoId={videoId} />}
          </Formik>
        </ErrorBoundary>
      </div>
    </AnimatedRoute>
  );
};

export default VideoLinkProduct;
