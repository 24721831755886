/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type KeyValueDTO = {
    key: KeyValueDTO.key;
    value?: string;
};

export namespace KeyValueDTO {

    export enum key {
        STATISTICS_SHOW_CLICKS = 'STATISTICS_SHOW_CLICKS',
        REGENERATED_FIREBASE_SHARING_LINKS = 'REGENERATED_FIREBASE_SHARING_LINKS',
        HASHTAGS_PER_ENTITY = 'HASHTAGS_PER_ENTITY',
        CREATOR_SHOW_VOUCHER = 'CREATOR_SHOW_VOUCHER',
        JCP_SHOW_SHOPPING = 'JCP_SHOW_SHOPPING',
        CREATOR_SHOW_ORDERS = 'CREATOR_SHOW_ORDERS',
        ENABLE_DISCOUNT_PRICES = 'ENABLE_DISCOUNT_PRICES',
        CREATOR_SHOW_SHIP_ROCKET_CREATE_SHIPMENT = 'CREATOR_SHOW_SHIP_ROCKET_CREATE_SHIPMENT',
    }


}
