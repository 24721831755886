/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSharingUrlDTO } from '../models/CreateSharingUrlDTO';
import type { SharingUrlDTO } from '../models/SharingUrlDTO';
import type { UpdateSharingUrlDTO } from '../models/UpdateSharingUrlDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SharingService {

    /**
     * Creates a new sharing link for a video in a collection
     * @param requestBody 
     * @returns SharingUrlDTO 
     * @throws ApiError
     */
    public static createSharingUrl(
requestBody: CreateSharingUrlDTO,
): CancelablePromise<SharingUrlDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/sharing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all sharing links for a video
     * @param videoId 
     * @returns SharingUrlDTO 
     * @throws ApiError
     */
    public static getSharingLinks(
videoId: number,
): CancelablePromise<Array<SharingUrlDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/sharing/video/{videoId}',
            path: {
                'videoId': videoId,
            },
        });
    }

    /**
     * Update a SharingToken
     * @param sharingTokenId 
     * @param requestBody 
     * @returns SharingUrlDTO 
     * @throws ApiError
     */
    public static updateSharingToken(
sharingTokenId: number,
requestBody: UpdateSharingUrlDTO,
): CancelablePromise<SharingUrlDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/sharing/{sharingTokenId}',
            path: {
                'sharingTokenId': sharingTokenId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove the specified SharingToken
     * @param sharingTokenId 
     * @returns SharingUrlDTO 
     * @throws ApiError
     */
    public static softRemoveSharingToken(
sharingTokenId: number,
): CancelablePromise<SharingUrlDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/sharing/{sharingTokenId}',
            path: {
                'sharingTokenId': sharingTokenId,
            },
        });
    }

}
