/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AvailabilityDTO = {
    type: AvailabilityDTO.type;
    active?: boolean | null;
    start?: string | null;
    end?: string | null;
};

export namespace AvailabilityDTO {

    export enum type {
        PERMANENT = 'PERMANENT',
        DURATION = 'DURATION',
    }


}
