import { InfiniteData, useMutation } from '@tanstack/react-query';
import { channelListKeys } from 'features/channel-list/queries';
import {
  ApiError,
  ChannelDTO,
  ChannelsService,
  PaginatedChannelWithVideosResponseDTO,
  VoucherDto,
} from 'generated';
import produce from 'immer';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export const useAddVoucher = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useMutation<
    ChannelDTO,
    ApiError,
    { channelId: number; voucher: VoucherDto }
  >({
    mutationFn: ({ channelId, voucher }) =>
      ChannelsService.addVoucherToChannel(channelId, voucher),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<
        InfiniteData<PaginatedChannelWithVideosResponseDTO>
      >(channelListKeys.getChannels(vendorAgent?.currentVendor?.id), (old) =>
        produce(old, (draft) => {
          let findItemIndex: number;
          const findPageIndex = draft?.pages.findIndex((page) => {
            findItemIndex = page.data.findIndex((i) => i.id === data.id);
            return findItemIndex !== undefined;
          });

          if (draft) {
            draft.pages[findPageIndex!].data[findItemIndex!].voucher =
              data.voucher;
          }
        }),
      );
    },
  });
};

export const useEditVoucher = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useMutation<
    ChannelDTO,
    ApiError,
    { channelId: number; voucher: VoucherDto }
  >({
    mutationFn: ({ channelId, voucher }) =>
      ChannelsService.editVoucher(channelId, voucher),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<
        InfiniteData<PaginatedChannelWithVideosResponseDTO>
      >(channelListKeys.getChannels(vendorAgent?.currentVendor?.id), (old) =>
        produce(old, (draft) => {
          let findItemIndex: number;
          const findPageIndex = draft?.pages.findIndex((page) => {
            findItemIndex = page.data.findIndex((i) => i.id === data.id);
            return findItemIndex !== undefined;
          });

          if (draft) {
            draft.pages[findPageIndex!].data[findItemIndex!].voucher =
              data.voucher;
          }
        }),
      );
    },
  });
};

export const useDeleteVoucher = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useMutation<ChannelDTO, ApiError, { channelId: number }>({
    mutationFn: ({ channelId }) => ChannelsService.deleteVoucher(channelId),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<
        InfiniteData<PaginatedChannelWithVideosResponseDTO>
      >(channelListKeys.getChannels(vendorAgent?.currentVendor?.id), (old) =>
        produce(old, (draft) => {
          let findItemIndex: number;
          const findPageIndex = draft?.pages.findIndex((page) => {
            findItemIndex = page.data.findIndex((i) => i.id === data.id);
            return findItemIndex !== undefined;
          });

          if (draft) {
            draft.pages[findPageIndex!].data[findItemIndex!].voucher =
              undefined;
          }
        }),
      );
    },
  });
};
