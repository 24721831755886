import getSymbolFromCurrency from 'currency-symbol-map';
import { Field, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../components/Input/InputField';
import TextAreaField from '../../components/Input/TextAreaField';
import LabeledInfoText from '../../components/LabeledInfoText/LabeledInfoText';
import LabelText from '../../components/LabelText/LabelText';
// import {
//   KeyValueDTO,
//   KeyValueStoreService,
// } from '../../generated';
import { useReduxSelector } from '../../redux/hooks';
import { selectLocaleIso } from '../../redux/slices/i18n/i18nSlice';
import { localeToCurrency } from '../../utils/currency';
import { Spacer } from 'components/Layout';
import { ProductForm } from './utils';
import './product-localisation.scss';
import { selectEnableDiscountPrices } from 'redux/slices/kvstore/kvstoreSlice';
import { InfoFilled } from 'components/Icon/SVG';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
export interface Props {
  index: number;
  currency?: string;
}

const ProductLocalisation = ({ index, currency }: Props) => {
  const { t } = useTranslation(['product']);
  const field = (fieldName: keyof ProductForm['localisations'][number]) =>
    `localisations.[${index}].${fieldName}`;
  const { getFieldProps, setFieldValue } =
    useFormikContext<ProductForm['localisations'][number]>();
  const localeIso = useReduxSelector(selectLocaleIso);
  const enableDiscountPrices = useReduxSelector(selectEnableDiscountPrices);

  const handlePriceChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: keyof ProductForm['localisations'][number],
  ) => {
    setFieldValue?.(
      field(fieldName),
      Math.round(parseFloat(event.target.value) * 100) / 100
    );
  };


  return (
    <>
      <Spacer>
        <Spacer>
          <Field
            component={TextAreaField}
            className="form__title"
            placeholder={t('product:placeholder.title')}
            name={field('name')}
            big
          />
          <LabelText
            label={`${t('product:shop_url')}*`}
            className="form__group"
          >
            <Field
              component={InputField}
              placeholder={t('product:placeholder.shop_url')}
              name={field('shopUrl')}
            />
          </LabelText>

          <div className="form__group">
            <LabelText
              label={`${t('product:price', {
                currency: currency
                  ? getSymbolFromCurrency(currency)
                  : getSymbolFromCurrency(localeToCurrency(localeIso) || ''),
              })}*`}
            >
              <Field
                component={InputField}
                step=".01"
                required
                min="0"
                type="number"
                name={field('price')}
                value={
                  isNaN(getFieldProps(field('price')).value)
                    ? ''
                    : getFieldProps(field('price')).value
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handlePriceChange(e, 'price')
                }
              />

            </LabelText>
            {enableDiscountPrices && (
              <>
                <Spacer marginTop={4} />
                <LabelText label={
                  <>
                    {t('product:originalPrice.label')}
                    <Tooltip content={t('product:originalPrice.tooltip')} >
                      <Icon icon='info-filled' />
                    </Tooltip>
                  </>}>
                  <Field
                    component={InputField}
                    step=".01"
                    min="0"
                    type="number"
                    name={field('originalPrice')}
                  />
                </LabelText>
              </>)}
            <Spacer paddingTop={5} marginTop={8} borderTop={1}>
              <LabelText label={`${t('product:stock')}`} >
                <Field
                  component={InputField}
                  type="number"
                  placeholder={t('product:placeholder.stock')}
                  req
                  wrapperClassName="form__group"
                  name={field('stock')} />
              </LabelText>
            </Spacer>

          </div>
        </Spacer>

        <Spacer paddingTop={5} marginTop={8} borderTop={1}>
          <LabelText label={t('product:info')}>
            <p className="page__form-desc">{t('product:info_text_desc')}</p>
          </LabelText>
          <LabeledInfoText fieldName={field('description')} />
        </Spacer>
      </Spacer>
    </>
  );
};

export default ProductLocalisation;
