import { ApiError, TermsResponseDTO } from '../generated';
import { Action, State, ActionType } from '../hooks/useList';

const termsListReducer = (
  state: State<TermsResponseDTO>,
  action: Action<TermsResponseDTO>,
): State<TermsResponseDTO> => {
  switch (action.type) {
    case ActionType.LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionType.RELOAD:
      return {
        ...state,
        items: undefined,
        loading: true,
      };
    case ActionType.GET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        items:
          state.items && !action.payload.initial
            ? state.items.concat(action.payload.items)
            : action.payload.items,
        error: undefined,
        loading: false,
        loadMore: action.payload.next ? true : false,
        initialyLoaded: true,
      };
    case ActionType.GET_ERROR:
      return {
        ...state,
        error: action.payload.error as ApiError,

        loading: false,
      };
    case ActionType.UPDATE:
      return {
        ...state,
        items: state?.items?.map((item) => {
          if (item.id === action.payload.id) {
            return {
              id: action.payload.id,
              ...action.payload.item,
            } as TermsResponseDTO;
          }
          return item;
        }),
        loading: false,
        error: undefined,
      };
    case ActionType.ADD:
      return {
        ...state,
        items: [action.payload.item, ...(state.items || [])],
        loading: false,
        error: undefined,
      };
    case ActionType.DELETE:
      return {
        ...state,
        items:
          state.items &&
          state.items.filter((item) => item.id !== action.payload.id),
        totalCount: state.totalCount && state.totalCount - 1,
      };
    case ActionType.DELETE_MULTI:
      const newItems =
        state.items &&
        state.items.filter((item) => !action.payload.items?.includes(item.id));
      return {
        ...state,
        items: newItems,
        totalCount: newItems?.length,
      };
    case ActionType.DEACTIVATE:
      return state;
  }
  return state;
};

export default termsListReducer;
