/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EditNotificationDTO = {
    title?: string;
    body?: string;
    imageUrl?: string;
    date?: string;
    ios?: boolean;
    android?: boolean;
    optionKey?: EditNotificationDTO.optionKey;
    optionValue?: string;
    language?: string;
    vendorId?: string;
    analyticsLabel: string;
};

export namespace EditNotificationDTO {

    export enum optionKey {
        SCP = 'scp',
        LINK = 'link',
        CHANNEL_KEY = 'channelKey',
        URL = 'url',
    }


}
