import ProductSelect, {
  ProductOption,
} from 'components/ProductSelect/ProductSelect';
import { FilterDetail, FilterItem, useFilter } from 'features/filter';
import { OrderFilterItem, OrderFilterKeys } from 'features/order-filter/types';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useEffect, useMemo } from 'react';
import { MultiValue } from 'react-select';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCode, selectLanguageCodeEnum } from 'redux/slices/i18n/i18nSlice';
import { pickProductName, pickProductPrice } from 'api/GraphQL/util';
import { useInfiniteProducts } from 'features/product-list';
import { Product } from 'generated/gql/gql';
import { selectSaleorChannel } from 'redux/slices/global/globalSlice';
const ProductFilter = () => {
  const { setFilter, filter, } = useFilter<OrderFilterKeys, OrderFilterItem>();
  const { searchableProps, debouncedSearchTerm, } = useSearchableSelect();
  const language = useReduxSelector(selectLanguageCode);
  const languageCode = useReduxSelector(selectLanguageCodeEnum);

  const saleorChannel = useReduxSelector(selectSaleorChannel);
  const { data: { pages = [] } = {}, error, hasNextPage, fetchNextPage: fetchNext, status } = useInfiniteProducts({
    search: debouncedSearchTerm || ''
    , languageCode: languageCode,
    saleorChannel: saleorChannel!,
  });
  const products = useMemo(
    () => pages.map((page) => (page?.products?.edges ?? [])
      .map(({ node }) => node))?.flat()?.filter(Boolean) as Product[] ?? [],
    [pages]
  )

  useEffect(() => {
    if (error) {
      console.log('effect error', { error })
    }
  }, [error])
  const handleChange = (newValue: MultiValue<ProductOption>) => {
    setFilter(
      'productIds',

      newValue?.map(({ value, label }) => {
        const findProduct = products
          .find((i) => i.id === value);
        const {
          translation,
          isAvailable,
          name,
          // pricing,
          defaultVariant,
          // channelListings,
          externalReference,
        } = findProduct || {};
        return {
          id: value,
          name: label,
          // Add product props to fill the custom ProductOption
          // The already selected products, that may not be found in the filtered data array (findProduct = undefined),
          // will be populated by the previous state in the useVideoFilter hook in setFilterByKey
          productProps: findProduct
            ? {
              label: pickProductName(findProduct),
              //  getProductTitleByLanguage(
              //   translation,
              //   language,
              // ),
              price: pickProductPrice({ defaultVariant }),
              // getProductPriceByLanguage(
              //   translation,
              //   language,
              // ),
              isAvailable,
              subtitle: externalReference,
            }
            : undefined,
        } as FilterItem;
      }),
    );
  };

  const getValue = () => {
    return (
      (filter?.productIds
        ?.map(({ id, name, productProps }) => {
          return {
            value: id.toString(),
            ...productProps,
            label: productProps?.label || name,
          };
        })
        .filter((i) => i !== undefined) as ProductOption[]) || []
    );
  };

  const infiniteProps = {
    onLoadMore: () => fetchNext(),
    hasNextPage: hasNextPage,
    filterOption: () => true,
    ...searchableProps,
  };

  return (
    <FilterDetail>
      <ProductSelect
        {...infiniteProps}
        isMulti
        variant="open"
        products={products}
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={getValue()}
        language={language}
        optionTheme="select"
        name="product-filter"
      />
    </FilterDetail>
  );
};

export default ProductFilter;
