import {
  CountableConnection,
  extractFromCountableConnection,
} from 'api/GraphQL/util';
import { Category } from 'generated/gql/gql';

export const CategoryUtils = {
  extractConnections: (args?: CountableConnection<Category>[]) =>
    Object.values(extractFromCountableConnection<Category>(args)),
  getAncestors: (category?: Category, excludeSelf?: boolean) => {
    if (!category) return [];
    const ancestors = CategoryUtils.extractConnections([
      category?.ancestors as CountableConnection<Category>,
    ]);
    return [...ancestors, ...(excludeSelf ? [] : [category])].filter(Boolean);
  },
} as const;
export default CategoryUtils;
