//TODO insert:  import { InfiniteData, useQuery } from '@tanstack/react-query';
import { ORDER_LIST_KEYS, orderListKeys } from 'features/order-list';
import {
  //   ApiError,
  AppOrderDto,
  //   PaginatedAppOrderDto,
  //   AppOrderService,
} from 'generated';
import { LanguageCodeEnum, useGetOrderQuery } from 'generated/gql/gql';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCodeEnum } from 'redux/slices/i18n/i18nSlice';
// import { useGetOrderQuery } from 'generated/gql/gql';
// import { queryClient } from 'index';
// import { useReduxSelector } from 'redux/hooks';
// import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export interface ExtendedVendorOrderDto extends AppOrderDto {
  manifestLink?: string | null;
  trackingLink?: string | null;
  invoiceLink?: string | null;
  /** YYYY-MM-DD */
  pickupdate?: string | null;
}

// export interface PaginatedExtendedVendorOrderDto extends PaginatedAppOrderDto {
//   data: Array<ExtendedVendorOrderDto>;
// }

export const orderDetailKeys = {
  getOrder: (orderNo: string, vendorAgentId?: number) => {
    const [key, vendorId] = orderListKeys.getOrders(vendorAgentId);
    return [key, vendorId, orderNo];
  },
};
// // export const useGetOrder = (id: string) => useGetOrderQuery({ id });
export const useGetOrder = (orderNo: string, language?: LanguageCodeEnum) => {
  const defaultLanguageCode = useReduxSelector(selectLanguageCodeEnum);
  const query = useGetOrderQuery({
    id: orderNo,
    languageCode: language ?? defaultLanguageCode,
  });
  return query;
  //   const vendorAgent = useReduxSelector(selectVendorAgent);
  //   return useQuery<ExtendedVendorOrderDto, ApiError>({
  //     queryKey: orderDetailKeys.getOrder(orderNo, vendorAgent?.currentVendor?.id),
  //     queryFn: async () => {
  //       const res = await AppOrderService.getByOrderNo(
  //         orderNo,
  //         vendorAgent?.apiKeySecret!,
  //       );
  //       return {
  //         ...res,
  //         trackingLink: null,
  //         manifestLink: null,
  //         invoiceLink: null,
  //       } as ExtendedVendorOrderDto;
  //     },
  //     initialData: () => {
  //       const data = queryClient.getQueryData<
  //         InfiniteData<PaginatedExtendedVendorOrderDto>
  //       >([ORDER_LIST_KEYS.getOrders, vendorAgent?.currentVendor?.id]);
  //       return data?.pages
  //         .map((d) => d.data)
  //         .flat()
  //         .find((i) => i.orderNo === orderNo);
  //     },
  //     staleTime: 2 * 60 * 1000, // 2 minutes
  //   });
};
