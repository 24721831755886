import SelectInteractive, {
  OptionWithNumberAndIcon,
  OptionWithNumberAndIconProps,
  ValueContainerIconLabelValue,
} from 'components/SelectInteractive';
import { VendorDTO, VendorResponseDTO } from 'generated';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { AuthVendorAgentType } from 'redux/slices/auth/authSlice';
import VendorSelectMenu from './components/VendorSelectMenu';
import './vendor-select.scss';

export type Props = {
  onVendorChange: (newVendorId: number) => void;
  vendors?: VendorResponseDTO[];
  vendorAgent: AuthVendorAgentType;
  isSuperAdmin: boolean;
};

const VendorSelect = ({
  onVendorChange,
  vendors,
  isSuperAdmin,
  vendorAgent,
}: Props) => {
  const { t } = useTranslation();
  const handleCurrentVendorChange = async (
    value: SingleValue<OptionWithNumberAndIconProps>,
  ) => {
    if (value) {
      onVendorChange(parseInt(value?.value));
    }
  };

  const [showSalesVendors, setShowSalesVendors] = useState(true);
  const [showDefaultVendors, setShowDefaultVendors] = useState(true);

  const handleSalesVendorCheckboxChange = useCallback(() => {
    setShowSalesVendors((prev) => !prev);
  }, []);

  const handleDefaultVendorCheckboxChange = useCallback(() => {
    setShowDefaultVendors((prev) => !prev);
  }, []);

  const renderVendorSelectMenu = useCallback(
    (props) => {
      return (
        <VendorSelectMenu
          {...props}
          showSalesVendors={showSalesVendors}
          showDefaultVendors={showDefaultVendors}
          handleSalesVendorCheckboxChange={handleSalesVendorCheckboxChange}
          handleDefaultVendorCheckboxChange={handleDefaultVendorCheckboxChange}
          salesVendorLabel={t('vendorToggle.sales')}
          defaultVendorLabel={t('vendorToggle.default')}
        />
      );
    },
    [
      showSalesVendors,
      showDefaultVendors,
      handleSalesVendorCheckboxChange,
      handleDefaultVendorCheckboxChange,
      t,
    ],
  );

  if (isSuperAdmin && vendors) {
    return (
      <SelectInteractive<OptionWithNumberAndIconProps>
        className="vendor-select"
        onChange={handleCurrentVendorChange}
        options={vendors
          .filter((vendor) => {
            if (showSalesVendors && vendor.type === VendorDTO.type.SALES) {
              return true;
            }

            if (showDefaultVendors && vendor.type === VendorDTO.type.DEFAULT) {
              return true;
            }

            return false;
          })
          .map((vendor) => ({
            label: vendor.company,
            value: vendor.id.toString(),
            icon: vendorAgent?.vendor?.id === vendor.id ? 'home' : undefined,
            number: vendor.id,
          }))}
        title={t('vendorToggle.title')}
        value={
          vendorAgent?.currentVendor
            ? {
              label: vendorAgent?.currentVendor?.company,
              value: vendorAgent?.currentVendor?.id?.toString(),
              icon:
                vendorAgent?.currentVendor?.id === vendorAgent?.vendor?.id
                  ? 'home'
                  : undefined,
            }
            : null
        }
        components={{
          Option: OptionWithNumberAndIcon,
          ValueContainer: ValueContainerIconLabelValue,
          Menu: renderVendorSelectMenu,
        }}
        appearance="dark"
        controlShouldRenderValue
      />
    );
  }

  return (
    <div className="vendor-select__name">
      {vendorAgent?.currentVendor?.company}
    </div>
  );
};

export default VendorSelect;
