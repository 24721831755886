import Error from '../../types/Error';
import Headline from '../Headline/Headline';
import './not-found.scss';

export interface Props {
  children?: React.ReactNode;
  error: Error;
}

const NotFound = ({ children, error }: Props) => {
  return (
    <div className="not-found">
      <Headline headingLevel="h1" className="not-found__headline">
        {error.status}
      </Headline>
      {(error.message || error.statusText) && <div className="not-found__text">{error.message || error.statusText}</div>}
      <div className="not-found__content">{children}</div>
    </div>
  );
};

export default NotFound;
