import { InfiniteData } from '@tanstack/react-query';
import classNames from 'classnames';
import { Spacer } from 'components/Layout';
import TreeSelect from 'components/TreeSelect';
import { Category, } from 'generated/gql/gql';
// import { Category } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
type Props = {
    onSelectChange: (value: Category) => void;
    selected?: Category;
    parent?: Category;
    status: string;
    scrollTargetId: string;
    onClose: () => void;
    onOpenPanel?: (panelId: string) => void;
}
const CategoryTree: React.FC<Props> = ({
    onSelectChange,
    scrollTargetId,
    parent,
    status,
    onClose,
    onOpenPanel,
    selected
}) => {
    const { t } = useTranslation(['translation', 'product']);
    const isDesktop = useMediaQuery(`(min-width:${BREAKPOINTS.m} )`);

    return <div
        className={classNames(
            'categories__tree',
            isDesktop && 'categories__modal-inner',
        )}
    >
        {!parent && status === 'success' && (
            <Spacer marginLeft={4} className="c-black-50">
                {t('product:categories.error.treeNotFound')}
            </Spacer>
        )}
        {status === 'error' && (
            <Spacer marginLeft={4} className="c-black-50">
                {t('product:categories.error.treeSearchError')}
            </Spacer>
        )}
        {parent && (
            <TreeSelect
                value={selected}
                onChange={(items) => {
                    onSelectChange(items as Category);
                    // openSubtree(items as Category);
                }
                }
                parent={parent}
                mobileNavigationProps={{
                    onClose,
                    closeIcon: 'arrow-left',
                    parentCenterSlot: (
                        <span className="caption--lavender">{parent.name}</span>
                    ),
                }}
                scrollTargetId={scrollTargetId}
                onOpenPanel={onOpenPanel}
            />
        )}
    </div>

}
export default CategoryTree