import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import useRoutesObject from '../../hooks/useRoutesObject';
import { useReduxSelector } from '../../redux/hooks';
import { selectVendorAgent } from '../../redux/slices/auth/authSlice';
import Icon from '../Icon/Icon';
import './breadcrumbs.scss';

/**TODO add new Breadcrumb implematation of react-router v6 */
const Breadcrumbs = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const routes = useRoutesObject();
  const [removeFirst, ...breadcrumbs] = useBreadcrumbs(routes);

  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__link">
        <span className="breadcrumbs__link-text">
          <span className="breadcrumbs__vendor">
            <Icon icon="home" />
            {vendorAgent && vendorAgent?.currentVendor?.company}
          </span>
        </span>
      </div>
      {breadcrumbs.map(({ breadcrumb, match }, key) =>
        key + 1 === breadcrumbs.length ? (
          <span className="breadcrumbs__active" key={key}>
            {breadcrumb}
          </span>
        ) : (
          <NavLink key={key} to={match.pathname} className="breadcrumbs__link">
            <span className="breadcrumbs__link-text">{breadcrumb}</span>
          </NavLink>
        ),
      )}
    </div>
  );
};
export default Breadcrumbs;
