/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppCustomerDeviceDTO = {
    instanceId: string;
    appInstanceId?: string;
    appsflyerId?: string;
    language?: string;
    referral?: string;
    platform: string;
    appBuildVersion?: string;
    recommendation?: AppCustomerDeviceDTO.recommendation;
};

export namespace AppCustomerDeviceDTO {

    export enum recommendation {
        DEFAULT = 'default',
        NONE = 'none',
        MINI = 'mini',
        AI = 'ai',
    }


}
