import { ProductType, useProductTypesQuery } from 'generated/gql/gql';

const useProductTypes = () => {
  const { data } = useProductTypesQuery({}, { retry: false });
  const productTypes = (data?.productTypes?.edges
    ?.flatMap((edge) => edge?.node)
    .filter(Boolean) ?? []) as ProductType[];
  return productTypes;
};
export default useProductTypes;
