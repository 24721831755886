import React from 'react';
import classNames from 'classnames';
import './labeled-info-text.scss';
import LabeledInfoTextFormComponent from './LabeledInfoTextFormComponent';
import { EditorDataType, EditorDataListType, EditorData } from './util';

export interface Props {
  className?: string;
  fieldName?: string;
  staticContent?: EditorData[];
  error?: string;
}
const ListWrapper: React.FC<{ type: EditorDataListType, classString: string }> = ({ type, children, classString, ...rest }) =>
  type === EditorDataListType.ordered
    ? <ol className={`${classString}__list`} children={children} {...rest} />
    : <ul className={`${classString}__list`} children={children} {...rest} />
const LabeledInfoText = ({
  fieldName,
  className,
  error,
  staticContent,
}: Props) => {
  const classString = 'labeled-info-text';
  return (
    <>
      {error && <span className={`${classString}__error`}>{error}</span>}
      {staticContent ? (
        <div className={classNames(classString, className)}>
          {staticContent.map((block, index) => (
            <div key={index} className={`${classString}__block`}>
              {block.type === EditorDataType.header && (
                <h3 className={`${classString}__header`}>{block.data.text}</h3>
              )}
              {block.type === EditorDataType.paragraph && (
                <p className={`small ${classString}__paragraph`}>{block.data.text}</p>
              )}
              {
                block.type === EditorDataType.list && (
                  <ListWrapper type={block.style} classString={classString} >
                    {block.data.items.map((item, i) => (
                      <li key={i} className={`${classString}__list-item`}>
                        {item}
                      </li>
                    ))}
                  </ListWrapper>
                )
              }
            </div>
          ))}
        </div>
      ) : (
        fieldName && (
          <LabeledInfoTextFormComponent
            name={fieldName}
            className={className}
          />
        )
      )}
    </>
  );
};

export default LabeledInfoText;
