import ChannelHeader from 'components/ChannelHeader/ChannelHeader';
import { Section } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import PageCollapse from 'components/PageCollapse/PageCollapse';
import usePageCollapse from 'components/PageCollapse/usePageCollapse';
import { isAvailable, validateAvailability } from 'features/availability';
import { Form, Formik, FormikProps } from 'formik';
import { ApiError, ChannelsService, TextInfoDTO } from 'generated';
import { AvailabilityDTO } from 'generated/models/AvailabilityDTO';
import useAppStatus from 'hooks/useAppStatus';
import useOpen from 'hooks/useOpen';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import { logOut } from 'redux/slices/auth/authSlice';
import { selectLanguageCode } from 'redux/slices/i18n/i18nSlice';
import 'styles/layout/_form.scss';
import ChannelType from 'types/ChannelType';
import Error from 'types/Error';
import notAuthenticated from 'utils/not-authenticated';
import * as Yup from 'yup';
import ChannelForm from './ChannelForm';
import { emptyChannel, formatValues, setupChannelLocalisations } from './utils';
import { EditorData, isEmpty } from 'components/LabeledInfoText/util';

export interface Props {
  channelId?: number;
  edit?: boolean;
  onLoadError?: (error: Error) => void;
  onLoadSuccess?: () => void;
}

/** @todo Could also be made with react-query in the channel-detail feature */
const ChannelDetailForm = ({
  channelId,
  edit,
  onLoadError,
  onLoadSuccess,
}: Props) => {
  const navigate = useNavigate();
  // TODO reset values when availability is updated from outside
  const [initialValues, setInitialValues] = useState<ChannelType>();
  const { pageCollapseIsOpen, togglePageCollapse, closePageCollapse } =
    usePageCollapse(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingButton, setLoadingButton] = useState<{
    save?: boolean;
    delete?: boolean;
    activate?: boolean;
  }>({});
  const {
    isOpen: cancelIsOpen,
    open: cancelOpen,
    close: cancelClose,
  } = useOpen(false);
  const { isOpen, open, close } = useOpen(false);
  const { t } = useTranslation(['translation', 'channel', 'edit']);
  const { setAppStatus } = useAppStatus();
  const dispatch = useReduxDispatch();
  const languageCode = useReduxSelector(selectLanguageCode);

  const nameSchema = Yup.object().shape({
    name: Yup.string().required(t('form.required')).ensure(),
    channelLocalisations: Yup.array().of(
      Yup.object().shape({
        legalInfo: Yup.object().shape({
          blocks: Yup.array().test(
            'labeled-input',
            t('form.labeledInputRequired'),
            function (value?: EditorData[]): boolean {
              if (value) {
                return (
                  value.length > 0 &&
                  value.filter(
                    (v: EditorData) =>
                      !(
                        isEmpty(v)
                      ),
                  ).length > 0
                );
              }
              return false;
            },
          )
        }),
      }),
    ),
    availability: validateAvailability(t('channel:available.endBeforeStart')),
    iconUrl: Yup.string()
      .nullable()
      .when('availability', {
        is: (availability: AvailabilityDTO) => isAvailable(availability),
        then: Yup.string()
          .nullable()
          .required(t('form.iconRequiredWhenAvailable')),
      }),
  });

  useEffect(() => {
    if (edit) {
      if (channelId) {
        const getChannel = async () => {
          try {
            const res = await ChannelsService.getChannel(channelId);

            if (res) {
              setLoading(false);
              setInitialValues({
                ...res,
                channelLocalisations: setupChannelLocalisations(
                  res.channelLocalisations || [],
                  languageCode,
                ),
                availability: res.availability
                  ? {
                    ...res.availability,
                  }
                  : undefined,
              });
              onLoadSuccess && onLoadSuccess();
            }
          } catch (error) {
            if (notAuthenticated(error as ApiError)) {
              dispatch(logOut());
            } else {
              setLoading(false);
              onLoadError && onLoadError(error as ApiError);
            }
          }
        };
        getChannel();
      }
    } else {
      setLoading(false);
    }
  }, [dispatch, channelId]);

  const handleUpdateChannel = (
    newValues: ChannelType,
    formik?: FormikProps<ChannelType>,
  ) => {
    if (channelId) {
      setLoadingButton({ delete: false, save: true });

      const updateChannel = async () => {
        try {
          const res = await ChannelsService.updateChannel(
            channelId,
            formatValues(newValues),
          );

          if (res) {
            setAppStatus(t('channel:save.success'), 'success');
            setLoadingButton({});
            formik && formik.resetForm({ values: res });
            closePageCollapse();
          }
        } catch (error) {
          if (notAuthenticated(error as ApiError)) {
            dispatch(logOut());
          } else {
            setAppStatus(t('channel:save.error'), 'error');
            setLoadingButton({});
          }
        }
      };
      updateChannel();
    }
  };

  const handleDelete = () => {
    if (channelId) {
      setLoadingButton({ delete: true, save: false });
      const deleteChannel = async () => {
        try {
          const res = await ChannelsService.removeChannel(channelId);

          close();
          setLoadingButton({});
          navigate('/channels');

          setAppStatus(t('channel:delete.success'), 'success', true);
        } catch (error) {
          if (notAuthenticated(error as ApiError)) {
            dispatch(logOut());
          } else {
            close();
            setLoadingButton({});
            setAppStatus(t('channel:delete.error'), 'error');
          }
        }
      };
      deleteChannel();
    }
  };

  const handleAddChannel = async (values: ChannelType) => {
    setLoadingButton({ delete: false, save: true });
    try {
      const res = await ChannelsService.addChannel(formatValues(values));

      if (res) {
        setLoadingButton({});
        setAppStatus(t('channel:add.success'), 'success', true);
        navigate(`/channels/${res.id}`);
      }
    } catch (error) {
      if (notAuthenticated(error as ApiError)) {
        dispatch(logOut());
      } else {
        setLoadingButton({});
        setAppStatus(t('channel:add.error'), 'error');
      }
    }
  };

  const handleUpdateIconUrl = (
    values: ChannelType,
    {
      fileUrl,
      filePath,
    }: { fileUrl?: string | null; filePath?: string | null },
  ) => {
    handleUpdateChannel({ ...values, iconUrl: fileUrl, iconPath: filePath });
  };

  const handlePageCollapse = (formik: FormikProps<ChannelType>) => {
    if (pageCollapseIsOpen) {
      cancelClose();
      formik.resetForm();
    }
    togglePageCollapse();
  };

  const handleCancel = (formik: FormikProps<ChannelType>) => {
    if (Object.keys(formik.touched).length > 0 && !edit) {
      cancelOpen();
    } else if (edit) {
      handlePageCollapse(formik);
    } else {
      navigate('/channels');
    }
  };

  return (
    <Section appearance="full">
      <PageCollapse open={edit ? pageCollapseIsOpen : true}>
        {edit && pageCollapseIsOpen && loading && <Loader />}
        <Formik
          initialValues={initialValues || emptyChannel(languageCode)}
          onSubmit={(values: ChannelType) =>
            edit ? handleUpdateChannel(values) : handleAddChannel(values)
          }
          validationSchema={nameSchema}
          enableReinitialize={true}
        >
          {(formik: FormikProps<ChannelType>) => (
            <Form className="form">
              <ChannelHeader
                onUpdateIconUrl={handleUpdateIconUrl}
                edit={edit ? pageCollapseIsOpen : true}
                onEdit={togglePageCollapse}
                onUpdateItem={(availability?: AvailabilityDTO) =>
                  handleUpdateChannel(
                    { ...formik.values, availability },
                    formik,
                  )
                }
              />

              {(!edit || pageCollapseIsOpen) && (
                <ChannelForm
                  onDeleteClick={handleDelete}
                  onCancelClick={() => handleCancel(formik)}
                  loadingButton={loadingButton}
                  onCancelComfirm={
                    edit ? () => handlePageCollapse(formik) : undefined
                  }
                  deleteModalIsOpen={isOpen}
                  openDeleteModal={open}
                  closeDeleteModal={close}
                  cancelModalIsOpen={cancelIsOpen}
                  closeCancelModal={cancelClose}
                  edit={edit}
                />
              )}
            </Form>
          )}
        </Formik>
      </PageCollapse>
    </Section>
  );
};

export default ChannelDetailForm;
