import Button from 'components/Button/Button';
import ChannelController from 'components/ChannelController/ChannelController';
import useChannelController from 'components/ChannelController/useChannelController';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import IconButton from 'components/IconButton/IconButton';
import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout/Layout';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { HashtagAsyncField } from 'features/hashtags';
import { SelectSupportedLanguages } from 'features/language';
import { useFormikContext } from 'formik';
import {
  ChannelMinimalResponseDTO,
  HashtagResponseDTO,
  VideoResponseDTO,
} from 'generated';
import useOpen from 'hooks/useOpen';
import React, { FormEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { useReduxSelector } from 'redux/hooks';
import { selectSupportedLanguages } from 'redux/slices/i18n/i18nSlice';
import 'styles/layout/_form.scss';
import Color from 'types/Color';
import { isEqual } from 'lodash';

export interface Props {
  onChange: (
    field: keyof VideoResponseDTO,
    value: VideoResponseDTO[keyof VideoResponseDTO],
  ) => void;
  onSubmit: (
    channelEdit?: {
      channelsToAdd?: ChannelMinimalResponseDTO[];
      channelsToRemove?: ChannelMinimalResponseDTO[];
    },
    newChannelList?: ChannelMinimalResponseDTO[],
    newHashtags?: HashtagResponseDTO[],
  ) => void;
  onDelete: () => void;
  values: VideoResponseDTO;
  allChannels?: ChannelMinimalResponseDTO[];
  loadingButton?: {
    delete?: boolean;
    save?: boolean;
  };
}

const ProductVideoEdit = ({
  onChange,
  onSubmit,
  onDelete,
  values,
  allChannels,
  loadingButton,
}: Props) => {
  const { t } = useTranslation(['translation', 'video', 'product']);
  const { isOpen, close, open } = useOpen(false);
  let initialChannels = useRef<ChannelMinimalResponseDTO[]>();
  const { channels, revertToInitialChannels, removeChannel, addChannel } =
    useChannelController(values.channels);
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  // TODO move all values to Formik!
  const { values: formikValues, initialValues } =
    useFormikContext<{ hashtags?: HashtagResponseDTO[] }>();

  useEffect(() => {
    if (!initialChannels.current) {
      initialChannels.current = values.channels;
    }
  }, [values.channels]);

  useEffect(() => {
    onChange('channels', channels);
  }, [channels]);

  useEffect(() => {
    const valuesEqualLastValues = isEqual(formikValues, initialValues);

    // fixes infinite loop
    if (!valuesEqualLastValues) {
      onChange('hashtags', formikValues.hashtags);
    }
  }, [formikValues.hashtags]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const channelsToAdd = values?.channels?.filter(
      (e) => !initialChannels.current?.includes(e),
    );
    const channelsToRemove = initialChannels.current?.filter(
      (e) => !values?.channels?.includes(e),
    );

    onSubmit(
      !channelsToAdd && !channelsToRemove
        ? undefined
        : {
          channelsToAdd,
          channelsToRemove,
        },
      channels,
    );
  };

  const handleDelete = () => {
    close();
    onDelete();
  };

  const handleRemoveChannelFromList = (channelId: number) => {
    removeChannel(channelId);
  };

  const handleRevertChannelList = () => {
    revertToInitialChannels();
  };
  const handleAddChannelToList = (channel: ChannelMinimalResponseDTO) => {
    addChannel(channel);
  };

  return (
    <form
      className="form form--sidebar"
      onSubmit={(event) => handleSubmit(event)}
    >
      <VideoPlayer
        {...values}
        error={
          supportedLanguages.some((i) => i.iso === values.language)
            ? undefined
            : {
              badge: t('video:languageNotSupported.badge'),
              text: t('video:languageNotSupported.text'),
            }
        }
        isVisible={values.visibility === VideoResponseDTO.visibility.PUBLIC}
        isAvailable={values.status === VideoResponseDTO.status.LIVE}
      >
        <IconButton
          tooltip={t('video:deleteVideo')}
          onClick={open}
          appearance="ghost"
          icon="delete"
          big
        />
        <Button
          text={t('video:save')}
          type="submit"
          loading={loadingButton?.save}
          color={Color.primary}
          appearance="ghost"
        />

        <ConfirmModal
          isOpen={isOpen}
          headline={t('video:deleteVideo')}
          text={t('video:really_delete', {
            title: values.name,
          })}
          onCancelClick={close}
          onConfirmClick={handleDelete}
          confirmText={t('delete')}
          confirmLoading={loadingButton?.delete}
        />
      </VideoPlayer>

      <TextareaAutosize
        placeholder={t('video:placeholder.title')}
        className="form__title"
        value={values.name.replace(/(\r\n|\n|\r)/gm, ' ')}
        name="name"
        onChange={(event) => onChange('name', event.target.value)}
      />

      <div className="form__block form__block--noborder">
        <SelectSupportedLanguages
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            onChange('language', event.target.value)
          }
          value={values.language}
        />
      </div>

      <Spacer marginTop={8} paddingTop={5} borderTop={1}>
        <LabelText label={t('product:hashtag.label')}>
          <Spacer marginBottom={2} tag="p" className="form__description">
            {t('video:hashtag.desc')}
          </Spacer>
          <HashtagAsyncField />
        </LabelText>
      </Spacer>

      {values.channels && (
        <div className="form__block">
          <LabelText label={t('video:channels')}>
            <div className="form__description">
              {t('video:addChannelDescription')}
            </div>
            <ChannelController
              channels={channels}
              onDeleteItem={handleRemoveChannelFromList}
              onRevert={handleRevertChannelList}
              onAddChannel={handleAddChannelToList}
              allChannels={allChannels}
              className="form__block form__block-noborder"
            />
          </LabelText>
        </div>
      )}
    </form>
  );
};

export default ProductVideoEdit;
