import { Navigate, useParams } from 'react-router-dom';
import VideoLinkProduct from '../VideoLinkProduct/VideoLinkProduct';
import { useVideoOutletContext } from 'features/video-detail';

/** Route /videos/:id/change */
const VideoLinkProductOutlet = () => {
  const { id } = useParams();
  const videoId = id ? parseInt(id) : 0;
  const { visited } = useVideoOutletContext();
  return visited ? (
    <VideoLinkProduct videoId={videoId} />
  ) : (
    <Navigate to={`/videos/${videoId}`} />
  );
};

export default VideoLinkProductOutlet;
