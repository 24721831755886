import { useDebouncedEffect } from 'use-debounced-effect';
import { useInfiniteSaleorProducts } from 'features/product-list/queries';
import { ChannelsService, SharingUrlDTO, VideoResponseDTO } from 'generated';
import { Product } from 'generated/gql/gql';
import { BREAKPOINTS } from 'global-constants';
import useList from 'hooks/useList';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useEffect, useMemo, useState } from 'react';
import videoListReducer from 'reducer/reducer-video-list';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCodeEnum } from 'redux/slices/i18n/i18nSlice';
import useOpen from 'hooks/useOpen';
import { handleVideoProducts } from 'features/product-info/queries';
import { selectSaleorChannel } from 'redux/slices/global/globalSlice';

const useChannelProductVideos = (channelId?: number) => {
  const [searchTerm, setSearchTerm] = useState('');
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const [productMap, setProductMap] = useState<Record<string, Product>>({});
  const { isOpen, open, close } = useOpen(isDesktop);

  const saleorChannel = useReduxSelector(selectSaleorChannel);
  const [mediathekOpen, setMediathekOpen] = useState<boolean>(false);
  const languageCodeEnum = useReduxSelector(selectLanguageCodeEnum);
  const [showHowTo, setShowHowTo] = useState<boolean>(false);
  const [deleteCount, setDeleteCount] = useState<number>(0);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const searchLocale = useReduxSelector((state) => state.i18n.localeIso);
  const updateProductMap = (partial: typeof productMap) =>
    setProductMap((old) => ({ ...old, ...partial }));
  const { listState, listFunctions, activeItem } = useList<VideoResponseDTO>({
    reducer: videoListReducer,
  });
  const { get, deleteItems, setActiveItem, setLoading, updateVideoItem } =
    listFunctions;
  const saleorIds = useMemo(
    () =>
      (listState.items ?? [])
        .map((i) => i.saleorProductId)
        .filter(Boolean) as string[],
    [listState.items],
  );
  const {
    data: { pages } = {},
    fetchNextPage,
    hasNextPage,
    ...rest
  } = useInfiniteSaleorProducts({
    ids: saleorIds.filter((id) => !productMap[id]),
    languageCode: languageCodeEnum,

    saleorChannel: saleorChannel!,
  });
  useEffect(() => {
    if (channelId) {
      get({
        service: async () => await ChannelsService.getChannelsVideos(channelId),

        props: {
          initial: true,
          onSuccess: async (res) => {
            await handleVideoProducts(
              res,
              languageCodeEnum,
              updateProductMap,
              setLoading,
              saleorChannel,
            );
            if (isDesktop && res.data[0]) {
              setActiveItem(res.data[0]);
            }

            if (res.data.length === 0) {
              setShowHowTo(true);
            }
          },
        },
      });
    }
  }, [channelId, languageCodeEnum, saleorChannel]);

  useDebouncedEffect(
    () => {
      if (!channelId) {
        return;
      }

      try {
        setLoading(true);
        get({
          props: {
            initial: true,
            onSuccess: async (res) =>
              await handleVideoProducts(
                res,
                languageCodeEnum,
                updateProductMap,
                setLoading,
                saleorChannel,
              ),
          },
          service: async () =>
            await ChannelsService.getChannelsVideos(
              channelId,
              undefined, // offset
              undefined, // limit
              searchTerm,
              searchLocale,
            ),
        });
      } catch (error) {
        console.log(error);
      }
    },
    400,
    [searchTerm],
  );

  useEffect(() => {
    if (!activeItem && listState.items && isDesktop) {
      setActiveItem(listState.items[0]);
    }
    setShowHowTo(listState.items?.length === 0);
  }, [listState.items]);

  const handleLoadMore = () => {
    if (channelId) {
      get({
        props: {
          initial: false,
          onSuccess: async (res) =>
            await handleVideoProducts(
              res,
              languageCodeEnum,
              updateProductMap,
              setLoading,
              saleorChannel,
            ),
        },
        service: async () =>
          await ChannelsService.getChannelsVideos(
            channelId,
            listState.items?.length, // offset
            undefined, // limit
            searchTerm,
            searchLocale,
          ),
      });
    }
  };

  const handleClose = () => {
    close();
    setTimeout(() => {
      setActiveItem(undefined);
    }, 600);
  };

  const handleOpenDetails = (item: VideoResponseDTO) => {
    setActiveItem(item);
    open();
  };

  const handleSelectionChange = (selectedIds: number[]) => {
    setSelectedIds(selectedIds);
    setDeleteCount(selectedIds.length);
  };

  const handleRemove = () => {
    if (channelId && deleteCount > 0) {
      deleteItems({
        service: () =>
          ChannelsService.removeVideos(channelId, {
            videoIds: selectedIds,
          }),
        itemsToDelete: selectedIds,
        props: {
          onSuccess: (res) => {
            setDeleteCount(0);
          },
        },
      });
    }
  };

  const handleMediathekClose = () => {
    setMediathekOpen(false);

    if (channelId) {
      get({
        service: async () =>
          await ChannelsService.getChannelsVideos(
            channelId,
            undefined, // offset
            undefined, // limit
            searchTerm,
            searchLocale,
          ),
        props: {
          initial: true,
          onSuccess: async (res) =>
            await handleVideoProducts(
              res,
              languageCodeEnum,
              updateProductMap,
              setLoading,
              saleorChannel,
            ),
        },
      });
    }
  };

  const handleUpdateShareUrls = (newSharingUrls: SharingUrlDTO[]) => {
    // TODO also possible with new updateItem<T> with dependency injection ??
    updateVideoItem({ newSharingUrls });
  };

  const handleUpdateVisibility = (updatedVideo: VideoResponseDTO) => {
    // TODO also possible with new updateItem<T> with dependency injection ??
    updateVideoItem({ res: updatedVideo });
  };

  const mediaThekProps = {
    open: mediathekOpen,
    onClose: handleMediathekClose,
    channelId,
  };
  return {
    listState,
    listFunctions,
    activeItem,
    productMap,
    updateProductMap,
    searchTerm,
    setSearchTerm,
    showHowTo,
    handleLoadMore,
    handleClose,
    handleOpenDetails,
    handleSelectionChange,
    handleRemove,
    handleUpdateShareUrls,
    handleUpdateVisibility,
    mediaThekProps,
    saleorIds,
    selectedIds,
    hasNextPage,
    fetchNextPage,
    pages,
    setMediathekOpen,
    isOpen,
    open,
    close,
    deleteCount,
    ...rest,
  };
};
export default useChannelProductVideos;
