import QueryList from 'components/List/QueryList';
import Loader from 'components/Loader/Loader';

import IconButton from 'components/IconButton/IconButton';
import { Spacer } from 'components/Layout';
import OrderListItem from 'components/OrderListItem/OrderListItem';
import { useFilter } from 'features/filter';
import {
  OrderFilterItem,
  OrderFilterKeys,
  getFilterQueryFromFilter,
} from 'features/order-filter';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import React, { Key, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { useReduxSelector } from 'redux/hooks';
// import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import Color from 'types/Color';
import { useGetOrders } from '../queries';
import InfiniteScroll from 'react-infinite-scroll-component';

const OrderData = () => {
  const { t } = useTranslation(['video']);
  const { filter, resetFilter } = useFilter<OrderFilterKeys, OrderFilterItem>();
  const { totalCount, orders, ...getOrdersQuery } = useGetOrders(getFilterQueryFromFilter(filter));
  const { status, data, isFetching } = getOrdersQuery;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  // const vendorAgent = useReduxSelector(selectVendorAgent);
  // console.log({ status, data })

  useEffect(() => {
    resetFilter();
  }, []);

  if (status === 'loading' && !isFetching) {
    return <Loader />;
  }

  if (status === 'error') {
    return (
      <Spacer padding={!isDesktop ? [0, 2] : 0}>
        {t('orders:list.error')}
      </Spacer>
    );
  }
  if (
    status === 'success' &&
    orders.length === 0
  ) {
    return (
      <Spacer padding={!isDesktop ? [0, 2] : 0}>
        {t('orders:list.empty')}
      </Spacer>
    );
  }

  return (
    <InfiniteScroll
      dataLength={totalCount || 0}
      next={() => getOrdersQuery.fetchNextPage()}
      hasMore={getOrdersQuery?.hasNextPage!}

      loader={<Loader />}
    >

      {/* // <QueryList
        //   {...getOrdersQuery}
        //   rightSlot={
        //     !isDesktop ? (
        //       <IconButton
        //         highlightNumber={
        //           Object.values(filter || []).flat().length || undefined
        //         }
        //         onClick={() => navigate('filter')}
        //         icon="filter"
        //         color={Color.confirm}
        //         big
        //       />
        //     ) : undefined
        //   }
        // >
         {data?.pages.map((page, i) => (
        <React.Fragment key={(page.pageInfo?.endCursor || i) as Key}> */}
      {orders.map((order, index) => (
        <OrderListItem
          key={order?.id}
          order={order}
        // currentOrderStatus={order.status}
        // createdAt={order.createdAt!}
        // product={order.product}
        // orderNo={order.orderNo!}
        />
        //   ))}
        // </React.Fragment>
      ))}
      {/* </QueryList> */}
    </InfiniteScroll>
  );
};

export default OrderData;
