// import { gqlHookFetcher } from 'api/GraphQL/gql.fetcher';
import { useInfiniteQuery } from '@tanstack/react-query';
import { OrderListFilterQueryType } from 'features/order-filter';
import {
  GetOrdersQuery,
  GetOrdersQueryVariables,
  LanguageCodeEnum,
  Order,
  OrderCountableConnection,
  useGetOrdersQuery,
} from 'generated/gql/gql';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import { ApiError } from 'generated';
import { selectLanguageCodeEnum } from 'redux/slices/i18n/i18nSlice';
import { selectSaleorChannel } from 'redux/slices/global/globalSlice';

export enum KEYS {
  getOrders = 'getOrders',
}
// const ordersQuery = gqlHookFetcher<
//   { orders: OrderCountableConnection },
//   GetOrdersQueryVariables
// >(GetOrdersDocument);
export const orderListKeys = {
  getOrders: (vendorAgentId?: number, filterQuery?: OrderListFilterQueryType) =>
    [KEYS.getOrders, vendorAgentId, filterQuery].filter((i) => i !== undefined),
};

export const useGetOrders = (
  filter: OrderListFilterQueryType,
  languageCode?: LanguageCodeEnum,
) => {
  const orderNo = filter?.orderNo;
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const saleorChannel = useReduxSelector(selectSaleorChannel);
  const defaultLanguageCode = useReduxSelector(selectLanguageCodeEnum);
  const _language = languageCode ?? defaultLanguageCode;
  const query = useInfiniteQuery<OrderCountableConnection, ApiError>({
    // ['getOrder', variables], iables>(GetOrderDocument).bind(null, variables),
    // options
    queryKey: orderListKeys.getOrders(vendorAgent?.currentVendor?.id, filter),
    // queryFn: async ({ pageParam = '' }) => gqlFetcher<{orders:}>(GetOrdersDocument)({variables: {filter}}),
    // AppOrderService.getAll(

    queryFn: async ({ pageParam = '' }) => {
      const {
        orderNo,
        orderStatus,
        productIds,
        createdAtFrom,
        createdAtTo,
        customerName,
      } = filter;
      const { orders } = await useGetOrdersQuery.fetcher({
        ...(pageParam ? { after: pageParam } : {}),
        languageCode: _language,
        saleorChannel: saleorChannel!,
        ...(createdAtFrom ||
        createdAtTo ||
        customerName ||
        orderNo ||
        orderStatus
          ? {
              filter: {
                ...(createdAtFrom || createdAtTo
                  ? {
                      created: {
                        gte: createdAtFrom,
                        lte: createdAtTo,
                      },
                    }
                  : {}),
                ...(customerName ? { customer: customerName } : {}),
                ...(orderNo ? { numbers: [orderNo] } : {}),
                ...(orderStatus
                  ? {
                      status: [orderStatus],
                    }
                  : {}),
              },
            }
          : {}),
      })();
      return orders as OrderCountableConnection;
    },

    getNextPageParam: (lastPage) =>
      lastPage?.pageInfo?.hasNextPage
        ? lastPage?.pageInfo?.endCursor
        : undefined,
  });

  return {
    ...query,
    orders: (
      query.data?.pages
        ?.map((i) => i?.edges?.map(({ node }) => node))
        ?.flat() ?? []
    ).filter(Boolean) as Order[],
    totalCount: query.data?.pages?.[0]?.totalCount ?? 0,
  };
  // return useInfiniteQuery<PaginatedAppOrderDto, ApiError>({
  //   queryKey: orderListKeys.getOrders(vendorAgent?.currentVendor?.id, filter),
  //   queryFn: async ({ pageParam = 0 }) => gqlFetcher(GetOrdersDocument)({}),
  //   // AppOrderService.getAll(
  //   //   pageParam, //offset
  //   //   undefined, //limit
  //   //   filter?.orderNo, //orderNo
  //   //   undefined, // productName
  //   //   filter?.customerName, //customerName
  //   //   filter?.orderNo ? [filter?.orderNo] : undefined, //orderNumbers
  //   //   filter?.productIds, //productIds
  //   //   filter?.createdAtFrom, //createdAtFrom
  //   //   filter?.createdAtTo, //createdAtTo
  //   //   filter?.orderStatus, //orderStatus
  //   //   'createdAt',
  //   //   'DESC',
  //   // ),
  //   getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  // });
};
