/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InternalPaymentSessionDto = {
    paymentProvider: InternalPaymentSessionDto.paymentProvider;
    /**
     * Payment session id provided by payment provider
     */
    sessionId: string;
    status: InternalPaymentSessionDto.status;
    customerId: string;
    customerNo: string;
    orderNo: string;
    sessionUrl?: string | null;
};

export namespace InternalPaymentSessionDto {

    export enum paymentProvider {
        SHIP_ROCKET = 'shipRocket',
        STRIPE = 'stripe',
    }

    export enum status {
        COMPLETE = 'complete',
        EXPIRED = 'expired',
        OPEN = 'open',
    }


}
