/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetNotificationsQueryDTO = {
    offset?: number;
    limit?: number;
    status?: GetNotificationsQueryDTO.status;
};

export namespace GetNotificationsQueryDTO {

    export enum status {
        PENDING = 'pending',
        SENT = 'sent',
        ALL = 'all',
    }


}
