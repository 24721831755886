import React from 'react';
import i18n from 'i18next';
import classNames from 'classnames';
import './price.scss';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';

type Variant = 'red' | 'green';
export interface Props {
  amount?: number | null;
  currency?: string;
  className?: string;
  crossedOut?: boolean;
  variant?: Variant;
  // discounted?: boolean;
  noStock?: boolean
  originalPrice?: number | null;
  hideNoStockTooltip?: boolean;
}

export const formatPrice = (amount: number, currency: string = 'USD') => {
  const lang = i18n.language;
  const priceFormatted = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currency,
  }).format(amount);

  return priceFormatted;
};

const Price = ({
  amount,
  currency = 'EUR',
  className,
  originalPrice,
  crossedOut,
  variant = 'green',
  noStock,
  hideNoStockTooltip = false
}: Props) => {
  // const discounted = !!originalPrice && !!amount && (originalPrice > amount);
  const priceFormatted = formatPrice(
    crossedOut ? originalPrice ?? 0 : amount ?? 0, currency);
  return (
    <span className='price__wrapper'>
      <div
        className={classNames(
          'price',
          crossedOut && 'price--crossed',
          variant === 'red' && 'price--discount',
          className,
        )}
      >
        <span className="price__text">{priceFormatted}</span>
      </div>

      {noStock && !hideNoStockTooltip && <Tooltip content='no stock!'>
        <span className="price__wrapper--warning" ><Icon className='price__icon--nostock' icon={'help'} /></span>
      </Tooltip>}


    </span >
  );
};
export default Price;
