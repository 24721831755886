/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NotificationDTO = {
    title: string;
    body: string;
    imageUrl?: string | null;
    date?: string | null;
    ios: boolean;
    android: boolean;
    optionKey?: NotificationDTO.optionKey;
    optionValue?: string | null;
    vendorId?: string | null;
    language: string;
    analyticsLabel: string;
};

export namespace NotificationDTO {

    export enum optionKey {
        SCP = 'scp',
        LINK = 'link',
        CHANNEL_KEY = 'channelKey',
        URL = 'url',
    }


}
