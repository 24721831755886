import SelectInteractive, { DefaultOption } from 'components/SelectInteractive';
import { InfiniteScrollProps } from 'components/SelectInteractive/SelectInteractive';
import useVideoFilter from 'features/video-filter/hooks/useVideoFilter';
import { FilterItem } from 'features/video-filter/types';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { useReduxSelector } from 'redux/hooks';
import { selectLanguageCode } from 'redux/slices/i18n/i18nSlice';
import { useGetCategoryFilter } from '../../queries';
import FilterDetail from './FilterDetail';
import { useLayoutEffect, useMemo, useState } from 'react';
import { CountableConnection, extractFromCountableConnection } from 'api/GraphQL/util';
import { Category } from 'generated/gql/gql';

const CategoryFilter = () => {
  const { t } = useTranslation(['translation', 'video']);
  const { setFilter, filter } = useVideoFilter();
  const [isInfinite, setIsInfinite] = useState(true);
  const { searchableProps, debouncedSearchTerm } = useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetCategoryFilter(
    debouncedSearchTerm as string,
  );

  const handleChange = (newValue: MultiValue<DefaultOption>) => {
    setFilter(
      'category',
      newValue?.map(
        ({ value, label }) =>
        ({
          id: value,
          name: label,
        } as FilterItem),
      ),
    );
  };

  const getValue = useMemo(() => {
    return (
      filter?.category?.map(({ id, name }) => {
        return { value: id.toString(), label: name };
      }) || []
    );
  }, [filter?.category]);

  const getOptions = useMemo(() => {
    const d = data?.pages.map((i) => i.categories as CountableConnection<Category>).flat() || [];
    return Object.values(extractFromCountableConnection(d)).map((i) => ({
      value: i.id.toString(),
      label: i.name,
    }));
  }, [data?.pages]);


  const infiniteScrollProps: InfiniteScrollProps = useMemo(() => ({
    dataLength: data?.pages?.[0]?.categories?.totalCount || 0,//data?.pages.map((i) => i.data).flat().length || 0,
    next: () => { fetchNextPage() },
    hasMore: !!hasNextPage,
  }), [data, hasNextPage, fetchNextPage]);

  const infiniteProps = useMemo(() => isInfinite
    ? {
      infiniteScrollProps,
      filterOption: () => true,
      ...searchableProps,
    }
    : {},
    [isInfinite, infiniteScrollProps, searchableProps]
  );

  return (
    <FilterDetail>
      <SelectInteractive
        {...infiniteProps}
        filterOption={() => true}
        isMulti
        variant="open"
        options={getOptions}
        isLoading={status === 'loading'}
        onChange={handleChange}
        value={getValue}
        placeholder={t('video:filter.category.placeholder')}
        infiniteScrollTargetId="sidebar--scroll-target"
        isOpenSelect
        noOptionsMessage={
          data?.pages?.[0]?.categories?.totalCount === 0 &&
            (debouncedSearchTerm?.length || 0) === 0
            ? t('video:filter.category.empty')
            : t('noOptions')
        }
      />
    </FilterDetail>
  );
};

export default CategoryFilter;
