import { pickProductIsAvailableForPurchase } from 'api/GraphQL/util'
import ActivateToggle from 'components/ActivateToggle/ActivateToggle'
import { Product, useUpdateProductChannelListingsMutation } from 'generated/gql/gql'
import useAppStatus from 'hooks/useAppStatus'
import React from 'react'
import { useTranslation } from 'react-i18next'
type Props = {
    product: Product
    refetch: () => void
    refetching?: boolean
}
const ToggleProductAvailability: React.FC<Props> = ({ product, refetch, refetching }) => {
    const { mutate: updateProductChannelListings, isLoading: activating, } = useUpdateProductChannelListingsMutation()
    const { setAppStatus } = useAppStatus();
    const { t } = useTranslation(['product'])
    const handleDeactivate = () => {
        if (product) {
            const productChannelId =
                product?.channelListings?.[0]?.channel?.id || '';
            updateProductChannelListings({
                id: product?.id || '',
                input: {
                    updateChannels: [{
                        isAvailableForPurchase: !pickProductIsAvailableForPurchase(product),
                        channelId: productChannelId
                    }]
                }
            },
                {
                    onSuccess: async ({ productChannelListingUpdate }) => {
                        const { errors } = productChannelListingUpdate!
                        if (errors?.length) {
                            setAppStatus(
                                errors[0].message!,
                                'error',
                            );
                        }
                        refetch()
                    }
                }
            );

        }
    };
    return <ActivateToggle
        active={pickProductIsAvailableForPurchase(product)}
        onToggle={handleDeactivate}
        tooltip={t('product:tooltip.isAvailableForPurchase')}
        loading={activating || refetching}
        activeText={t('product:toggle.available')}
        inactiveText={t('product:toggle.unavailable')}
    />
}
export default ToggleProductAvailability