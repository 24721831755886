/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateVendorAgentRoleDTO = {
    role: UpdateVendorAgentRoleDTO.role;
};

export namespace UpdateVendorAgentRoleDTO {

    export enum role {
        SALES_AGENT = 'sales_agent',
        SALES_ADMIN = 'sales_admin',
        REPORTER = 'reporter',
        ADMIN = 'admin',
    }


}
