import RefListItem from 'components/RefListItem/RefListItem';
import { VendorReferralResponseDto, VendorReferralService } from '../../../generated';
import { useReduxSelector } from '../../../redux/hooks';
import { selectLocale } from '../../../redux/slices/i18n/i18nSlice';

type Props = VendorReferralResponseDto & {
  onDeleteClick: (event: any) => void;
  onEditClick: (event: any) => void;
};

const RefLink = ({ id, ...props }: Props) => {
  const locale = useReduxSelector(selectLocale);

  const handleSendMail = async () => {
    await VendorReferralService.sendReferralEmailToAdmins(id);
  };

  return (
    <RefListItem
      onSendMail={handleSendMail}
      locale={locale}
      id={id}
      {...props}
    />
  );
};

export default RefLink;
