import { UseInfiniteQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { FlexBox, Spacer } from 'components/Layout';
import ListItemSelect from 'components/ListItemSelect/ListItemSelect';
import Loader from 'components/Loader/Loader';
import React, { Key, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CategoriesQuery, Category, } from 'generated/gql/gql';
import CategegoryHeader, { type Props as HeaderProps } from './CategoryHeader';
import CategoryBranch from 'components/CategoryBranch/CategoryBranch';
type Props = {
  scrollTargetId?: string;
  selected?: Category;
  onSelect: (category: Category, checked?: boolean) => void;
  searchTerm?: string
  query: UseInfiniteQueryResult<CategoriesQuery, unknown>
  headerProps: HeaderProps
}
const CategoryList: React.FC<Props> = ({
  scrollTargetId,
  selected,
  onSelect,
  searchTerm,
  query,
  headerProps

}) => {
  const { data, status, fetchNextPage, hasNextPage } = query
  const { t } = useTranslation(['translation', 'product']);
  return <div className="categories__modal-inner">
    <CategegoryHeader
      {...headerProps}
      loading={status === 'loading'}
    />
    <InfiniteScroll
      scrollableTarget={scrollTargetId}
      next={fetchNextPage}
      hasMore={hasNextPage || false}
      dataLength={data?.pages[0]?.categories?.totalCount || 0}
      loader={<Loader small />}
    >
      {(data?.pages[0]?.categories?.totalCount ?? 0) === 0 &&
        status === 'success' && (
          <Spacer className="c-black-50">
            {t('product:categories.error.notFound')}
          </Spacer>
        )}
      {status === 'error' && (
        <Spacer className="c-black-50">
          {t('product:categories.error.searchError')}
        </Spacer>
      )}
      {(data?.pages.map((i) => i.categories?.edges).flat().length ?? 0) > 0 && (
        <ul className="categories__list">
          {data?.pages.map((page, i) => (
            <React.Fragment key={(page.categories?.pageInfo?.endCursor || i) as Key}>
              {page.categories?.edges.map(({ node: treeItem }) => (
                <ListItemSelect
                  key={treeItem.id}
                  selected={
                    selected?.id === treeItem.id
                  }
                  id={`check--${treeItem.id}`}
                  name={`check--${treeItem.id}`}
                  onChange={(checked: boolean) =>
                    onSelect(treeItem as Category, checked)
                  }
                  appearance={'default'}
                  className={classNames(
                    'tree-select__option',
                    'categories__option',
                    (treeItem.parent) &&
                    'categories__option--parents',
                  )}
                >
                  <FlexBox justifyContent="space-between" alignItems="center">
                    <div className="categories__name">
                      <span>{treeItem.name}</span>
                      {treeItem.parent && (
                        <CategoryBranch category={treeItem as Category} hideRoot />
                      )}
                    </div>

                    {(treeItem.children?.totalCount && !searchTerm) ? (
                      <FlexBox
                        tag="button"
                        type="button"
                        className={classNames('tree-select__arrow')}
                        onClick={() => {
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Icon icon="arrow-right" />
                      </FlexBox>
                    ) : <></>}
                  </FlexBox>
                </ListItemSelect>
              ))}
            </React.Fragment>
          ))}
        </ul>
      )}
    </InfiniteScroll>
  </div>
}
export default CategoryList