import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';
import StatusBar from '../components/StatusBar/StatusBar';
import useAppStatus from '../hooks/useAppStatus';
import { useReduxSelector } from '../redux/hooks';
import {
  selectisSuperAdminDifferentVendor,
  selectVendorAgent,
} from '../redux/slices/auth/authSlice';
import { VideoUploadManager } from 'features/video-upload';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

function Layout() {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const isSuperAdminDifferentVendor = useReduxSelector(
    selectisSuperAdminDifferentVendor,
  );
  const { t } = useTranslation();
  const { appStatus, type, setAppStatus } = useAppStatus();
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  const handleCloseStatusBar = () => {
    setAppStatus(undefined);
  };

  return (
    <div>
      <Header />

      <main className="page__main">
        {isSuperAdminDifferentVendor && vendorAgent && (
          <StatusBar
            status={
              isDesktop
                ? t('super-admin-banner-text', {
                  vendor: vendorAgent?.currentVendor?.company,
                })
                : t('super-admin-banner-text-mobile', {
                  vendor: vendorAgent?.currentVendor?.company,
                })
            }
            type="warning"
            key={vendorAgent.currentVendor?.id}
          />
        )}

        <StatusBar
          status={appStatus}
          type={type}
          onClose={handleCloseStatusBar}
        />

        <Outlet />
        <ErrorBoundary>
          <VideoUploadManager />
        </ErrorBoundary>
      </main>
    </div>
  );
}

export default Layout;
