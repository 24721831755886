import { SocketNamespace, useSockets } from 'context/sockets';
import 'date-time-format-timezone';
import * as dotenv from 'dotenv';
import { videoListKeys } from 'features/video-list';
import { OpenAPI } from 'generated';
import endpoints, { Htaccess } from 'global-constants';
import useRoutesObject from 'hooks/useRoutesObject';
import { queryClient } from 'index';
import TermsPage from 'pages/TermsPage';
import { useEffect, useLayoutEffect } from 'react';
import Modal from 'react-modal';
import { useLocation, useRoutes } from 'react-router-dom';
import { useReduxDispatch, useReduxSelector } from 'redux/hooks';
import {
  init,
  selectIsAdmin,
  selectIsAuthLoading,
  selectIsSuperAdmin,
  selectVendorAgent,
  selectisSuperAdminDifferentVendor,
} from 'redux/slices/auth/authSlice';
import { selectTermsAcceptedDate } from 'redux/slices/terms/termsSlice';
import { LoaderFullScreen } from '../Loader/Loader';
import AppErrorBoundary from './AppErrorBoundary';

dotenv.config();

OpenAPI.BASE = endpoints.ApiUrl;
OpenAPI.USERNAME = Htaccess.user;
OpenAPI.PASSWORD = Htaccess.pw;
OpenAPI.WITH_CREDENTIALS = true;

Modal.setAppElement('#root');

const App = () => {
  const isAdmin = useReduxSelector(selectIsAdmin);
  const isSuperAdminDifferentVendor = useReduxSelector(
    selectisSuperAdminDifferentVendor,
  );
  const isSuperAdmin = useReduxSelector(selectIsSuperAdmin);
  const termsAcceptedDate = useReduxSelector(selectTermsAcceptedDate);
  const isAuthLoading = useReduxSelector(selectIsAuthLoading);

  const dispatch = useReduxDispatch();
  const routes = useRoutesObject();
  const location = useLocation();
  const appElement = useRoutes(routes, location);

  const statusUpdateSocket = useSockets(SocketNamespace.STATUS_UPDATE);
  const vendorAgent = useReduxSelector(selectVendorAgent);

  useEffect(() => {
    const initialize = async () => {
      await dispatch(init());
    };

    initialize();
  }, []);

  useEffect(() => {
    statusUpdateSocket?.on(
      'status-update:video-status-changed',
      (data: any) => {
        console.log('status-update:video-status-changed');
        queryClient.invalidateQueries(
          videoListKeys.getVideos(vendorAgent?.currentVendor?.id),
        );
      },
    );
  }, [statusUpdateSocket]);

  const showTermsPage = termsAcceptedDate == null && isAdmin && !isSuperAdmin;

  useLayoutEffect(() => {
    if (isSuperAdmin) {
      document.querySelector('body')?.classList.add('body--super');
    }
    if (isSuperAdminDifferentVendor) {
      document.querySelector('body')?.classList.add('body--super-admin');
    } else {
      document.querySelector('body')?.classList.remove('body--super-admin');
    }
  });

  if (isAuthLoading) {
    return <LoaderFullScreen />;
  }

  if (showTermsPage) {
    return <TermsPage />;
  }

  return (
    <>
      {/* <AppScrollTop /> */}
      {appElement}
    </>
  );
};

const AppWithBoundary = () => {
  return (
    <AppErrorBoundary>
      <App />
    </AppErrorBoundary>
  );
};

export default AppWithBoundary;
