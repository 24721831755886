import { AppOrderStatusDto } from 'generated';
import { OrderStatus } from 'generated/gql/gql';
import { ColorUse } from 'types/Color';

export const orderActionColors = {
  error: ColorUse['warning-50'],
  process: ColorUse['primary-25'],
  end: ColorUse.confirm,
};

export const orderActionPeriods = {
  // t('orders:periodKey.error.title');
  // t('orders:periodKey.error.tooltip');
  error: [
    // t('orders:status.cancelOrderBeforeShipping');
    OrderStatus.Canceled,
    OrderStatus.Expired,
    OrderStatus.Unfulfilled,
  ],
  // t('orders:periodKey.process.title');
  // t('orders:periodKey.process.tooltip');
  process: [
    OrderStatus.Draft,
    OrderStatus.PartiallyFulfilled,
    OrderStatus.PartiallyReturned,
    OrderStatus.Unconfirmed,
  ],
  // t('orders:periodKey.end.title');
  // t('orders:periodKey.end.tooltip');
  end: [OrderStatus.Fulfilled, OrderStatus.Returned],
};

export const getPeriodKeyByOrderAction = (action: OrderStatus) => {
  return (
    Object.keys(orderActionPeriods).find((key) =>
      orderActionPeriods[key].includes(action),
    ) || 'process'
  );
};

export const getOrderActionColorByStatus = (action: OrderStatus) => {
  const periodKey = getPeriodKeyByOrderAction(action);

  return orderActionColors[
    (periodKey as keyof typeof orderActionColors) || process
  ];
};

export const doesOrderActionNeedConfirmation = (action: OrderStatus) => {
  const actions: OrderStatus[] = [
    // OrderStatus.PURCHASE_BASKET_ITEMS,
    // OrderStatus.PAID_BASKET_ITEMS,
  ];

  return actions.includes(action);
};
