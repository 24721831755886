import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Icon from 'components/Icon/Icon';
import IconButton from 'components/IconButton/IconButton';
import LinkCode from 'components/LinkCode/LinkCode';
import ListItemSimple from 'components/ListItemSimple/ListItemSimple';
import { format } from 'date-fns';
import { VendorReferralResponseDto as ReferralResponseDTO } from 'generated';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ref-list-item.scss';
import Tooltip from 'components/Tooltip/Tooltip';
import useAppStatus from 'hooks/useAppStatus';
import { useGetCategoryQuery } from 'generated/gql/gql';

export type Props = ReferralResponseDTO & {
  onDeleteClick: (event: any) => void;
  onEditClick: (event: any) => void;
  onSendMail: () => Promise<void>;
  locale?: Locale;
};

const RefListItem = ({
  campaignName,
  createdAt,
  firebaseDynLink,
  source,
  medium,
  term,
  content,
  category: categoryId,
  onDeleteClick,
  landingPage,
  onEditClick,
  installationCount,
  locale,
  onSendMail,
}: Props) => {
  const [isExtended, setIsExtended] = useState(false);
  const [confirmModalOpen, setconfirmModalOpen] = useState(false);
  const { data: { category } = {} } = useGetCategoryQuery({ id: categoryId }, {
    enabled: !!categoryId && !!isExtended,
  })
  const { t } = useTranslation(['translation', 'settings']);
  const { setAppStatus } = useAppStatus();

  const handleSendMail = async () => {
    try {
      await onSendMail();
      setconfirmModalOpen(false);
      setAppStatus(t('settings:ref-link.mail.success', { campaignName }));
    } catch {
      setconfirmModalOpen(false);
      setAppStatus(t('settings:ref-link.mail.error'), 'error');
    }
  };

  return (
    <ListItemSimple
      headline={campaignName}
      headerRight={
        <>
          <span className="ref-list-item__date">
            {format(new Date(createdAt), 'P', {
              locale,
            })}
          </span>
          <IconButton
            icon={isExtended ? 'arrow-up' : 'arrow-down'}
            onClick={() => setIsExtended((prev) => !prev)}
          />
        </>
      }
      body={
        <>
          {firebaseDynLink && (
            <div className="ref-list-item__link">
              <LinkCode
                copyToClipBoard
                link={firebaseDynLink.shortLink}
                copyLink={firebaseDynLink.shortLink}
                copyToClipBoardText={t('settings:ref-link.copy.referral')}
              />
            </div>
          )}

          <div className="ref-list-item__link">
            <LinkCode
              copyToClipBoard
              link={landingPage.landingPageLink}
              copyLink={landingPage.landingPageLink}
              copyToClipBoardText={t('settings:ref-link.copy.landing')}
            />
          </div>

          <div className="ref-list-item__installations">
            <Tooltip
              content={t('settings:ref-link.installationTooltip')}
              className="ref-list-item__tooltip"
            >
              <div className="ref-list-item__installations-count">
                <span className="ref-list-item__installations-text">
                  {installationCount}
                </span>
                <span className="ref-list-item__installations-icon">
                  <Icon icon="install-mobile" />
                </span>
              </div>
            </Tooltip>
          </div>

          {isExtended && (
            <div className="ref-list-item__body-extended">
              <dl className="ref-list-item__meta">
                <div>
                  <dt className="input__label">
                    {t('settings:ref-link.source')}
                  </dt>
                  <dd>{source || '-'}</dd>
                </div>
                <div>
                  <dt className="input__label">
                    {t('settings:ref-link.medium')}
                  </dt>
                  <dd>{medium || '-'}</dd>
                </div>
                <div>
                  <dt className="input__label">
                    {t('settings:ref-link.term')}
                  </dt>
                  <dd>{term || '-'}</dd>
                </div>
                <div>
                  <dt className="input__label">
                    {t('settings:ref-link.content')}
                  </dt>
                  <dd>{content || '-'}</dd>
                </div>
                <div>
                  <dt className="input__label">
                    {t('settings:ref-link.category')}
                  </dt>
                  <dd>{category ? category.name : '-'
                  }</dd>
                </div>
              </dl>
              <div className="ref-list-item__controls">
                <IconButton
                  wrapperClassName="ref-list-item__button"
                  icon="delete"
                  onClick={onDeleteClick}
                  tooltip={t('settings:ref-link.delete')}
                />
                <IconButton
                  wrapperClassName="ref-list-item__button"
                  icon="mail"
                  onClick={() => setconfirmModalOpen(true)}
                  tooltip={t('settings:ref-link.mail.tooltip')}
                />
                <IconButton
                  wrapperClassName="ref-list-item__button"
                  icon="edit"
                  onClick={onEditClick}
                  tooltip={t('settings:ref-link.edit')}
                />

                <ConfirmModal
                  isOpen={confirmModalOpen}
                  headline={t('settings:ref-link.mail.title')}
                  text={t('settings:ref-link.mail.text', { campaignName })}
                  onCancelClick={() => setconfirmModalOpen(false)}
                  confirmText={t('settings:ref-link.mail.confirm')}
                  onConfirmClick={handleSendMail}
                />
              </div>
            </div>
          )}
        </>
      }
    />
  );
};

export default RefListItem;
