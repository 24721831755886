import classNames from 'classnames';
import Icon from 'components/Icon/Icon';
import { FlexBox } from 'components/Layout';
import ListItemSelect from 'components/ListItemSelect/ListItemSelect';
import { TreeItem } from '../TreeSelect';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';

export type Props = {
  onSelect: (item: TreeItem, level: number, checked: boolean) => void;
  selected: boolean;
  onOpenChild: (item: TreeItem) => void;
  treeItem: TreeItem;
};

const TreeSelectItem = ({
  selected,
  onSelect,
  onOpenChild,
  treeItem,
  // level,
}: Props) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  return (
    <ListItemSelect
      key={treeItem.id}
      selected={selected}
      id={`check--${treeItem.id}`}
      name={`check--${treeItem.id}`}
      onChange={(checked: boolean) => onSelect(treeItem, treeItem.level, checked)}
      appearance={isDesktop ? 'chip' : 'default'}
      className="tree-select__option"
    >
      <FlexBox justifyContent="space-between" alignItems="center">
        <span className="tree-select__name">{treeItem.name}</span>

        {(treeItem.children && (treeItem.children?.totalCount || 0) > 0) && (
          <FlexBox
            tag="button"
            type="button"
            className={classNames('tree-select__arrow')}
            onClick={() => onOpenChild(treeItem)}
            alignItems="center"
            justifyContent="center"
          >
            <Icon icon="arrow-right" />
          </FlexBox>
        )}
      </FlexBox>
    </ListItemSelect>
  );
};

export default TreeSelectItem;
