import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  ApiError,
  AvailabilityDTO,
  ChannelDTO,
  ChannelsService,
  PaginatedChannelWithVideosResponseDTO,
} from 'generated';
import useAppStatus from 'hooks/useAppStatus';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import QUERY_KEYS from 'types/QueryKeys';

export const useUpdateChannelAvailability = (id: number) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['channel']);
  const queryClient = useQueryClient();

  return useMutation<ChannelDTO, ApiError, AvailabilityDTO | undefined>({
    mutationFn: (availability?: AvailabilityDTO) =>
      ChannelsService.updateChannel(id, { availability }),
    onSuccess: (data, variables) => {
      queryClient.setQueryData<
        InfiniteData<PaginatedChannelWithVideosResponseDTO>
      >([QUERY_KEYS.getChannels, vendorAgent?.currentVendor?.id], (old) =>
        produce(old, (draft) => {
          let findItemIndex: number = -1;
          const findPageIndex = draft?.pages.findIndex((page) => {
            findItemIndex = page.data.findIndex((i) => i.id === data.id);

            return findItemIndex !== undefined;
          });

          if (
            findPageIndex !== undefined &&
            findItemIndex !== undefined &&
            findItemIndex !== -1 &&
            draft?.pages?.[findPageIndex]?.data[findItemIndex]
          ) {
            draft.pages[findPageIndex!].data[findItemIndex!].availability =
              variables;
          }
        }),
      );
    },
    onError: () => {
      setAppStatus(t('channel:available.error'), 'error');
    },
  });
};
