/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InternalOrderStatusDto = {
    id: number;
    orderId: number;
    action: InternalOrderStatusDto.action;
    orderStatus: InternalOrderStatusDto.orderStatus;
    paymentStatus: InternalOrderStatusDto.paymentStatus;
    fulfillmentStatus: InternalOrderStatusDto.fulfillmentStatus;
    returnStatus: InternalOrderStatusDto.returnStatus;
    createdAt: string;
    createdBy?: number;
    updatedAt?: string | null;
    updatedBy?: number;
    deletedDate?: string | null;
};

export namespace InternalOrderStatusDto {

    export enum action {
        PURCHASE_BASKET_ITEMS = 'purchaseBasketItems',
        PAID_BASKET_ITEMS = 'paidBasketItems',
        CREATE_SHIPMENT = 'createShipment',
        CANCEL_SHIPMENT = 'cancelShipment',
        CANCEL_ORDER_BEFORE_SHIPPING = 'cancelOrderBeforeShipping',
        PICKUP_ORDER_FROM_WAREHOUSE = 'pickupOrderFromWarehouse',
        DELIVER_ORDER_TO_CUSTOMER = 'deliverOrderToCustomer',
        NOT_DELIVERED_ORDER = 'notDeliveredOrder',
        DO_PAYMENT_COD = 'doPaymentCOD',
        CLOSE_ORDER = 'closeOrder',
        LOST_ORDER = 'lostOrder',
        REQUEST_RETURN_BY_CUSTOMER = 'requestReturnByCustomer',
        CREATE_RETURN_SHIPMENT = 'createReturnShipment',
        PICKUP_ORDER_FROM_CUSTOMER = 'pickupOrderFromCustomer',
        REJECT_RETURN_REQUEST = 'rejectReturnRequest',
        DELIVER_ORDER_TO_WAREHOUSE = 'deliverOrderToWarehouse',
        REFUND_PAYMENT = 'refundPayment',
    }

    export enum orderStatus {
        OPEN = 'open',
        CLOSED = 'closed',
        CANCELLED = 'cancelled',
    }

    export enum paymentStatus {
        UNPAID = 'unpaid',
        PAID = 'paid',
        REFUNDED = 'refunded',
    }

    export enum fulfillmentStatus {
        UNFULFILLED = 'unfulfilled',
        FULFILLED = 'fulfilled',
    }

    export enum returnStatus {
        NONE = 'none',
        REQUESTED = 'requested',
        PROCESSING = 'processing',
        RETURNED = 'returned',
        CANCELLED = 'cancelled',
    }


}
