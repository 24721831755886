import { Spacer } from 'components/Layout/Layout';
import QueryList from 'components/List/QueryList';
import { Fragment, Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import QUERY_KEYS from 'types/QueryKeys';
import { useDebounce } from 'use-debounce';
import Button from '../../../components/Button/Button';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import Headline from '../../../components/Headline/Headline';
import Modal from '../../../components/Modal/Modal';
import {
  LandingPageDTO,
  VendorCreateReferralDto,
  VendorReferralResponseDto,
  VendorReferralService,
} from '../../../generated';
import { useGetRefLinks } from '../../../hooks/queries';
import useAppStatus from '../../../hooks/useAppStatus';
import { queryClient } from '../../../index';
import Color from '../../../types/Color';
import AddRefLinkForm from './AddRefLinkForm';
import EditLandingPageForm from './EditLandingPageForm';
import RefLink from './RefLink';
import RefLinksFilter from './RefLinksFilter';
import './ref-links.scss';

const RefLinksContainer = () => {
  const { t } = useTranslation(['translation', 'settings']);
  const { setAppStatus } = useAppStatus();
  const [submitLoadingAddRefLinkForm, setSubmitLoadingAddRefLinkForm] =
    useState(false);
  const [
    submitLoadingEditLandingPageForm,
    setSubmitLoadingEditLandingPageForm,
  ] = useState(false);
  const [removeRefModal, setRemoveRefModal] = useState<VendorReferralResponseDto>();
  const [addRefModal, setAddRefModal] = useState<boolean>(false);
  const [editRefModal, setEditRefModal] = useState<VendorReferralResponseDto>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get('search') || '',
  );
  const [selectSource, setSelectSource] = useState<string>(
    searchParams.get('source') || '',
  );
  const [selectMedium, setSelectMedium] = useState<string>(
    searchParams.get('medium') || '',
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const refLinksQuery = useGetRefLinks(
    debouncedSearchTerm[0],
    selectSource,
    selectMedium,
  );
  const { data } = refLinksQuery;

  const filterParams = {
    searchTerm,
    setSearchTerm,
    selectMedium,
    setSelectMedium,
    selectSource,
    setSelectSource,
  };

  useEffect(() => {
    if (!submitLoadingAddRefLinkForm) {
      setAddRefModal(false);
    }
  }, [submitLoadingAddRefLinkForm]);

  useEffect(() => {
    if (!submitLoadingEditLandingPageForm) {
      setEditRefModal(undefined);
    }
  }, [submitLoadingEditLandingPageForm]);

  useEffect(() => {
    const obj = {
      search: searchTerm,
      medium: selectMedium,
      source: selectSource,
    };
    setSearchParams(obj);
  }, [searchParams, searchTerm, selectMedium, selectSource]);

  const deleteReferral = (id: number) => {
    handleDeleteRefLink(id);
    setRemoveRefModal(undefined);
  };

  const handleDeleteRefLink = async (id: number) => {
    try {
      await VendorReferralService.softRemoveReferralLink(id);
      setAppStatus(t('settings:success.deleteRefLink'), 'success', true);

      queryClient.invalidateQueries([QUERY_KEYS.getRefLinks]);
    } catch (error) {
      setAppStatus(t('settings:error.deleteRefLink'), 'error', true);
    }
  };

  const handleAddRefLinkSubmit = async (
    values: VendorCreateReferralDto,
    callback: Function,
  ) => {
    setSubmitLoadingAddRefLinkForm(true);
    try {
      await VendorReferralService.createReferralLink({
        ...values,
        referralLinkObjectKey: values.referralLinkObjectKey || undefined,
        referralLinkType: values.referralLinkObjectKey
          ? VendorReferralResponseDto.referralLinkType.CHANNEL
          : VendorReferralResponseDto.referralLinkType.APP,
      });

      setAppStatus(t('settings:success.addRefLink'), 'success', true);
      callback();

      queryClient.invalidateQueries([QUERY_KEYS.getRefLinks]);
    } catch (error) {
      setAppStatus(t('settings:error.addRefLink'), 'error', true);
    }

    setSubmitLoadingAddRefLinkForm(false);
  };

  const handleEditLandingPageEdit = async (values: LandingPageDTO) => {
    if (!editRefModal) {
      return;
    }

    setSubmitLoadingEditLandingPageForm(true);
    try {
      const setValues = {
        ...values,
        languageIso:
          values.languageIso?.length && values.languageIso.length >= 2
            ? values.languageIso
            : undefined,
      };

      await VendorReferralService.updateReferralLandingPage(
        editRefModal?.id,
        setValues,
      );

      setAppStatus(t('settings:success.editRefLink'), 'success', true);

      queryClient.invalidateQueries([QUERY_KEYS.getRefLinks]);
    } catch (error) {
      setAppStatus(t('settings:error.editRefLink'), 'error', true);
    }

    setSubmitLoadingEditLandingPageForm(false);
  };

  const hasItems = data?.pages?.map((i) => i.data).flat().length || -1 > 0;

  return (
    <>
      <Headline className="settings__headline" headingLevel="h2" size={2}>
        {t('settings:headlines.refLinks')}
      </Headline>

      <div
        className="settings__block settings__block--inline"
        style={{ marginTop: '-4.8rem' }}
      >
        <Button
          type="button"
          text={t('add')}
          onClick={() => setAddRefModal(true)}
          color={Color.secondary}
          icon="add"
          className="settings__block--end"
        />
      </div>

      <RefLinksFilter {...filterParams} />

      <div className="settings__block">
        {data?.pages.map((page) => page.data).flat().length === 0 && (
          <Spacer marginTop={4}>
            <p className="small small--dark-50">
              {t('settings:no-refLinks-text')}
            </p>
          </Spacer>
        )}
        {hasItems && (
          <QueryList {...refLinksQuery}>
            {data?.pages.map((page, i) => (
              <Fragment key={(page.links?.next?.offset || i) as Key}>
                {page.data.map((item, index) => (
                  <RefLink
                    {...item}
                    key={item.id}
                    onDeleteClick={() => setRemoveRefModal(item)}
                    onEditClick={() => setEditRefModal(item)}
                  />
                ))}
              </Fragment>
            ))}
          </QueryList>
        )}
      </div>

      {removeRefModal != null && (
        <ConfirmModal
          isOpen={true}
          confirmText={t('delete')}
          headline={t('settings:really_delete')}
          text={t('settings:really_delete_refLink', {
            title: removeRefModal.campaignName,
          })}
          onCancelClick={() => setRemoveRefModal(undefined)}
          onConfirmClick={() => deleteReferral(removeRefModal.id)}
        />
      )}

      <Modal
        isOpen={addRefModal}
        onClose={() => setAddRefModal(false)}
        variant="wide"
        headline={t('settings:addRefLinkForm.headlines.h1')}
        hasHeaderBorder
      >
        <AddRefLinkForm
          submitLoading={submitLoadingAddRefLinkForm}
          onSubmit={handleAddRefLinkSubmit}
        />
      </Modal>

      {editRefModal && (
        <Modal
          headline={t('settings:editLandingPageForm.headline')}
          isOpen={editRefModal !== undefined}
          onClose={() => setEditRefModal(undefined)}
          variant="wide"
          hasHeaderBorder
        >
          <EditLandingPageForm
            edit={editRefModal.landingPage}
            submitLoading={submitLoadingEditLandingPageForm}
            onSubmit={handleEditLandingPageEdit}
          />
        </Modal>
      )}
    </>
  );
};

export default RefLinksContainer;
