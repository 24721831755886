import { ChannelLocalisationDTO } from 'generated';
import { AvailabilityDTO } from 'generated/models/AvailabilityDTO';
import ChannelType from 'types/ChannelType';

export const formatValues = ({
  channelLocalisations,
  iconPath,
  name,
  availability,
}: ChannelType) => {
  const startDate =
    availability?.start && availability?.type !== AvailabilityDTO.type.PERMANENT
      ? new Date(availability.start).toISOString()
      : undefined;
  const endDate =
    availability?.end && availability?.type !== AvailabilityDTO.type.PERMANENT
      ? new Date(availability.end).toISOString()
      : undefined;
  return {
    name: name.replace(/[\r\n]+/gm, ' ').trim(),
    channelLocalisations:
      channelLocalisations?.map((loc) => ({
        ...loc,
        legalInfo: loc.legalInfo,
        description: loc.description,
      })) || [],
    iconPath,
    availability: availability
      ? {
        ...availability,
        start: availability?.start && startDate,
        end: availability?.end && endDate,
      }
      : undefined,
  };
};

export const emptyChannel = (languageCode: string): ChannelType => ({
  id: 0,
  key: '',
  name: '',
  channelLocalisations: [
    {
      language: languageCode,
      legalInfo: { blocks: [] },
      description: { blocks: [] },
    },
  ],
});

export const setupChannelLocalisations = (
  channelLocalisations: ChannelLocalisationDTO[],
  languageCode: string,
) => {
  if (channelLocalisations?.length === 0 || !channelLocalisations) {
    return emptyChannel(languageCode).channelLocalisations;
  }

  return channelLocalisations;
};
