import HashtagSelectField from 'components/HashtagSelect/HashtagSelectField';
import { useGetHashtagSettings } from 'features/Settings/UpdateHashtagsMax/queries';
import { Field, useField } from 'formik';
import { HashtagResponseDTO } from 'generated';
import { queryClient } from 'index';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hashtagQueryKeys, useAddHashtag, useGetHashtags } from '../queries';
import useSearchableSelect from 'hooks/useSearchableSelect';

export type Props = {
  error?: string;
  success?: string;
  description?: string;
};

/**
 * This component renders a Formik Field named 'hashtags' prefilled with options and functions needed to edit hashtags in a Formik form.
 * Only use this component with a <Formik> wrapper component for state handling and submitting the value.
 * If you need a Field that is not prefilled, please use HashtagSelectField which is used here internally.
 * If you just want to use an input component without Formik, please use the HashtagSelect.
 */
const HashtagsAsyncField = ({ error, success, description }: Props) => {
  const [emptyText, setEmptyText] = useState(null);
  const { searchableProps, clearInputValue, debouncedSearchTerm } =
    useSearchableSelect();
  const { data, status, fetchNextPage, hasNextPage } = useGetHashtags(
    debouncedSearchTerm as string,
  );
  const useAddMutation = useAddHashtag();
  const [field, , { setValue }] = useField<HashtagResponseDTO[]>('hashtags');
  const { t } = useTranslation(['translation', 'product']);
  const [addError, setAddError] = useState();
  const { data: settings } = useGetHashtagSettings();

  const handleCreateHashtag = () => {
    useAddMutation.mutate(
      {
        name: searchableProps.inputValue,
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries({
            queryKey: hashtagQueryKeys.getHashtags(),
          });
          clearInputValue();
          setValue([...field.value, data]);
          setAddError(undefined);
        },
        onError: (error) => {
          if (error.body.error === 'ForbiddenNameError') {
            setAddError(t('product:hashtag.forbidden'));
            clearInputValue();
          } else if (error.body.error === 'BadRegex') {
            setAddError(t('product:hashtag.badRegex'));
          } else if (error) {
            setAddError(t('product:hashtag.error'));
            clearInputValue();
          }
        },
      },
    );
  };

  useEffect(() => {
    if (
      status === 'success' &&
      data.pages.map((i) => i.data).flat().length === 0
    ) {
      if (searchableProps.inputValue.replace(/\s/g, '').length > 0) {
        setEmptyText(t('product:hashtag.searchNotFound'));
      } else {
        setEmptyText(t('product:hashtag.empty'));
      }
    } else if (status === 'error') {
      setEmptyText(t('product:hashtag.notFound'));
    } else {
      setEmptyText(t('product:hashtag.empty'));
    }
  }, [searchableProps.inputValue, data, status]);

  useEffect(() => {
    setAddError(undefined);
  }, [field.value]);

  return (
    <Field
      {...searchableProps}
      component={HashtagSelectField}
      name="hashtags"
      hashtags={data?.pages.map((i) => i.data).flat()}
      isLoading={status === 'loading'}
      noOptionsMessage={emptyText}
      onCreateHashtag={handleCreateHashtag}
      onLoadMore={fetchNextPage}
      hasNextPage={hasNextPage}
      addError={addError}
      maxHashtagsValue={settings?.maximumHashtagsPerEntity || 5}
      error={error}
      success={success}
      description={description}
    />
  );
};

export default HashtagsAsyncField;
