/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VendorDTO = {
    company: string;
    type?: VendorDTO.type;
};

export namespace VendorDTO {

    export enum type {
        DEFAULT = 'default',
        SALES = 'sales',
    }


}
