/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InternalCustomerDeviceDto = {
    id: number;
    instanceId: string;
    appInstanceId?: string;
    language?: string;
    referral?: string;
    platform: string;
    appBuildVersion?: string;
    recommendation?: InternalCustomerDeviceDto.recommendation;
    createdAt?: string | null;
    createdBy?: string | null;
    updatedAt?: string | null;
    updatedBy?: string | null;
    deletedDate?: string | null;
};

export namespace InternalCustomerDeviceDto {

    export enum recommendation {
        DEFAULT = 'default',
        NONE = 'none',
        MINI = 'mini',
        AI = 'ai',
    }


}
