import { pickProductIsPublished } from 'api/GraphQL/util'
import ActivateToggle from 'components/ActivateToggle/ActivateToggle'
import { Product, useUpdateProductChannelListingsMutation } from 'generated/gql/gql'
import useAppStatus from 'hooks/useAppStatus'
import React from 'react'
import { useTranslation } from 'react-i18next'
type Props = {
    product: Product
    refetch: () => void
    refetching?: boolean
}
const ToggleProductVisibility: React.FC<Props> = ({ product, refetch, refetching }) => {
    const { mutate: updateProductChannelListings, isLoading: activating, } = useUpdateProductChannelListingsMutation()
    const { setAppStatus } = useAppStatus();
    const { t } = useTranslation(['product'])

    const handlePublish = () => {
        if (product) {
            const productChannelId =
                product?.channelListings?.[0]?.channel?.id || '';
            updateProductChannelListings({
                id: product?.id || '',
                input: {
                    updateChannels: [{
                        isPublished: !pickProductIsPublished(product),
                        channelId: productChannelId
                    }]
                }
            },
                {
                    onSuccess: async ({ productChannelListingUpdate }) => {
                        const { errors } = productChannelListingUpdate!
                        if (errors?.length) {
                            setAppStatus(
                                errors[0].message!,
                                'error',
                            );
                        }
                        refetch()
                    }
                }
            );

        }
    }
    return <ActivateToggle
        active={pickProductIsPublished(product)}
        onToggle={handlePublish}
        loading={activating || refetching}
        activeIcon='eye'
        activeText={t('product:toggle.published')}
        inactiveText={t('product:toggle.unpublished')}
        tooltip={t('product:tooltip.isPublished')}
        // disabled={!pickProductIsAvailable(product)}
        inactiveIcon='eye-off'
    />
}
export default ToggleProductVisibility