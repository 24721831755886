import Navi, { Props as NaviProps } from 'components/Navi/Navi';
import Steps from 'components/Steps/Steps';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './tree-select.scss';
import TreePanel from './components/TreePanel';
import { Category } from 'generated/gql/gql';
import CategoryUtils from 'features/categories/Categories/utils';

export type Props = {
  // tree?: TreeItem;
  // /** This array needs to be sorted by depth starting with the lowest. 
  //  *  All Ids must be from a single tree branch 
  //  * */
  value?: TreeItem;
  onChange: (selectedItems?: TreeItem) => void;
  /** If the tree is connected to a search and opens 
   * from a non-tree target, you can give this panel another id than 0 */
  parent?: Category;
  /** Adds a close button in the mobile view */
  onClose?: () => void;
  /** Populates the Navi component with additional props and components if needed */
  mobileNavigationProps?: Partial<NaviProps>;
  onOpenPanel?: (panelId: string) => void;
  scrollTargetId: string;
};

export type TreeItem = Category

const TreeSelect = ({
  value,
  onChange,
  parent,
  onClose,
  mobileNavigationProps,
  scrollTargetId,
  onOpenPanel,
}: Props) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { t } = useTranslation(['product']);
  const ancestry = CategoryUtils.getAncestors(value).map(({ id }) => id);
  const [showPanels, _setShowPanels] = useState<string[]>(ancestry);
  useEffect(() => {
    if (showPanels[showPanels.length - 1] !== ancestry[ancestry.length - 1]) {
      _setShowPanels([...((isDesktop && value) ? ancestry : [])]);
    }
  }, [ancestry, showPanels, isDesktop, value]);

  useEffect(() => {
    onOpenPanel?.(showPanels[showPanels.length - 1]);
  }, [showPanels,]);

  const setShowPanels = (panel: string, level: number) => {
    _setShowPanels((panels) => {
      return [...(panels.slice(0, level + 1) || []), panel];
    });
  };
  const handleSelect = (item: TreeItem, level: number, checked: boolean) => {
    if (checked) {
      if (isDesktop) {
        setShowPanels(item.id!, level);
      } else {
        _setShowPanels([item.id])
      }

    } else {
    }
    onChange(item);
  };

  const navigateBack = () => {
    _setShowPanels(old => old.slice(0, -1));
  };

  const handleClose = () => {
    if (parent) {
      _setShowPanels([parent.id]);
    }
    onClose?.();
  };

  const panelProps = {
    selectedItems: value,
    onSelectChange: handleSelect,
    showPanels,
    setShowPanels,
    parent: parent!,
    scrollTargetId
  };

  return (
    <ul className="tree-select">
      {!isDesktop ? (
        <>
          <Navi
            forwardKey={showPanels.toString()}
            onBackClick={navigateBack}
            onClose={handleClose}
            childCenterSlot={
              <span className="caption">
                {value?.name ?? t('product:addCategories')}
              </span>
            }
            childRouteIsActive={showPanels.length > 1}
            parentCenterSlot={
              <span className="caption--lavender">
                {t('product:addCategories')}
              </span>
            }
            {...mobileNavigationProps}
          />
          <Steps stepKey={!isDesktop ? showPanels.toString() : 'true'}>
            <TreePanel {...panelProps} />
          </Steps>
        </>
      ) : (
        <TreePanel {...panelProps} />
      )}
    </ul>
  );
};

export default TreeSelect;
