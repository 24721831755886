import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { TreeItem } from '../TreeSelect';
import TreeSelectItem from './TreeSelectItem';
import { Spacer } from 'components/Layout';
import { Category, } from 'generated/gql/gql';
import { Fragment, useLayoutEffect, useMemo } from 'react';
import { useCategoryQuery } from 'features/categories/queries';
import CategoryUtils from 'features/categories/Categories/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'components/Loader/Loader';
import { CountableConnection } from 'api/GraphQL/util';

const TreePanel = ({
  selectedItems,
  showPanels,
  setShowPanels,
  onSelectChange,
  parent,
  scrollTargetId
}: {
  scrollTargetId: string;
  selectedItems?: TreeItem;
  showPanels: string[];
  setShowPanels: (panel: string, level: number) => void;
  onSelectChange: (item: TreeItem, level: number, checked: boolean) => void;
  parent: Category;
}) => {
  const { data: { pages } = {}, status, hasNextPage, fetchNextPage, } = useCategoryQuery(parent.id);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const selectedAncestors = useMemo(() => CategoryUtils.getAncestors(selectedItems!), [selectedItems]);
  const _items = useMemo(() =>
    (pages?.map(({ category }) => category as Category)
      ?.map(({ children }) => [children] as CountableConnection<Category>[])
      ?.map(CategoryUtils.extractConnections)
      ?.flat()
      ?.filter(Boolean) ?? []) as Category[]
    , [pages]);
  const itemMap = useMemo(() => _items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {} as Record<Category['id'], Category>), [_items])
  const items = useMemo(() => Object.values(itemMap) as Category[], [itemMap])
  const childrenTree = useMemo(() =>
    items
      .filter(item => !!item && (item?.children?.totalCount ?? 0) > 0)
      .filter(node =>
        selectedAncestors
          .map(({ id }) => id)
          .includes(node.id)
        && node.id !== parent.id)
    ,
    [items, parent.id, selectedAncestors])
  useLayoutEffect(() => {
    if (
      hasNextPage &&
      status === 'success'
    ) {

      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, status]
  );

  const openChild = (item: TreeItem) => {
    setShowPanels(item.id!, item.level!);
    onSelectChange(item, item.level!, true);
  };

  const handleSelect = (item: TreeItem, level: number, checked: boolean) => {
    onSelectChange(item, level, checked);
  };

  const panelIsVisible = !!items.length && !!parent &&
    isDesktop
    ? (showPanels?.includes(parent?.id) || showPanels?.includes(selectedItems?.id!))
    : (showPanels?.[showPanels.length - 1] === selectedItems?.id
    )


  return (
    <div className="tree-select__cards" id={`tree-select__cards--${parent?.level}`}>
      {panelIsVisible && (
        <div className="tree-select__card" id={`tree-select__card--${parent?.id}`}>
          {isDesktop && (
            <Spacer
              tag="h2"
              className="caption tree-select__headline"
              marginBottom={1}
            >
              {parent?.name}
            </Spacer>
          )}
          <InfiniteScroll
            scrollableTarget={scrollTargetId}
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            dataLength={items?.length || 0}
            loader={<Loader small />}
          >
            {items?.map((c, i) => (
              <Fragment key={c.id + i}>
                <TreeSelectItem
                  treeItem={c}
                  onOpenChild={openChild}
                  onSelect={handleSelect}
                  selected={
                    CategoryUtils.getAncestors(selectedItems).some(({ id }) => id === c?.id)

                  }
                />

              </Fragment>
            ))}
          </InfiniteScroll>
        </div>
      )}

      {panelIsVisible
        ? childrenTree.map((node, i) => (
          <Fragment key={i}>
            <TreePanel
              scrollTargetId={scrollTargetId}
              selectedItems={selectedItems}
              parent={node}
              showPanels={showPanels}
              setShowPanels={setShowPanels}
              onSelectChange={onSelectChange}
            /></Fragment>
        ))
        : <></>}

    </div>
  );
};

export default TreePanel;
