import classNames from 'classnames';
import SelectInteractive, {
  OptionWithIcon,
  OptionWithIconProps,
  Props as SelectInteractiveProps,
  ValueContainerIconValue,
} from 'components/SelectInteractive/';
import VideoStatusIcon from 'components/VideoStatusIcon/VideoStatusIcon';
import { isAvailable } from 'features/availability';
import { ChannelMinimalResponseDTO, ChannelWithVideosDTO, VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import { GroupBase, components } from 'react-select';
import './channel-select.scss';

export type Props<
  OptionType extends OptionWithIconProps,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>,
> = SelectInteractiveProps<OptionType, IsMulti, Group> & {

  channels?: (ChannelMinimalResponseDTO | ChannelWithVideosDTO)[];
  /** Adds an infinite scroll function for paginated options.
   * It should update the options by adding the newly fetched options of the next page.
   * This will only be called when hasNextPage is true. */
  onLoadMore?: () => void;
  /** Decides wether onLoadMore is called again if infinite scroll is used. */
  hasNextPage?: boolean;
};

export const getOptionFromChannel = ({
  availability,
  name,
  id,
}: ChannelMinimalResponseDTO | ChannelWithVideosDTO): OptionWithIconProps => {
  let statusProps = VideoResponseDTO.status.IDLE;
  if (availability) {
    statusProps = isAvailable(availability)
      ? VideoResponseDTO.status.LIVE
      : VideoResponseDTO.status.IDLE;
  }

  return {
    icon: <VideoStatusIcon status={statusProps} />,
    label: name,
    value: id.toString(),
  };
};

const ChannelSelect = <isMulti extends boolean = false>(
  props: Props<OptionWithIconProps, isMulti>,
) => {
  const { t } = useTranslation(['translation', 'channel']);
  const { channels } = props;
  const sharedProps = {
    title: t('channel:title'),
    label: t('channel:title'),
    placeholder: t('channel:choose'),
    ...props,
    className: classNames('channel-select', props.className),
    options: channels?.map((i) => getOptionFromChannel(i)),
  };
  const infiniteScrollProps = props.onLoadMore
    ? {
      dataLength: channels?.length || 0,
      next: () => props.onLoadMore?.(),
      hasMore: props.hasNextPage || false,
    }
    : undefined;

  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to "",
    // prevent setting a value cleans the input
    if (
      event.action !== 'input-blur' &&
      event.action !== 'menu-close' &&
      event.action !== 'set-value'
    ) {
      props.onInputChange?.(inputText, event);
    }
  };

  return (
    <SelectInteractive
      {...sharedProps}
      noOptionsMessage={props.noOptionsMessage || t('channel:empty')}
      components={{
        Option: OptionWithIcon,
        ValueContainer: !props.isMulti
          ? ValueContainerIconValue
          : components.ValueContainer,
        ...props.components
      }}
      closeMenuOnSelect={props.isMulti ? false : true}
      infiniteScrollProps={infiniteScrollProps}
      onInputChange={handleInputChange}
    />
  );
};

export default ChannelSelect;
