import { FilterType } from 'features/filter';
import {
  OrderFilterItem,
  OrderFilterKeys,
  OrderListFilterQueryType,
} from './types';
import { AppOrderStatusDto } from 'generated';
import { OrderStatus, OrderStatusFilter } from 'generated/gql/gql';

export const getFilterQueryFromFilter = (
  filter?: FilterType<OrderFilterKeys, OrderFilterItem>,
): OrderListFilterQueryType => {
  if (filter) {
    return {
      orderNo: filter.orderNo?.[0].id,
      createdAtFrom: filter.createdAtFrom?.[0]?.zonedDate
        ? new Date(filter.createdAtFrom?.[0].zonedDate).toISOString()
        : undefined,
      createdAtTo: filter.createdAtTo?.[0]?.zonedDate
        ? new Date(filter.createdAtTo?.[0].zonedDate).toISOString()
        : undefined,
      productIds: filter.productIds?.map((item) => item.id),
      customerName: filter.customerName?.[0].id,
      orderStatus: filter.orderStatus?.[0].id as OrderStatusFilter,
    };
  }
  return {};
};

export const getFilterItemsFromFilter = (
  filter?: FilterType<OrderFilterKeys, OrderFilterItem>,
): OrderFilterItem[] => {
  if (filter) {
    return Object.values(filter)
      .filter((i) => i !== undefined)
      .flat();
  }
  return [];
};
