import { FormikProps } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ActivateToggle from '../../components/ActivateToggle/ActivateToggle';
import Button from '../../components/Button/Button';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import Icon from '../../components/Icon/Icon';
import { ApiError } from '../../generated';
import useAppStatus from '../../hooks/useAppStatus';
import useOpen from '../../hooks/useOpen';
import { useReduxDispatch, useReduxSelector } from '../../redux/hooks';
import { logOut } from '../../redux/slices/auth/authSlice';
import notAuthenticated from '../../utils/not-authenticated';
import { Product, useDeleteProductMutation, useProductCheckQuery, useUpdateProductChannelListingsMutation } from 'generated/gql/gql';
import { ProductForm } from './utils';
import { selectLanguageCodeEnum } from 'redux/slices/i18n/i18nSlice';
import { selectSaleorChannel } from 'redux/slices/global/globalSlice';
import ToggleProductAvailability from 'features/product-control/ToggleProductAvailability';
import ToggleProductVisibility from 'features/product-control/ToggleProductVisibility';

export interface Props {
  edit?: boolean;
  productId?: string;
  formik: FormikProps<ProductForm>;
  loadingSave: boolean;
}

const ProductFormControls = ({
  edit,
  productId,
  formik,
  loadingSave,
}: Props) => {
  const { t } = useTranslation(['translation', 'product']);
  const { isOpen, open, close } = useOpen(false);
  const languageCode = useReduxSelector(selectLanguageCodeEnum)
  const saleorChannel = useReduxSelector(selectSaleorChannel);
  const { data: { product } = {}, refetch } = useProductCheckQuery({
    id: productId ?? '',
    languageCode: languageCode,
    saleorChannel
  }, {
    enabled: !!edit && !!productId,
  });
  const {
    isOpen: cancelIsOpen,
    open: cancelOpen,
    close: cancelClose,
  } = useOpen(false);
  const [deactivateLoading, setDeactivateLoading] = useState<boolean>(false);
  const { values, setFieldValue, touched } = formik;
  const dispatch = useReduxDispatch();
  const { setAppStatus } = useAppStatus();
  const [loading, setLoading] = useState<boolean>(false);
  const { mutate: updateAvailability } = useUpdateProductChannelListingsMutation()
  const { mutate: deleteProduct } = useDeleteProductMutation()
  const navigate = useNavigate();

  const handleDeactivate = () => {
    if (edit) {
      if (productId) {
        const setAvailability = async () => {
          setDeactivateLoading(true);
          try {
            const res = await updateAvailability({
              id: productId, input: {
                updateChannels: [{ isAvailableForPurchase: !values?.isAvailable, channelId: values?.saleorChannelId }]
              }
            },
              {
                onSuccess: (_data) => {
                  setFieldValue('isAvailable', !values?.isAvailable);

                },
                onError: (_error) => {
                  setAppStatus(t('product:deactivate.error'), 'error');

                }
              })
            return res
          } catch (error) {
            // setAppStatus(t('product:deactivate.error'), 'error');
          }
        };
        setAvailability();
      }
    } else {
      setFieldValue('isAvailable', !values?.isAvailable);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    const _deleteProduct = async () => {
      try {
        if (productId) {
          const res = await deleteProduct({ id: productId },
            {
              onSuccess: (data) => {
                close();
                navigate('/products');

                setAppStatus(
                  t('product:delete.success', { product: data.productDelete?.product?.externalReference }),
                  'success',
                  true,
                );
              },
              onError: (error) => {
                if (notAuthenticated(error as ApiError)) {
                  dispatch(logOut());
                } else {
                  open();
                  setAppStatus(t('product:delete.error'), 'error');
                }
              }
            }
          );
          return res

        }
      } catch (error) {

      }
    };
    _deleteProduct();
  };

  const handleCancel = () => {
    if (Object.keys(touched).length > 0) {
      cancelOpen();
    } else {
      navigate('/products');
    }
  };

  return (
    <div className="form__footer">
      <div className="form__controls">
        <div className="form__controls-left">
          {/* <ActivateToggle
            onToggle={handleDeactivate}
            active={values?.isAvailable || false}
            loading={deactivateLoading}
          /> */}

          {edit && (
            <>
              {
                product
                &&
                <>
                  <ToggleProductAvailability product={product as Product} refetch={refetch} />
                  <ToggleProductVisibility product={product as Product} refetch={refetch} />
                </>
              }

              <Button
                text={t('delete')}
                appearance="ghost"
                type="button"
                onClick={open}
              />

              <ConfirmModal
                isOpen={isOpen}
                headline={t('delete')}
                text={t('product:really_delete', {
                  title: values?.externalReference,
                })}
                onCancelClick={close}
                onConfirmClick={handleDelete}
                confirmLoading={loading}
                confirmText={t('delete')}
              />
            </>
          )}
        </div>
        <div className="form__controls-right">
          {!edit && (
            <div className="form__controls-info">
              <span className="form__controls-info-icon">
                <Icon icon="info" />
              </span>
              <span className="form__controls-info-text">
                {t('product:videosAfterSafe')}
              </span>
            </div>
          )}
          <Button
            text={t('cancel')}
            type="button"
            appearance="ghost"
            onClick={handleCancel}
          />

          <ConfirmModal
            isOpen={cancelIsOpen}
            headline={t('cancel')}
            text={t('product:really_delete_changes')}
            cancelText={t('no')}
            onCancelClick={cancelClose}
            confirmText={t('leave')}
            confirmLink="/products"
          />

          <Button text={t('save')} type="submit"
            formAction='submit' loading={loadingSave} />
        </div>
      </div>
    </div>
  );
};

export default ProductFormControls;
