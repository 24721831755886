import { FieldProps, getIn } from 'formik';
import { FC } from 'react';
import { onSubmitDontBlur } from '../../utils/on-submit-dont-blur';
import { DefaultOption } from './components/Option';
import SelectInteractive, { Props } from './SelectInteractive';
import { Props as ReactSelectProps } from 'react-select';

const SelectInteractiveField: FC<ReactSelectProps & FieldProps> = ({
  field,
  form: { errors, touched, setFieldValue },
  options,
  isMulti = false,
  ...props
}) => {
  const err = getIn(errors, field.name);
  const tou = getIn(touched, field.name);

  const getValue = () => {
    if (!field.value) {
      return '';
    }
    if (options) {
      return isMulti
        ? options.filter(
          (option) =>
            field.value.indexOf((option as DefaultOption).value) >= 0,
        )
        : options.find(
          (option) => (option as DefaultOption).value === field.value,
        );
    } else {
      return isMulti ? [] : ('' as any);
    }
  };

  const onChange = (option: DefaultOption | DefaultOption[]) => {
    setFieldValue(
      field.name,
      isMulti
        ? (option as DefaultOption[]).map((item: DefaultOption) => item.value)
        : (option as DefaultOption).value,
    );
  };
  return (
    <SelectInteractive
      {...field}
      {...props}
      options={options}
      value={getValue()}
      onChange={onChange}
      error={tou && err ? err : undefined}
      onBlur={(event) => onSubmitDontBlur(event, field.onBlur)}
    />
  );
};

export default SelectInteractiveField;
