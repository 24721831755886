import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import useSidebar from 'hooks/useSidebar';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import VendorAsyncSelect from '../../features/vendor';
import { BREAKPOINTS } from '../../global-constants';
import { useMediaQuery } from '../../hooks/useMediaquery';
import Logo from '../../images/logo-creator-2x.png';
import { useReduxDispatch, useReduxSelector } from '../../redux/hooks';
import { logOut, selectIsSalesAgent } from '../../redux/slices/auth/authSlice';
import Icon from '../Icon/Icon';
import HeaderMenuItem from './HeaderMenuItem';
import NavigationLink from './NavigationItem';
import './header.scss';
import { KeyValueDTO, KeyValueStoreService } from 'generated';

export interface Props {
  logo?: string;
}

const Header = ({ logo }: Props) => {
  const { t } = useTranslation(['translation']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const targetRef = useRef<HTMLDivElement>(null);
  const dispatch = useReduxDispatch();
  const isSalesAgent = useReduxSelector(selectIsSalesAgent);
  const { closeSidebar, sidebarIsOpen } = useSidebar();
  const [showOrders, setShowOrders] = useState<boolean>(false);

  const getOrderShow = async (): Promise<boolean> => {
    try {
      const keyValueDto = await KeyValueStoreService.getKeyValueStoreItem(
        KeyValueDTO.key.CREATOR_SHOW_ORDERS,
      );

      return keyValueDto.value === 'true';
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const orders = async () => {
      const showOrders = await getOrderShow();
      setShowOrders(showOrders);
    };
    orders();
  }, []);

  useEffect(() => {
    if (openMenu && targetRef.current) {
      disableBodyScroll(targetRef.current);
    } else if (!sidebarIsOpen) {
      clearAllBodyScrollLocks();
    }
  }, [openMenu]);

  const menu = isSalesAgent
    ? []
    : [
      { name: t('videosTitle'), path: '/videos' },
      { name: t('productTitle'), path: '/products' },
      { name: t('channelTitle'), path: '/channels' },
      { name: t('statisticsTitle'), path: '/statistics' },
      showOrders && { name: t('ordersTitle'), path: '/orders' },
      // { name: t('voucher'), path: '/voucher' },
    ];

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleNavClick = (next?: string) => {
    console.log(next);

    setOpenMenu(false);
    closeSidebar();
  };

  const NAV_ITEMS = [
    {
      text: t('settingsTitle'),
      icon: <Icon icon="person" />,
      to: '/settings',
      onClick: handleNavClick,
    },
    {
      text: t('logout'),
      icon: <Icon icon="remove-filled" />,
      onClick: () => dispatch(logOut()),
    },
  ];

  useEffect(() => clearAllBodyScrollLocks(), []);

  return (
    <header className="header">
      <div className="header__inner">
        <NavLink className="header__logo" to="/channels">
          <img src={logo || Logo} alt="Jooli Logo" />
        </NavLink>

        {isDesktop && (
          <>
            <nav className="header__nav">
              <div className="header__items">
                {menu.map(
                  (item, index) =>
                    item && (
                      <HeaderMenuItem
                        to={item.path}
                        name={item.name}
                        key={index}
                        onClick={() => handleNavClick(item.path)}
                      />
                    ),
                )}
              </div>
            </nav>
            <div className="header__controls">
              <VendorAsyncSelect />

              <div className="header__vendor">
                <button
                  className="header__vendor-select"
                  type="button"
                  aria-controls="vendorFylout"
                  aria-expanded={openMenu}
                  onClick={handleToggleMenu}
                  id="headerToggle"
                >
                  <Icon icon="person" />

                  <span className="hidden-visually">{t('settingsMenu')}</span>
                </button>

                {openMenu && (
                  <div className="header__vendor-flyout" id="vendorFylout">
                    <div className="header__vendor-items">
                      {NAV_ITEMS.map((navItem) => (
                        <NavigationLink key={navItem.text} {...navItem} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {!isDesktop && (
          <>
            <VendorAsyncSelect />

            <div className="header__mobile">
              <button
                className="header__toggle"
                type="button"
                aria-controls="mobileMenu"
                aria-expanded={openMenu}
                onClick={handleToggleMenu}
                id="headerToggle"
              >
                <div className="header__toggle-icon"></div>
                <span className="hidden-visually">{t('mobileMenu')}</span>
              </button>
            </div>
          </>
        )}
      </div>

      {!isDesktop && openMenu && (
        <nav
          className="header__nav"
          id="mobileMenu"
          aria-labelledby="headerToggle"
          ref={targetRef}
        >
          <div className="header__items">
            {menu.map(
              (item, index) =>
                item && (
                  <HeaderMenuItem
                    to={item.path}
                    name={item.name}
                    key={index}
                    onClick={() => handleNavClick(item.path)}
                  />
                ),
            )}
          </div>

          <div className="header__vendor">
            {NAV_ITEMS.map((navItem) => (
              <NavigationLink key={navItem.text} {...navItem} />
            ))}
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;
