/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetUtmResponseDTO } from '../models/GetUtmResponseDTO';
import type { UpdateUtmSourceDTO } from '../models/UpdateUtmSourceDTO';
import type { UtmMediumDTO } from '../models/UtmMediumDTO';
import type { UtmMediumResponseDTO } from '../models/UtmMediumResponseDTO';
import type { UtmSourceDTO } from '../models/UtmSourceDTO';
import type { UtmSourceResponseDTO } from '../models/UtmSourceResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UtmService {

    /**
     * Add new UTM source
     * @param requestBody 
     * @returns UtmSourceResponseDTO 
     * @throws ApiError
     */
    public static addUtmSource(
requestBody: UtmSourceDTO,
): CancelablePromise<UtmSourceResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/utm/sources',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add new UTM medium
     * @param requestBody 
     * @returns UtmMediumResponseDTO 
     * @throws ApiError
     */
    public static addUtmMedium(
requestBody: UtmMediumDTO,
): CancelablePromise<UtmMediumResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/utm/mediums',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get utm data
     * @returns GetUtmResponseDTO 
     * @throws ApiError
     */
    public static getUtmData(): CancelablePromise<GetUtmResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/utm',
        });
    }

    /**
     * update utm source
     * @param id 
     * @param requestBody 
     * @returns GetUtmResponseDTO 
     * @throws ApiError
     */
    public static updateSource(
id: number,
requestBody: UpdateUtmSourceDTO,
): CancelablePromise<GetUtmResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/utm/sources/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * delete utm source
     * @param id 
     * @returns GetUtmResponseDTO 
     * @throws ApiError
     */
    public static deleteSource(
id: number,
): CancelablePromise<GetUtmResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/utm/sources/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * delete utm medium
     * @param id 
     * @returns GetUtmResponseDTO 
     * @throws ApiError
     */
    public static deleteMedium(
id: number,
): CancelablePromise<GetUtmResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/utm/mediums/{id}',
            path: {
                'id': id,
            },
        });
    }

}
