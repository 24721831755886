import React, { useMemo } from 'react'
import Button from 'components/Button/Button';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import Modal from 'components/Modal/Modal';
// import { Category } from 'generated';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorUse } from 'types/Color';
import randomString from 'utils/random-string';
import '../../categories.scss';
import { Category, CategorySortField, OrderDirection } from 'generated/gql/gql';
import CategegoryHeader from './CategoryHeader';
import { useCategoriesQuery, } from 'features/categories/queries';
import CategoryList from './CategoryList';
import CategoryTree from '../CategoryTree';
import CategoryUtils from '../../utils';
type Props = {
    onSelect: (value?: Category) => void;
    value?: Category;
    scrollTargetId?: string;
    searchTerm?: string
    open: boolean
    close: () => void
}
const CategoryPickerModalDesktop: React.FC<Props> = ({
    onSelect,
    value,
    scrollTargetId: _scrollTargetId,
    open,
    close

}) => {

    const { t } = useTranslation(['translation', 'product']);
    const [selectedCat, setSelectedCat] = useState<Category | undefined>(value);
    const selectedAncestry = useMemo(() => CategoryUtils.getAncestors(selectedCat), [selectedCat]);
    const showTree = !!selectedCat || selectedAncestry.length > 0;
    const scrollTargetId = _scrollTargetId || `categories--${randomString()}`;
    const scrollTargetRef = useRef<HTMLDivElement>(null);

    const { searchableProps, debouncedSearchTerm, setInputValue } = useSearchableSelect({
        onInputChange: () => {
            setSelectedCat(undefined);
        }
    });
    const query = useCategoriesQuery(debouncedSearchTerm ?? '', 0, {
        direction: OrderDirection.Asc,
        field: CategorySortField.Name,
    });
    useEffect(
        () => {
            if (value?.id !== selectedCat?.id) {
                setSelectedCat(value);
            }
        },
        [value]
    )
    const onSelectChange = (value: Category) => {
        setSelectedCat(value);
        setInputValue('')
    }
    const cancel = () => {
        onSelect(value);
        setInputValue('')
        setSelectedCat(value);
        close();
    }
    const submit = () => {
        onSelect(selectedCat);
        setSelectedCat(value);
        setInputValue('')
        close();
    }
    const handleScroll = () => {
        scrollTargetRef.current?.scrollTo({
            left: scrollTargetRef.current?.scrollWidth,
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        handleScroll()
    }, [selectedCat])
    return <Modal
        isOpen={open}
        onClose={cancel}
        variant={'full'}
        scrollTargetId={scrollTargetId}
        headline={t('product:categories.modalTitle')}
        hideHeader={true}
        className="categories__modal"
        ref={scrollTargetRef}
        hideScrollBars={false}
    >
        <CategegoryHeader
            searchableProps={searchableProps}
            onClose={cancel}
            loading={query.status === 'loading'}
        />
        <FlexBox padding={[6, 4, 4]}>
            <CategoryList
                query={query}
                scrollTargetId={scrollTargetId}
                selected={selectedCat}
                onSelect={onSelectChange}
            />
            {
                showTree
                    ? <CategoryTree
                        selected={selectedCat}
                        status={query.status}
                        scrollTargetId={scrollTargetId}
                        onSelectChange={onSelectChange}
                        parent={selectedAncestry[0]}
                        onClose={cancel}
                    />
                    : <></>
            }

        </FlexBox>

        <footer className="modal__fixed-footer">
            <FlexBox justifyContent="space-between" alignItems="center">
                <Button
                    text={t('cancel')}
                    onClick={cancel}
                    color={ColorUse.confirm}
                    appearance="ghost"
                />
                <Button
                    text={t('confirm')}
                    onClick={submit}
                    color={ColorUse.confirm}
                />

            </FlexBox>
        </footer>
    </Modal >

}
export default CategoryPickerModalDesktop