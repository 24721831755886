import { Spacer } from 'components/Layout';
import { OrderStatusActionOptionType } from 'components/OrderStatusActionSelect';
import OrderStatusActionSelect from 'components/OrderStatusActionSelect/OrderStatusActionSelect';
import { FilterDetail, useFilter } from 'features/filter';
import { OrderFilterItem, OrderFilterKeys } from 'features/order-filter/types';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { getOrderActionColorByStatus } from 'utils/order-action';

const OrderStatusFilter = () => {
  const { t } = useTranslation(['translation', 'orders']);
  const { setFilter, filter } = useFilter<OrderFilterKeys, OrderFilterItem>();

  const handleChange = (value: SingleValue<OrderStatusActionOptionType>) => {
    if (value?.label && value.value) {
      setFilter('orderStatus', [
        {
          name: value.label,
          id: value.value,
          chipProps: {
            color: getOrderActionColorByStatus(value.value),
          },
        },
      ]);
    }
  };

  const getValue = () => {
    return {
      value: filter?.orderStatus,
      label: filter?.orderStatus
    };
  };

  return (
    <FilterDetail>
      <Spacer tag="p" className="small" marginBottom={2}>
        {t('orders:filter.orderStatus.description')}
      </Spacer>
      <OrderStatusActionSelect
        variant="open"
        value={
          filter?.orderStatus?.values().next().value

        }
        onChange={handleChange}
      />
    </FilterDetail>
  );
};

export default OrderStatusFilter;
