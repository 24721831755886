import { IconType } from 'components/Icon/Icon';
import { VideoResponseDTO } from 'generated';
import { ColorName } from 'types/Color';

export const getVideoTypeAppearance = (type: VideoResponseDTO.type) => {
  switch (type) {
    case VideoResponseDTO.type.CHANNEL:
      return { icon: 'channel' as IconType, color: ColorName['rose-50'] };
    case VideoResponseDTO.type.PRODUCT:
      return {
        icon: 'product' as IconType,
        color: ColorName['pale-beryl-25'],
      };
    default:
      return {
        icon: 'product' as IconType,
        color: ColorName['pale-beryl-25'],
      };
  }
};
export const getProductVideoCount = (videos: VideoResponseDTO[]) =>
  videos.reduce(
    (acc, video) => ({
      ...acc,
      [video.saleorProductId!]: (acc[video.saleorProductId!] ?? 0) + 1,
    }),
    {} as Record<string, number>,
  );
