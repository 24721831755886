import Button from 'components/Button/Button';
import IconButton from 'components/IconButton/IconButton';
import { FlexBox, FlexItem, Spacer } from 'components/Layout';
import Modal from 'components/Modal/Modal';
import Steps from 'components/Steps/Steps';
// import { Category } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import useOpen from 'hooks/useOpen';
import useSearchableSelect from 'hooks/useSearchableSelect';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorUse } from 'types/Color';
import randomString from 'utils/random-string';
import '../../categories.scss';
import CategoryBranch from 'components/CategoryBranch/CategoryBranch';
import { Category } from 'generated/gql/gql';
import { useCategoriesQuery, } from 'features/categories/queries';
import CategoryList from './CategoryList';
import CategoryUtils from '../../utils';
import CategoryTree from '../CategoryTree';
type Props = {
    onSelect: (value?: Category) => void;
    value?: Category;
    scrollTargetId?: string;
    open: boolean
    close: () => void
}
const CategoryPickerModalMobile: React.FC<Props> = ({
    onSelect,
    value,
    scrollTargetId: _scrollTargetId,
    open,
    close
}) => {
    const { t } = useTranslation(['translation', 'product']);
    const [selectedCat, setSelectedCat] = useState<Category>();
    const scrollTargetId = _scrollTargetId || `categories--${randomString()}`;
    const selectedAncestry = useMemo(() => CategoryUtils.getAncestors(selectedCat), [selectedCat]);
    const scrollTargetRef = useRef<HTMLDivElement>(null);
    const showTree = !!selectedCat || selectedAncestry.length > 0;

    const { searchableProps, debouncedSearchTerm, setInputValue, } =
        useSearchableSelect({
            onInputChange: () => {
                setSelectedCat(undefined);
            },
        });
    const query = useCategoriesQuery(debouncedSearchTerm ?? '');
    useEffect(
        () => {
            if (value?.id !== selectedCat?.id) {
                setSelectedCat(value);
            }
        },
        [value]
    )
    const onSelectChange = (value: Category) => {
        setSelectedCat(value);
        setInputValue('')
    }
    const cancel = () => {
        onSelect(value);
        setInputValue('')
        setSelectedCat(value);
        close();
    }
    const submit = () => {
        onSelect(selectedCat);
        setSelectedCat(value);
        setInputValue('')
        close();
    }
    return <Modal
        isOpen={open}
        onClose={cancel}
        variant={'bottom-sheet'}
        scrollTargetId={scrollTargetId}
        headline={t('product:categories.modalTitle')}
        hideHeader={true}
        className="categories__modal"
        ref={scrollTargetRef}
        hideScrollBars={false}
    >

        <Steps stepKey={selectedCat?.id!}>
            {
                showTree
                    ? <CategoryTree
                        selected={selectedCat}
                        scrollTargetId={scrollTargetId}
                        status={query.status}
                        onSelectChange={onSelectChange}
                        parent={selectedAncestry[0]}
                        onClose={cancel}
                    />
                    : <CategoryList
                        query={query}
                        scrollTargetId={scrollTargetId}
                        selected={selectedCat}
                        onSelect={setSelectedCat}
                        headerProps={{ searchableProps, onClose: cancel, loading: query.status === 'loading' }}

                    />
            }
        </Steps>
        <footer className="modal__fixed-footer">
            <FlexBox justifyContent="space-between" alignItems="center">
                {(
                    <>
                        <FlexItem flexGrow={1} marginRight={2}>
                            <CategoryBranch category={selectedCat!} />
                        </FlexItem>
                        <FlexItem marginRight={4}>
                            <IconButton
                                icon="close"
                                onClick={cancel}
                                color={ColorUse.confirm}
                                appearance="ghost"
                                big
                            />
                        </FlexItem>
                        <FlexItem>
                            <IconButton
                                icon="check"
                                onClick={submit}
                                color={ColorUse.confirm}
                                appearance="filled"
                                big
                            />
                        </FlexItem>
                    </>
                )}
            </FlexBox>
        </footer>
    </Modal>
}
export default CategoryPickerModalMobile