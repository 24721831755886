import classNames from 'classnames';
import { Field, FieldArray, useField } from 'formik';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import InputField from '../Input/InputField';
import TextAreaField from '../Input/TextAreaField';
import './labeled-info-text.scss';
import LabeledInfoTextControls from './LabeledInfoTextControls';
import Select from 'components/Select/Select';
import { EditorDataType, EditorDataListType, EditorData } from './util';

export interface Props {
  name: string;
  className?: string;
}
const getInitialProductInfo = (type: EditorDataType, style?: EditorDataListType): EditorData => (
  (type === EditorDataType.list)
    ? {
      type,
      data: {
        items: []
      },
      style: style ?? EditorDataListType.ordered
    }
    : {
      type,
      data: {
        text: ''
      }
    }

)
const LabeledInfoTextFormComponent = ({ className, name }: Props) => {
  const [{ value }, { error, touched }] = useField<EditorData[]>(name);
  const { t } = useTranslation();
  const classString = 'labeled-info-text';
  const getFieldName = (type: EditorDataType) => type === EditorDataType.list
    ? 'items'
    : 'text'

  return (
    <div
      className={classNames(
        classString,
        `${classString}--edit`,
        error && touched && `${classString}--error`,
        className,
      )}
    >
      <div className={`${classString}__form`}>
        {value && (
          <FieldArray
            name={name}
            render={(arrayHelpers) => (
              <>
                <Flipper flipKey={JSON.stringify(value)} >
                  {value.map((v, i) => (
                    <Flipped key={i} flipId={i} translate>
                      <div className={`${classString}__block`}>
                        <LabeledInfoTextControls
                          moveItemUp={() => arrayHelpers.move(i, i - 1)}
                          moveItemDown={() => arrayHelpers.move(i, i + 1)}
                          removeItem={arrayHelpers.handleRemove(i)}
                          showDownButton={i + 1 < value.length}
                          showUpButton={i - 1 >= 0}
                        />
                        <div className={`${classString}__inputs`}>
                          <span>
                            <Select
                              className={`${classString}__select`}
                              name={`${name}.${i}.type`}
                              value={v.type}
                              defaultValue={v.type}
                              onChange={(e) => {
                                arrayHelpers.replace(i, getInitialProductInfo(e.target.value as EditorDataType))
                              }}
                              label='Type'
                              options={[
                                { value: EditorDataType.header, text: t('edit:label.header') },
                                { value: EditorDataType.paragraph, text: t('edit:label.paragraph') },
                                { value: EditorDataType.list, text: t('edit:label.list') },
                              ]}
                            />
                            {v.type === EditorDataType.list
                              ? <Select
                                className={`${classString}__select`}
                                name={`${name}.${i}.style`}
                                value={v.style}
                                defaultValue={v.style}
                                onChange={(e) => {
                                  arrayHelpers.replace(i, getInitialProductInfo(v.type, e.target.value as EditorDataListType))
                                }}
                                label='List style'
                                options={[
                                  { value: EditorDataListType.unordered, text: '● ' + t('edit:label.unordered') },
                                  { value: EditorDataListType.ordered, text: '1. ' + t('edit:label.ordered') },
                                ]}
                              /> : <></>}
                          </span>

                          <Field
                            component={v.type !== EditorDataType.header ? TextAreaField : InputField}
                            className={`${classString}__textarea ${classString}__${v.type}`}
                            name={`${name}.${i}.data.${getFieldName(v.type)}`}
                            label={t(`edit:${v.type}.add`)}
                            multiline={`${v.type === EditorDataType.list}`}
                            minrows={v.type === EditorDataType.list ? 3 : undefined}
                            value={
                              v.type === EditorDataType.list
                                ? v.data.items.join('\n')
                                : v?.data?.text
                            }
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                              const newValue = v.type === 'list'
                                ? e.target.value.split('\n')
                                : e.target.value;
                              arrayHelpers.replace(i, {
                                ...v,
                                data: {
                                  ...v.data,
                                  [getFieldName(v.type)]: newValue,
                                },
                              });
                            }
                            }

                            id={`${name}.${i}.data.${getFieldName(v.type)}`}
                          />
                        </div>
                      </div>
                    </Flipped>
                  ))}
                </Flipper>

                <button
                  className={`${classString}__block ${classString}__add`}
                  type="button"
                  onClick={() =>
                    arrayHelpers.push(getInitialProductInfo(EditorDataType.header))
                  }
                >
                  <div className={`${classString}__controls`}>
                    <div
                      className={`${classString}__button ${classString}__button--add`}
                    >
                      <span className="hidden-visually">
                        {t('edit:block.add')}
                      </span>
                      <Icon icon="add" />
                    </div>
                  </div>
                  <div className={`${classString}__add-text`}>
                    {t('edit:block.add')}
                  </div>
                </button>
                {error && touched && (
                  <div className={`input__error ${classString}__field-error`}>
                    {error}
                  </div>
                )}
              </>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default LabeledInfoTextFormComponent;
