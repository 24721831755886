import classNames from 'classnames';
import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import { VideoResponseDTO } from '../../generated';
import placeholderImage from '../../images/placeholder-product.svg';
import { randomString } from '../../utils/random-string';
import Checkbox from '../Checkbox/Checkbox';
import EncodingBadge from '../EncodingBadge/EncodingBadge';
import Icon from '../Icon/Icon';
import LazyImage from '../LazyImage/LazyImage';
import './list-item.scss';
import Price from 'components/Price/Price';

export interface Props {
  title?: string;
  image?: string;
  subtitle?: string;
  price?: {
    amount: number;
    currency?: string;
    originalPrice?: number | null;
  };
  language?: string;
  onClick?: () => void;
  active?: boolean;
  deactivated?: boolean;
  className?: string;
  border?: boolean;
  selectable?: {
    selected: boolean;
    onSelectChange: (isSelected: boolean) => void;
    disabled?: boolean;
  };
  id?: string;
  numberOfVideos?: number;
  highlighted?: boolean;
  encodingStatus?: VideoResponseDTO.encodingStatus;
  placeholder?: string;
  languageNotSupportedError?: boolean;
  isPrivate?: boolean;
  highlightImage?: boolean;
  tag?: ElementType;
  noStock?: boolean;
}

const ListItem = ({
  active,
  border,
  className,
  deactivated,
  encodingStatus,
  highlighted,
  highlightImage,
  id,
  image,
  isPrivate,
  language,
  languageNotSupportedError,
  noStock,
  numberOfVideos,
  onClick,
  placeholder,
  price,
  selectable,
  subtitle,
  tag: Tag = 'li',
  title = 'No Title',
}: Props) => {
  const isId = id || randomString();
  const { t } = useTranslation(['translation', 'list']);

  const handleSelectChange = (selected: boolean) => {
    selectable?.onSelectChange(selected);
  };

  const hasBlockOverlay = deactivated || languageNotSupportedError;

  return (
    <Tag
      className={classNames(
        {
          'list-item': true,
          'list-item--action': onClick,
          'list-item--active': active,
          'list-item--deactivated': deactivated,
          'list-item--border': border,
          'list-item--highlighted': numberOfVideos || highlighted,
          'list-item--selectable': selectable,
          'list-item--error': languageNotSupportedError,
          'list-item--highlight-image': highlightImage,
        },
        className,
      )}
      onClick={onClick}
    >
      {selectable && (
        <Checkbox
          checked={selectable.selected}
          id={`check--${isId}`}
          name={`check--${isId}`}
          onChange={handleSelectChange}
          label={title === '' ? t('no_title') : title}
          disabled={selectable.disabled}
          hideLabel
        />
      )}
      <div className="list-item__image">
        <LazyImage
          src={image || placeholder || placeholderImage}
          lqip={placeholder || placeholderImage}
          aspectRatio={1}
          alt={t('list:preview', { title: title })}
        />
        {hasBlockOverlay && (
          <div className="list-item__image-overlay">
            <Icon icon="block" />
          </div>
        )}

        {!hasBlockOverlay && isPrivate && (
          <div className="list-item__image-overlay">
            <Icon icon="eye-off" />
          </div>
        )}
      </div>

      <div className="list-item__detail">
        <div className="list-item__title">
          {title === '' ? t('no_title') : title}
        </div>
        {subtitle && <div className="list-item__subtitle">{subtitle}</div>}
      </div>
      {price && (
        <Price
          amount={price.amount}
          currency={price.currency}
          originalPrice={price.originalPrice}
          variant={price.originalPrice ? 'red' : 'green'}
          className="list-item__price"
          noStock={noStock}
        />
      )}

      {encodingStatus &&
        encodingStatus !== VideoResponseDTO.encodingStatus.NONE &&
        encodingStatus !== VideoResponseDTO.encodingStatus.READY && (
          <EncodingBadge
            encodingStatus={encodingStatus}
            className="list-item__encoding"
          />
        )}
      {language && (
        <div
          className={classNames(
            'list-item__language',
            languageNotSupportedError && 'list-item__language--error',
          )}
        >
          {language.toUpperCase()}
        </div>
      )}

      {onClick && (
        <div className="list-item__arrow">
          <Icon icon="caret-right" />
        </div>
      )}
    </Tag>
  );
};

export default ListItem;
