/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KeyValueDTO } from '../models/KeyValueDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class KeyValueStoreService {

    /**
     * Gets key value store item with key = :key
     * @param key 
     * @returns KeyValueDTO 
     * @throws ApiError
     */
    public static getKeyValueStoreItem(
key: string,
): CancelablePromise<KeyValueDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/kv/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * Sets key value store item with key = :key to value
     * @param requestBody 
     * @returns KeyValueDTO 
     * @throws ApiError
     */
    public static setKeyValueStoreItem(
requestBody: KeyValueDTO,
): CancelablePromise<KeyValueDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/kv',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
