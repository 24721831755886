import classNames from 'classnames';
import CategoryBranch from 'components/CategoryBranch/CategoryBranch';
import Icon from 'components/Icon/Icon';
import { FlexBox } from 'components/Layout';
import ListItemSelect from 'components/ListItemSelect/ListItemSelect';
import { Category } from 'generated/gql/gql';
import React from 'react'
import CategoryUtils from '../utils';
type Props = {
    category: Category;
    onSelect: (category: Category, checked: boolean) => void;
    searchTerm?: string
    selected?: Category
}
const CategoryListItem: React.FC<Props> = ({ category, onSelect, searchTerm, selected }) => {
    const ancestry = CategoryUtils.getAncestors(selected);
    return <ListItemSelect
        key={category.id}
        selected={ancestry.some(
            ancestor => ancestor.id === category.id
        )
        }
        id={`check--${category.id}`}
        name={`check--${category.id}`}
        onChange={(checked: boolean) => {
            onSelect(category, checked)
        }
        }
        appearance={'chip'}
        className={classNames(
            'tree-select__option',
            'categories__option',
            (category.parent) &&
            'categories__option--parents',
        )}
    >
        <FlexBox justifyContent="space-between" alignItems="center">
            <div className="categories__name">
                <span>{category.name}</span>
                {category.parent && (
                    <CategoryBranch category={category} hideRoot />
                )}
            </div>

            {(category.children?.totalCount && !searchTerm) ? (
                <FlexBox
                    tag="button"
                    type="button"
                    className={classNames('tree-select__arrow')}
                    onClick={() => {
                        onSelect(category, true)
                    }}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Icon icon="arrow-right" />
                </FlexBox>
            ) : <></>}
        </FlexBox>
    </ListItemSelect>
}
export default CategoryListItem