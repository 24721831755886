import { useInfiniteQuery } from '@tanstack/react-query';
import {
  ApiError,
  PaginatedVideoResponseDTO,
  VendorVideoService,
} from 'generated';
import {
  GetSaleorProductsQueryVariables,
  ProductsQuery,
  ProductsQueryVariables,
  useInfiniteGetSaleorProductsQuery,
  useInfiniteProductsQuery,
} from 'generated/gql/gql';
import useAppStatus from 'hooks/useAppStatus';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectSaleorChannel } from 'redux/slices/global/globalSlice';
import Error from 'types/Error';
export enum KEYS {
  getProducts = 'getProducts',
  getProduct = 'getProduct',
  updateProducts = 'updateProducts',
  getProductVideos = 'getProductVideos',
}
export const productListKeys = {
  getProducts: ({
    search = '',
    ids = [],
  }: {
    search?: string;
    ids?: string[];
  }) => [KEYS.getProducts, ids.join(','), search],
};

export const useInfiniteProducts = (vars: ProductsQueryVariables) => {
  const { setAppStatus } = useAppStatus();
  const saleorChannel = useReduxSelector(selectSaleorChannel);
  const { t } = useTranslation();
  return useInfiniteProductsQuery<ProductsQuery, ApiError>(
    { ...vars, saleorChannel: saleorChannel! },

    {
      queryKey: productListKeys.getProducts({
        search: vars.search + vars.languageCode,
      }),
      getNextPageParam: (lastPage) =>
        lastPage?.products?.pageInfo?.hasNextPage
          ? { after: lastPage?.products?.pageInfo?.endCursor }
          : undefined,
      getPreviousPageParam: (firstPage) => ({
        after: firstPage?.products?.pageInfo?.startCursor,
      }),
      // suspense: true,
      onSuccess: (data) => {
        //INFO: check if there are any errors in the pages, because GraphQL doesn't throw an error if there is an error in the response
        if (data?.pages.some((page) => (page as any)?.errors?.length)) {
          setAppStatus(
            (data?.pages?.[0] as any)?.errors?.[0].message ??
              t('product:fetch.error'),
            'error',
          );
        }
        return data;
      },

      onError: (error: ApiError) => {
        setAppStatus(error?.message ?? t('product.fetch.error'), 'error');
      },
      staleTime: 1000 * 60 * 5,
      retry: false,
      enabled: !!saleorChannel,
    },
  );
};

export const useInfiniteSaleorProducts = ({
  ids,
  languageCode,
}: GetSaleorProductsQueryVariables) => {
  const { setAppStatus } = useAppStatus();
  const saleorChannel = useReduxSelector(selectSaleorChannel);

  const { t } = useTranslation();
  return useInfiniteGetSaleorProductsQuery(
    {
      ids,
      languageCode,
      saleorChannel: saleorChannel!,
    },
    {
      queryKey: productListKeys.getProducts({
        ids: ids as string[],
        search: languageCode,
      }),
      getNextPageParam: (lastPage) =>
        lastPage?.products?.pageInfo?.hasNextPage
          ? { after: lastPage?.products?.pageInfo?.endCursor }
          : undefined,
      getPreviousPageParam: (firstPage) => ({
        before: firstPage?.products?.pageInfo?.startCursor,
      }),
      onError: (error) => {
        setAppStatus(t('product:fetch.error'), 'error');
      },
      retry: false,
      enabled: !!ids?.length,
    },
  );
};
type ProductVideoParam =
  | {
      saleorProductIds: string[];
      searchTerm?: never;
      locale?: never;
    }
  | { saleorProductIds?: never; searchTerm: string; locale?: string };
export const useGetProductVideos = (param: ProductVideoParam) => {
  return useInfiniteQuery<PaginatedVideoResponseDTO, ApiError>({
    queryKey: [
      KEYS.getProductVideos,
      param?.saleorProductIds ?? param?.searchTerm ?? '',
    ],
    queryFn: async ({ pageParam = 0 }) =>
      VendorVideoService.getVideos(
        pageParam,
        10,
        param?.searchTerm,
        param.locale,
        param?.saleorProductIds,
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};
