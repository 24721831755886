import {
  ObjectWithMetadata,
  Product,
  ProductMediaType,
} from 'generated/gql/gql';
import { useMemo } from 'react';

export type CountableConnection<T> = {
  totalCount: number;
  pageInfo: {
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    startCursor?: string;
    endCursor?: string;
    fetchNextPage?: () => void;
  };
  edges: Array<{ node: T; cursor: string }>;
};
export const extractFromCountableConnection = <T extends { id: string }>(
  connection?: CountableConnection<T>[],
): Record<string, T> => {
  const items =
    connection
      ?.map((con) => con?.edges.map((edge) => edge.node))
      .flat()
      .filter(Boolean) ?? [];

  return items.reduce(
    (acc, item) => ({ ...acc, [item.id]: item }),
    {} as Record<string, T>,
  );
};

export const useExtractFromCountableConnection = <T extends { id: string }>(
  connection?: CountableConnection<T>[],
) => {
  const items = useMemo(
    () =>
      connection
        ?.map((con) => con?.edges.map((edge) => edge.node))
        .flat()
        .filter(Boolean) ?? [],
    [connection],
  );
  return useMemo(
    () => ({
      totalCount: connection?.[0].totalCount ?? 0,
      pageInfo: connection?.[0].pageInfo ?? {},
      items,
      records: items.reduce(
        (acc, item) => ({ ...acc, [item.id]: item }),
        {} as Record<string, T>,
      ),
      // getCursor: (id: string): string | undefined =>
      //   connection?.reduce(
      //     (acc, con) =>
      //       acc || con.edges.find((edge) => edge.node.id === id)?.cursor,
      //     undefined as string | undefined,
      //   ),
    }),
    [connection, items],
  );
};
export const pickMeta = (obj?: ObjectWithMetadata | undefined, key?: string) =>
  obj?.metadata?.find((meta) => meta.key === key)?.value ?? '';
export const pickJbeProductId = (product: Product) =>
  pickMeta(product, 'jbeProductId')
    ? parseInt(pickMeta(product, 'jbeProductId') as string)
    : undefined;
export const pickProductShopUrl = (product: Product) =>
  pickMeta(product, 'shopUrl') as string;
export const pickHashtags = (product?: Product): string[] => {
  const _tags = pickMeta(product, 'hashtags');
  return _tags.length && _tags.startsWith('[') && _tags !== '[object Object]'
    ? JSON.parse(_tags).map((val: any) => val?.name ?? '')
    : _tags === '[object Object]'
    ? []
    : _tags.split(',').filter(Boolean);
};
export const pickProductIsAvailableForPurchase = (product?: Product) =>
  product?.isAvailableForPurchase ?? false;
export const pickProductIsAvailable = (product?: Product) =>
  product?.isAvailable ?? false;
export const pickProductIsPublished = (product?: Product) =>
  product?.channelListings?.[0]?.isPublished ?? false;
export const pickProductPrice = (prod: Pick<Product, 'defaultVariant'>) =>
  prod?.defaultVariant?.pricing?.priceUndiscounted
    ? {
        amount: prod.defaultVariant.pricing?.priceUndiscounted?.gross?.amount!, //.channelListings[0]?.price?.amount ?? 0,
        originalPrice: pickProductOriginalPrice(prod),
        currency: prod.defaultVariant.pricing?.priceUndiscounted?.currency, //.channelListings[0]?.price?.currency ?? 'usd',
      }
    : {
        amount: 0,
        currency: 'USD',
      };
export const pickProductOriginalPrice = (
  prod: Pick<Product, 'defaultVariant'>,
) => Number(pickMeta(prod?.defaultVariant!, 'originalPrice')) || null;

export const pickProductName = (product?: Product) =>
  product?.translation?.name ?? product?.name;
export const pickProductVideoPreview = (product: Product) =>
  product?.media?.find((i) => i.type === ProductMediaType.Video)?.url; //.videos[0]?.videoPreviews?.find((i) => i.size === 'small')?.uri;
export const pickProductStock = (product: Product) =>
  product?.defaultVariant?.stocks?.[0]?.quantity ?? 0;
