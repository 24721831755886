import React, { useEffect } from 'react'
import SelectInteractiveField from 'components/SelectInteractive/SelectInteractiveField';
import { Field, useField, useFormikContext } from 'formik';

import useProductTypes from '../../hooks/useProductTypes';
import { useTranslation } from 'react-i18next';
const ProductTypeForm: React.FC = () => {
    const { t } = useTranslation('product')
    const { setFieldValue, } = useFormikContext();
    const [field, { error }] = useField<string>('productType');
    const productTypes = useProductTypes()
    const options = (productTypes ?? []).map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    useEffect(() => {
        if (!field.value && options.length) {
            setFieldValue(field.name, options[0]?.value)
        }
    }, [options])
    if (!productTypes
        || !productTypes.length
        || !options.length
        || (options.length === 1 &&
            field.value === options[0].value
        )) return null
    return <Field
        as='select'
        {...field}
        label={`${t('product:productType')}*`}
        component={SelectInteractiveField}
        isMulti={false}
        selectFirstOption={true}
        isDisabled={!!field.value && (options?.length === 1)}
        name='productType'
        error={error}
        options={options}
    >
        {options.map((productType) => (
            <option key={productType.value} value={productType.value}>
                {productType.label}
            </option>
        ))}
    </Field>

}
export default ProductTypeForm