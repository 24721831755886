import { useMutation } from '@tanstack/react-query';
import {
  ApiError,
  VideoRequestUploadUrlDTO,
  VideoUploadDTO,
  VendorVideoService,
} from 'generated';

/**
 * Gets a 3Q-URI to upload a video to. To upload the video with this URI request the upload from the redux store (feature/video-upload).
 */
export const useGetVideoUploadUrl = (index?: number) => {
  return useMutation<VideoUploadDTO, ApiError, VideoRequestUploadUrlDTO>({
    mutationFn: (variables) =>
      VendorVideoService.createVideoUploadUrl(variables),
    mutationKey: ['videoUploadUrl', index],
  });
};
