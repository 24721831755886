import { UseMutationResult } from '@tanstack/react-query';
import { ApiError, VideoResponseDTO } from 'generated';
import React, { ReactNode } from 'react';
import { useLinkProductToVideo } from '../queries';
import { Product } from 'generated/gql/gql';

type MutationType = UseMutationResult<
  VideoResponseDTO,
  ApiError,
  {
    productId?: number | null;
    videoId: number;
    saleorProductId?: string | null;
    product?: Product;
  }
>;

export const ProductLinkMutationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const linkProductMutation = useLinkProductToVideo();

  return (
    <MutationContext.Provider value={linkProductMutation}>
      {children}
    </MutationContext.Provider>
  );
};
const MutationContext = React.createContext<MutationType>({} as MutationType);

/** Provides the useLinkProductToVideo Mutation from feature/video-link-product inside a Context Provider.
 * The ProductLinkMutationProvider needs to be a parent of the component the hook is used in.
 * The same mutation state can then be used throughout the children components.
 * Make sure to reset the mutation, if you want to mutate it again.
 */
const useLinkProductToVideoWithContext = () =>
  React.useContext(MutationContext);

export default useLinkProductToVideoWithContext;
