/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InternalCreateReferralDto = {
    target?: InternalCreateReferralDto.target | null;
    referralLinkType: InternalCreateReferralDto.referralLinkType;
    referralLinkObjectKey?: string;
    campaignName: string;
    term?: string;
    content?: string;
    source?: string;
    medium?: string;
    category?: string;
    landingPageTitle?: string;
    landingPageParagraph?: string;
    landingPageImage?: string;
    landingPageLanguageIso?: string;
    targetId?: string | null;
};

export namespace InternalCreateReferralDto {

    export enum target {
        FEED = 'feed',
        DISCOVER = 'discover',
        CHANNEL_DETAILS = 'channel-details',
        WISHLIST = 'wishlist',
    }

    export enum referralLinkType {
        CHANNEL = 'channel',
        PRODUCT = 'product',
        APP = 'app',
        VIDEOLIST = 'videolist',
    }


}
