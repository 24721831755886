/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PreferredLanguageDTO } from './PreferredLanguageDTO';

export type AddVendorAgentWithRoleWithoutSuperAdminDTO = {
    email: string;
    name: string;
    preferredLanguages?: Array<PreferredLanguageDTO>;
    role: AddVendorAgentWithRoleWithoutSuperAdminDTO.role;
};

export namespace AddVendorAgentWithRoleWithoutSuperAdminDTO {

    export enum role {
        SALES_AGENT = 'sales_agent',
        SALES_ADMIN = 'sales_admin',
        REPORTER = 'reporter',
        ADMIN = 'admin',
    }


}
