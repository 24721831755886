/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChannelMinimalResponseDTO } from './ChannelMinimalResponseDTO';
import type { HashtagResponseDTO } from './HashtagResponseDTO';
import type { ProductResponseDTO } from './ProductResponseDTO';
import type { SharingUrlDTO } from './SharingUrlDTO';
import type { VideoItemDTO } from './VideoItemDTO';
import type { VideoPreviewDTO } from './VideoPreviewDTO';

export type VideoResponseDTO = {
    id: number;
    type: VideoResponseDTO.type;
    language: string;
    name: string;
    encodingStatus?: VideoResponseDTO.encodingStatus;
    meta?: string;
    videoOffset?: number;
    aspectRatio: number;
    videoDuration?: number;
    orientation?: VideoResponseDTO.orientation;
    videoItems: Array<VideoItemDTO>;
    sharingUrls?: Array<SharingUrlDTO>;
    channels?: Array<ChannelMinimalResponseDTO>;
    product?: ProductResponseDTO;
    saleorProductId: string | null;
    videoPreviews?: Array<VideoPreviewDTO>;
    visibility: VideoResponseDTO.visibility;
    updatedAt?: string;
    status: VideoResponseDTO.status;
    hashtags?: Array<HashtagResponseDTO>;
};

export namespace VideoResponseDTO {

    export enum type {
        PRODUCT = 'product',
        PROMOTION = 'promotion',
        CHANNEL = 'channel',
    }

    export enum encodingStatus {
        NONE = 'none',
        PROCESSING = 'processing',
        READY = 'ready',
        ERROR = 'error',
    }

    export enum orientation {
        PORTRAIT = 'portrait',
        LANDSCAPE = 'landscape',
    }

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }

    export enum status {
        DRAFT = 'draft',
        LIVE = 'live',
        IDLE = 'idle',
    }


}
