import { FlexBox, FlexBoxProps, Spacer } from 'components/Layout';
import Price from 'components/Price/Price';

export interface Props {
  currency?: string;
  className?: string;
  noStock?: boolean;
  originalPrice?: number | null;
  listingPrice?: number | null;
}

const PriceDiscount = ({
  currency = 'EUR',
  noStock,
  originalPrice,
  listingPrice,
  ...rest
}: Props & FlexBoxProps) => {
  const shouldDisplay = !!originalPrice && originalPrice !== listingPrice;
  return (
    <FlexBox
      display={shouldDisplay ? 'flex' : 'block'}
      justifyContent="flex-start"
      alignItems="flex-start"
      gap={1}
      {...rest}
    >
      {shouldDisplay && (
        <Spacer>
          <Price amount={listingPrice} variant={'green'} originalPrice={originalPrice} currency={currency} crossedOut noStock={noStock} hideNoStockTooltip />
        </Spacer>
      )}
      <Spacer>
        <Price
          amount={listingPrice}
          variant={'red'}
          currency={currency} noStock={noStock}
        />
      </Spacer>

    </FlexBox>
  );
};
export default PriceDiscount;
