import { useMutation } from '@tanstack/react-query';
import { videoDetailKeys } from 'features/video-detail/queries';
import { videoListKeys } from 'features/video-list';
import { VideoResponseDTO, ApiError, VendorVideoService } from 'generated';
import { Product } from 'generated/gql/gql';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export enum KEYS {
  linkProductToVideo = 'linkProductToVideo',
}

export const useLinkProductToVideo = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useMutation<
    VideoResponseDTO,
    ApiError,
    {
      productId?: number | null;
      videoId: number;
      // product?: Product;
      saleorProductId?: string | null;
    }
  >({
    mutationKey: [KEYS.linkProductToVideo],
    mutationFn: ({ productId, videoId, saleorProductId }) =>
      VendorVideoService.updateVideoProduct(videoId, {
        productId,
        saleorProductId,
      }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries(
        videoListKeys.getVideos(vendorAgent?.currentVendor?.id),
      );

      const previousVideo = queryClient.getQueryData(
        videoDetailKeys.getVideo(
          variables.videoId,
          vendorAgent?.currentVendor?.id,
        ),
      );

      queryClient.setQueryData<VideoResponseDTO>(
        videoDetailKeys.getVideo(
          variables.videoId,
          vendorAgent?.currentVendor?.id,
        ),
        (old?: VideoResponseDTO) => {
          if (old) {
            return {
              ...old,
              // product: variables.productId ? variables.product : undefined,
            };
          }
        },
      );

      return { previousVideo };
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        videoListKeys.getVideos(vendorAgent?.currentVendor?.id),
      );
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData<VideoResponseDTO>(
        videoDetailKeys.getVideo(
          variables.videoId,
          vendorAgent?.currentVendor?.id,
        ),
        (context as { previousVideo: VideoResponseDTO })?.previousVideo,
      );
    },
  });
};
