import { InfiniteData, UseInfiniteQueryOptions } from '@tanstack/react-query';
import {
  CategoriesQuery,
  CategorySortingInput,
  InputMaybe,
  useInfiniteCategoriesQuery,
  useInfiniteGetCategoryQuery,
} from 'generated/gql/gql';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
export enum KEYS {
  getCategories = 'getCategories',
  getCategoriesMinimal = 'getCategoriesMinimal',
  getCategoryTree = 'getCategoryTree',
}
export const categoriesKeys = {
  getCategories: (vendor?: string, searchTerm?: string) =>
    [KEYS.getCategories, vendor, searchTerm].filter((i) => i !== undefined),
  getCategoriesMinimal: [KEYS.getCategoriesMinimal],
  getCategoryTree: (rootCategoryId?: number) =>
    [KEYS.getCategoryTree, rootCategoryId].filter((i) => i !== undefined),
};
export const useCategoriesQuery = (
  search?: string,
  level?: number,
  sortBy?: InputMaybe<CategorySortingInput>,
  ops: UseInfiniteQueryOptions<CategoriesQuery> = {},
) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteCategoriesQuery(
    {
      filter: { search: search },
      ...(search ? {} : { level }),
      sortBy: sortBy,
    },
    {
      queryKey: categoriesKeys.getCategories(
        vendorAgent?.currentVendor?.id?.toString(),
        search,
      ),
      getNextPageParam: (lastPage) =>
        lastPage?.categories?.pageInfo?.endCursor
          ? { after: lastPage?.categories?.pageInfo?.endCursor }
          : undefined,
      getPreviousPageParam: (firstPage) => ({
        before: firstPage?.categories?.pageInfo?.startCursor,
      }),
      staleTime: 600 * 1000,
      ...ops,
      // 10 minutes
    },
  );
};
export const useCategoryQuery = (id?: string) => {
  return useInfiniteGetCategoryQuery(
    {
      id: id,
    },
    {
      queryKey: categoriesKeys.getCategories(id),

      getNextPageParam: (lastPage) =>
        lastPage?.category?.children?.pageInfo?.hasNextPage
          ? { afterChild: lastPage?.category?.children?.pageInfo?.endCursor }
          : undefined,
      getPreviousPageParam: (firstPage) => ({
        before: firstPage?.category?.children?.pageInfo?.startCursor,
      }),
      staleTime: 600 * 1000, // 10 minutes},
      enabled: !!id,
    },
  );
};
