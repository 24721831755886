import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { Field, FieldProps, getIn } from 'formik';
import { Category } from 'generated/gql/gql';
import { useCallback } from 'react';
import { Categories, CategoriesProps } from '..';

const CategoriesFieldWrap = ({
  field,
  form: { setFieldValue, errors, touched, ...rest },
  ...props
}: CategoriesProps & FieldProps) => {
  const err = getIn(errors, field.name);
  const tou = getIn(touched, field.name);
  const onChange = useCallback((newValue?: Category) => {
    setFieldValue(field.name, newValue);
    props.onSelectChange?.(newValue);
  }, [field.name, setFieldValue, props]);

  return (
    <ErrorBoundary>
      <Categories error={err && tou ? err.name : undefined} {...field} {...props} onSelectChange={onChange} />
    </ErrorBoundary >
  );
};

/**
 * This component is a Formik field, that controls the selection from a recursive tree such as categories.
 * Put this component as the component prop in a Formik Field.
 * This only works with a Formik wrapper, that populates this field with a context.
 */
const CategoriesField = ({ name }: { name?: string }) => {
  return (
    <Field
      component={CategoriesFieldWrap}
      name={name || 'category'}
      scrollTargetId={`scrolltarget--${name || 'category'}`}
      validate={(val: Category) => val?.id ? undefined : 'Required'}
    />
  );
};

export default CategoriesField;
