import classNames from 'classnames';
import Headline from 'components/Headline/Headline';
import Icon from 'components/Icon/Icon';
import { FlexBox, FlexItem, Grid, Spacer } from 'components/Layout';
import OrderActionChip from 'components/OrderActionChip/OrderActionChip';
import { utcToZonedTime } from 'date-fns-tz';
import { Shipping } from 'features/order-shipment';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useReduxSelector } from 'redux/hooks';
import { selectTimezone, selectWorldzone } from 'redux/slices/i18n/i18nSlice';
import { useGetOrder } from '../queries';
import Customer from './components/Customer';
import OrderHistory from './components/OrderHistory';
import Payment from './components/Payment';
import Product from './components/Product';
import './order-detail.scss';
import { KeyValueDTO, KeyValueStoreService } from 'generated';
import { Order } from 'generated/gql/gql';

const OrderDetail = ({ onClose }: { onClose?: () => void }) => {
  const { id: orderNo } = useParams();
  const { data: { order } = {}, error } = useGetOrder(orderNo!);
  const { t } = useTranslation(['translation', 'orders']);
  const timeZone = useReduxSelector(selectTimezone);
  const worldzone = useReduxSelector(selectWorldzone);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const [showShipping, setShowShipping] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      onClose && onClose();
    }
  }, [error]);

  const getShippingShow = async (): Promise<boolean> => {
    try {
      const keyValueDto = await KeyValueStoreService.getKeyValueStoreItem(
        KeyValueDTO.key.CREATOR_SHOW_SHIP_ROCKET_CREATE_SHIPMENT,
      );

      return keyValueDto.value === 'true';
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const orders = async () => {
      const show = await getShippingShow();
      setShowShipping(show);
    };
    orders();
  }, []);

  return (
    <div className="order-detail">
      <div className="modal__header order-detail__header">
        <div>
          {order?.status && !isDesktop && (
            <FlexBox alignItems="center">
              <OrderActionChip status={order?.status!} />
            </FlexBox>
          )}
          <FlexBox alignItems="center" tag="h1">
            <Spacer
              tag="span"
              className="order-detail__headline"
              marginRight={2}
            >
              {t('orders:detail.headline')}:
            </Spacer>
            <Spacer tag="span" className="order-detail__orderno">
              {orderNo}
            </Spacer>
            {order?.status && isDesktop && (
              <FlexBox marginLeft={10} alignItems="center">
                <OrderActionChip status={order?.status!} />
              </FlexBox>
            )}
          </FlexBox>
          {order?.created && (
            <Spacer tag="p">
              <span className="order-detail__date">
                {t('date', { date: utcToZonedTime(order.created, timeZone) })}
              </span>
            </Spacer>
          )}
        </div>
        {onClose && (
          <button className="modal__close" type="button" onClick={onClose}>
            <Icon icon="close" />
          </button>
        )}
      </div>

      <div className="order-detail__content">
        <Grid className="order-detail__content-inner">
          <FlexItem className="order-detail__col">
            <FlexItem
              padding={4}
              className="bg-white order-detail__box"
              flexGrow={1}
              display="flex"
              flexDirection="column"
            >
              <Headline
                headingLevel="h2"
                size={2}
                className="order-detail__box-headline"
              >
                {t('orders:detail.history.title')}
              </Headline>
              {/* //TODO insert */}
              {/* {orderStatusHistory && (
                <Spacer marginTop={5} style={{ height: '100%' }}>
                  <OrderHistory orderStatusHistory={orderStatusHistory} />
                </Spacer>
              )} */}
            </FlexItem>
          </FlexItem>

          <FlexItem className="order-detail__col">
            {/* //TODO insert */}
            {/* {worldzone === 'in' &&
              order?.status &&
              orderItems[0].product?.saleorId &&
              showShipping && (
                <Shipping
                  orderStatus={data.order?.status!}
                  productId={data.orderItems[0].product?.saleorId}
                  orderNo={orderNo}
                  pickupAddress={data?.pickupAddress}
                  paymentMethod={data?.paymentMethod}
                  requestedPickupDate={
                    data?.pickupdate// shipments?.[0]?.requestedPickupDate
                  }
                />
              )} */}
            {(worldzone === 'eu' || !showShipping) && (
              <Spacer
                padding={4}
                className="bg-white order-detail__box order-detail__box--grow"
              >
                <Headline
                  headingLevel="h2"
                  size={2}
                  className="order-detail__box-headline"
                >
                  {t('orders:detail.customer.title')}
                </Headline>
                <Spacer marginTop={5}>
                  <Customer
                    shippingAdress={order?.shippingAddress}
                    invoiceAdress={order?.billingAddress}
                  />
                </Spacer>
              </Spacer>
            )}

            {(worldzone === 'eu' || !showShipping) && (
              <Spacer
                padding={4}
                className="bg-white order-detail__box order-detail__box--grow"
              >
                <Headline
                  headingLevel="h2"
                  size={2}
                  className="order-detail__box-headline"
                >
                  {t('orders:detail.payment.title')}
                </Headline>
                <Spacer marginTop={5}>
                  <Payment
                    order={order as Order}
                  // paymentMethod={order?.paymentMethod}
                  // pricing={order?.pricing}
                  // productId={orderItems[0].product?.saleorId!}
                  />
                </Spacer>
              </Spacer>
            )}
          </FlexItem>

          <FlexItem className="order-detail__col">
            <FlexItem
              padding={4}
              className={classNames(!isDesktop && 'bg-white')}
              flexGrow={1}
            >
              <Headline
                headingLevel="h2"
                size={2}
                className="order-detail__box-headline"
              >
                {t('orders:detail.product.title')}
              </Headline>
              <Spacer marginTop={5}>
                {/* //TODO insert */}
                {/* <Product
                  orderItems={orderItems}
                  pricing={data?.pricing}
                /> */}
              </Spacer>
            </FlexItem>
            {worldzone === 'in' && showShipping && (
              <Spacer
                padding={4}
                className="bg-white order-detail__box"
                marginTop={1}
              >
                <Headline
                  headingLevel="h2"
                  size={2}
                  className="order-detail__box-headline"
                >
                  {t('orders:detail.customer.title')}
                </Headline>
                <Spacer marginTop={5}>
                  <Customer
                    shippingAdress={order?.shippingAddress}
                    invoiceAdress={order?.billingAddress}
                  />
                </Spacer>
              </Spacer>
            )}
            {worldzone === 'in' && showShipping && (
              <FlexItem
                padding={4}
                className="bg-white order-detail__box"
                marginTop={isDesktop ? 5 : 1}
              >
                <Headline
                  headingLevel="h2"
                  size={2}
                  className="order-detail__box-headline"
                >
                  {t('orders:detail.payment.title')}
                </Headline>
                <Spacer marginTop={5}>
                  <Payment
                    order={order as Order}
                  // paymentMethod={data?.paymentMethod}
                  // pricing={data?.pricing}
                  // productId={orderItems[0].product?.saleorId!}
                  />
                </Spacer>
              </FlexItem>
            )}
          </FlexItem>
        </Grid>
      </div>
    </div>
  );
};

export default OrderDetail;
