import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import {
  VideoItemDTO,
  VideoPreviewDTO,
  VideoResponseDTO,
} from '../../generated';
import placeholder from '../../images/placeholder-video-mediathek-gradient.svg';
import EncodingBadge from '../EncodingBadge/EncodingBadge';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import LazyImage from '../LazyImage/LazyImage';
import VideoDownload from '../VideoDownload/VideoDownload';
import './video-player.scss';

export interface Props {
  id: number;
  language: string;
  videoItems?: Array<VideoItemDTO>;
  name: string;
  isAvailable?: boolean;
  children?: React.ReactNode;
  encodingStatus?: VideoResponseDTO.encodingStatus;
  videoPreviews?: VideoPreviewDTO[];
  isVisible: boolean;
  error?: {
    badge: string;
    text: string;
  };
}

const VideoPlayer = ({
  language,
  videoItems,
  name,
  isAvailable, // product,
  id,
  children,
  encodingStatus,
  videoPreviews,
  isVisible,
  error,
}: Props) => {
  const { t } = useTranslation(['video']);
  const [showPlayer, setShowPlayer] = useState(false);
  const videoUrlMp4 = videoItems?.find((i) => i.streamType === 'mp4_low')?.url;
  const videoUrlHls = videoItems?.find((i) => i.streamType === 'hls')?.url;
  const preview = videoPreviews?.find((i) => i.size === 'small')?.uri;

  return (
    <div className="video-player">
      <div className="video-player__controls">
        <div className="video-player__lang">{language}</div>
        <IconButton
          icon={showPlayer ? 'close' : 'play'}
          appearance="filled"
          onClick={() => setShowPlayer(!showPlayer)}
          tooltip={showPlayer ? t('video:play.hide') : t('video:play.show')}
          tooltipPlace="up"
          big
        />
        {(videoItems?.length || 0) > 0 && (
          <VideoDownload
            items={[
              VideoItemDTO.streamType.MP4_HIGH,
              VideoItemDTO.streamType.MP4_MEDIUM,
              VideoItemDTO.streamType.MP4_LOW,
            ].map(
              (type) =>
                videoItems?.find((i) => i.streamType === type) as VideoItemDTO,
            )}
          />
        )}
        {children}
      </div>

      <div className="video-player__wrap">
        {showPlayer && (videoUrlMp4 || videoUrlHls) ? (
          <div className="video-player__video">
            <ReactPlayer
              url={videoUrlMp4 || videoUrlHls}
              controls={true}
              playing
              width={
                getComputedStyle(
                  document.documentElement.querySelector('.video-player') ||
                  document.documentElement,
                ).getPropertyValue('--video-width') || 'auto'
              }
              height={
                getComputedStyle(
                  document.documentElement.querySelector('.video-player') ||
                  document.documentElement,
                ).getPropertyValue('--video-height') || '100%'
              }
            />
          </div>
        ) : (
          <div className="video-player__image-wrap">
            <LazyImage
              src={preview || placeholder}
              alt={name}
              aspectRatio={202 / 360}
              lqip={placeholder}
              className="video-player__image"
            />
            {!isAvailable && (
              <div className="video-player__image-overlay">
                <Icon icon="block" />
              </div>
            )}
          </div>
        )}

        <div className="video-player__status">
          {error && (
            <div className="video-player__status-item">
              <div className="video-player__status-text">{error.text}</div>
              <div className="icon encoding-badge encoding-badge--processing encoding-badge--small">
                <Icon icon="info" />
                {error.badge}
              </div>
            </div>
          )}

          {(encodingStatus === VideoResponseDTO.encodingStatus.PROCESSING ||
            encodingStatus === VideoResponseDTO.encodingStatus.ERROR) && (
              <div className="video-player__status-item">
                <div className="video-player__status-text">
                  {t(`video:encodingStatus.${encodingStatus}Text`)}
                </div>
                <EncodingBadge encodingStatus={encodingStatus} disableTooltip />
              </div>
            )}

          {!isVisible && (
            <div className="video-player__status-item">
              <div className="video-player__status-text">
                {t(`video:not-visible-text`)}
              </div>
            </div>
          )}
        </div>
      </div>
      {showPlayer && !videoUrlMp4 && !videoUrlHls && (
        <div className="video-player__error">{t('video:play.error')}</div>
      )}
    </div>
  );
};

export default VideoPlayer;

// t('video:encodingStatus.processing')
// t('video:encodingStatus.processingText')
// t('video:encodingStatus.error')
// t('video:encodingStatus.errorText')
