/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateSharingUrlDTO = {
    name?: string | null;
    videoId: number;
    channelId: number;
    sharingType?: CreateSharingUrlDTO.sharingType;
    sharingRefId?: number | null;
};

export namespace CreateSharingUrlDTO {

    export enum sharingType {
        VENDOR_AGENT = 'vendor_agent',
        VENDOR = 'vendor',
    }


}
