import { useInfiniteQuery } from '@tanstack/react-query';
import { VideoFilterQueryType } from 'features/video-filter/types';
import { ApiError, PaginatedVideoResponseDTO, VendorVideoService } from 'generated';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export enum KEYS {
  getVideos = 'getVideos',
}

export const videoListKeys = {
  getVideos: (vendorAgentId?: number, filterQuery?: VideoFilterQueryType) =>
    [KEYS.getVideos, vendorAgentId, filterQuery].filter((i) => i !== undefined),
};

export const useGetVideos = (filter?: VideoFilterQueryType) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useInfiniteQuery<PaginatedVideoResponseDTO, ApiError>({
    queryKey: videoListKeys.getVideos(vendorAgent?.currentVendor?.id, filter),
    queryFn: async ({ pageParam = 0 }) =>
      VendorVideoService.getVideosV2(
        pageParam,
        undefined,
        filter?.channelIds,
        [],
        filter?.productIds,
        filter?.categoryIds,
        filter?.hashtagIds,
        filter?.type as ('product' | 'promotion' | 'channel')[],
        filter?.status as ('idle' | 'draft' | 'live')[],
        filter?.visibility as ('public' | 'private')[],
      ),
    getNextPageParam: (lastPage) => lastPage?.links?.next?.offset,
  });
};
