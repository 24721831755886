/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PurchaseBasketItemsDto = {
    paymentMethod: PurchaseBasketItemsDto.paymentMethod;
    /**
     * In context of Stripe payments this is the intentId.
     */
    transactionId?: string;
};

export namespace PurchaseBasketItemsDto {

    export enum paymentMethod {
        CASH_ON_DELIVERY = 'cashOnDelivery',
        ACSS_DEBIT = 'acss_debit',
        AFFIRM = 'affirm',
        AFTERPAY_CLEARPAY = 'afterpay_clearpay',
        ALIPAY = 'alipay',
        AU_BECS_DEBIT = 'au_becs_debit',
        BACS_DEBIT = 'bacs_debit',
        BANCONTACT = 'bancontact',
        BLIK = 'blik',
        BOLETO = 'boleto',
        CARD = 'card',
        CASHAPP = 'cashapp',
        CUSTOMER_BALANCE = 'customer_balance',
        EPS = 'eps',
        FPX = 'fpx',
        GIROPAY = 'giropay',
        GRABPAY = 'grabpay',
        IDEAL = 'ideal',
        KLARNA = 'klarna',
        KONBINI = 'konbini',
        LINK = 'link',
        OXXO = 'oxxo',
        P24 = 'p24',
        PAYNOW = 'paynow',
        PAYPAL = 'paypal',
        PIX = 'pix',
        PROMPTPAY = 'promptpay',
        SEPA_DEBIT = 'sepa_debit',
        SOFORT = 'sofort',
        US_BANK_ACCOUNT = 'us_bank_account',
        WECHAT_PAY = 'wechat_pay',
        ZIP = 'zip',
    }


}
