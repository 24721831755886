import {
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { setDefaultLocale } from 'react-datepicker';
import { Tab } from '../../../components/Tabs/Tabs';
import {
  defaultFallbackLanguage,
  defaultFallbackLocale,
} from '../../../global-constants';
import { LocaleDTO, OpenAPI } from '../../../generated';
import i18n, { getLocale } from '../../../i18n';
import { startAppListening } from '../../middlewares/listenerMiddleware';
import { RootState } from '../../store';
import { init, logIn } from '../auth/authSlice';
import { LanguageCodeEnum } from 'generated/gql/gql';
import { localeTolanguageCodeEnum } from 'container/ProductDetailContainer/utils';

export type Worldzone = 'eu' | 'in';

export interface AuthState {
  timezone: string;
  localeIso: string;
  languageCode: string;
  countryCode: string;
  supportedLanguages: LocaleDTO[];
  worldzone: Worldzone;
}

const initialState: AuthState = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  localeIso: defaultFallbackLocale,
  languageCode: defaultFallbackLanguage.substring(0, 2),
  countryCode: defaultFallbackLanguage.substring(3),
  supportedLanguages: [
    { language: 'English', iso: defaultFallbackLanguage, country: 'Europe' },
  ],
  worldzone: 'in',
};

const zones: { [key in Worldzone]: string[] } = {
  eu: ['it', 'de', 'fr'],
  in: ['in'],
};

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    changedLanguage: (state, action: PayloadAction<string>) => {
      state.languageCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logIn.fulfilled, (state, action) => {
        state.localeIso = action.payload.defaultLocale;
        state.supportedLanguages = action.payload.supportedLanguages;
        state.timezone = action.payload.defaultTimezone;
        state.languageCode = action.payload.defaultLocale.substring(0, 2);
        const countryCode = action.payload.defaultLocale.substring(
          3,
        ) as Worldzone;
        state.countryCode = countryCode;
        state.worldzone =
          Object.keys(zones).find((i) =>
            zones[i].includes(countryCode.toLocaleLowerCase()),
          ) || 'in';
      })
      .addCase(init.fulfilled, (state, action) => {
        state.localeIso = action.payload.defaultLocale;
        state.supportedLanguages = action.payload.supportedLanguages;
        state.timezone = action.payload.defaultTimezone;
        state.languageCode = action.payload.defaultLocale.substring(0, 2);
        const countryCode = action.payload.defaultLocale.substring(
          3,
        ) as Worldzone;
        state.countryCode = countryCode;
        state.worldzone =
          Object.keys(zones).find((i) =>
            zones[i].includes(countryCode.toLocaleLowerCase()),
          ) || 'in';
      });
  },
});

export const selectLocaleIso = (state: RootState) => state.i18n.localeIso;
export const selectLanguageCode = (state: RootState) => state.i18n.languageCode;
export const selectTimezone = (state: RootState) => state.i18n.timezone;
export const selectSupportedLanguages = (state: RootState) =>
  state.i18n.supportedLanguages;
export const selectCountryCode = (state: RootState) => state.i18n.countryCode;
export const selectWorldzone = (state: RootState) => state.i18n.worldzone;
export const selectLanguageCodeEnum = createSelector(
  [selectLanguageCode, selectCountryCode],
  (languageCode, countryCode) =>
    localeTolanguageCodeEnum({ country: countryCode, iso: languageCode }),
);

export const selectSupportedLanguagesTabs = createSelector(
  selectSupportedLanguages,
  (supportedLanguages) => {
    const tabs: Tab<string>[] = supportedLanguages.map((lang) => ({
      tab: lang.iso,
      text: lang.iso,
    }));

    return tabs;
  },
);

export const selectLocale = createSelector(selectLocaleIso, (localeIso) =>
  getLocale(localeIso),
);

// Action creators are generated for each case reducer function
export const { changedLanguage } = i18nSlice.actions;

// Create the middleware instance and methods

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
startAppListening({
  matcher: isAnyOf(logIn.fulfilled, init.fulfilled, changedLanguage),
  effect: async (action, { getState }) => {
    const { localeIso, timezone } = getState().i18n;

    i18n.changeLanguage(localeIso, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    });

    setDefaultLocale(localeIso);

    OpenAPI.HEADERS = {
      'x-client-timezone': timezone,
    };
  },
});

export default i18nSlice.reducer;
