/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { HashtagEntityDTO } from './HashtagEntityDTO';

export type BatchLinkHashtagsErrorDto = {
    entity: HashtagEntityDTO;
    reason: BatchLinkHashtagsErrorDto.reason;
};

export namespace BatchLinkHashtagsErrorDto {

    export enum reason {
        MAX_HASHTAGS = 'MAX_HASHTAGS',
        NOT_FOUND = 'NOT_FOUND',
        FORBIDDEN = 'FORBIDDEN',
        ERROR = 'ERROR',
    }


}
