/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LandingPageDTO } from '../models/LandingPageDTO';
import type { LandingPageResponseDTO } from '../models/LandingPageResponseDTO';
import type { VendorCreateReferralDto } from '../models/VendorCreateReferralDto';
import type { VendorPaginatedReferralResponseDto } from '../models/VendorPaginatedReferralResponseDto';
import type { VendorReferralResponseDto } from '../models/VendorReferralResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorReferralService {

    /**
     * List all ReferralLinks that belong to authenticated VendorAgent
     * @param offset 
     * @param limit 
     * @param search 
     * @param source 
     * @param medium 
     * @returns VendorPaginatedReferralResponseDto 
     * @throws ApiError
     */
    public static getReferralLinks(
offset?: number,
limit?: number,
search?: string,
source?: string,
medium?: string,
): CancelablePromise<VendorPaginatedReferralResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/referrals',
            query: {
                'offset': offset,
                'limit': limit,
                'search': search,
                'source': source,
                'medium': medium,
            },
        });
    }

    /**
     * Create a new ReferralLink in Firebase
     * @param requestBody 
     * @returns VendorReferralResponseDto 
     * @throws ApiError
     */
    public static createReferralLink(
requestBody: VendorCreateReferralDto,
): CancelablePromise<VendorReferralResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/referrals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update LandingPage settings of Referral
     * @param id 
     * @param requestBody 
     * @returns LandingPageResponseDTO 
     * @throws ApiError
     */
    public static updateReferralLandingPage(
id: number,
requestBody: LandingPageDTO,
): CancelablePromise<LandingPageResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/referrals/{id}/landingPage',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Remove the specified ReferralLink
     * @param referralId 
     * @returns VendorReferralResponseDto 
     * @throws ApiError
     */
    public static softRemoveReferralLink(
referralId: number,
): CancelablePromise<VendorReferralResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/referrals/{referralId}',
            path: {
                'referralId': referralId,
            },
        });
    }

    /**
     * Send an e-mail with the specified referral to all Admins of the current vendor
     * @param referralId 
     * @returns void 
     * @throws ApiError
     */
    public static sendReferralEmailToAdmins(
referralId: number,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/referrals/{referralId}/sendmail',
            path: {
                'referralId': referralId,
            },
        });
    }

}
