import classNames from 'classnames';
import CategoryBranch from 'components/CategoryBranch/CategoryBranch';
import Chip from 'components/Chip/Chip';
import LabelText from 'components/LabelText/LabelText';
import LabeledInfoText from 'components/LabeledInfoText/LabeledInfoText';
import { FlexBox, FlexItem, Spacer } from 'components/Layout/Layout';
import LocalisationTabs from 'components/LocalisationTabs/LocalisationTabs';
import TabPane from 'components/LocalisationTabs/TabPane';
import { VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ColorUse } from 'types/Color';
import { Category, Product } from 'generated/gql/gql';
import { useReduxSelector } from 'redux/hooks';
import { selectSupportedLanguages } from 'redux/slices/i18n/i18nSlice';
import { localeStringToLanguageCodeEnum, localeTolanguageCodeEnum } from 'container/ProductDetailContainer/utils';
import { useSaleorProduct } from 'api/GraphQL/queries.gql';
import { pickHashtags, pickProductIsAvailable, pickProductPrice, pickProductShopUrl, pickProductStock } from 'api/GraphQL/util';

const ProductInfoContainer = ({
  productId,
  error,
  success,
  initialLanguage,
  footerSlot,
  controlsSlot,
  className,
}: {
  productId: string;
  error?: string;
  success?: string;
  initialLanguage?: string;
  footerSlot?: ReactNode;
  controlsSlot?: ReactNode;
  className?: string;
}) => {
  const { t } = useTranslation();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages)
  const { jbeProductId, params, ...product } = useSaleorProduct(productId, localeStringToLanguageCodeEnum(initialLanguage));
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const hashtags = pickHashtags(product as Product);

  const initialLocalisation = useMemo(() => ({
    //TODO : validate if this is correct
    id: product.translation?.language?.code!,
    title: product.name!,
    subtitle: product?.externalReference!,
    name: product.translation?.language?.code!,
    price: pickProductPrice(product as Product),
    // discountPrice: pickProductPrice(product as Product).discountPrice,
    currencyCode: product.defaultVariant?.pricing?.price?.gross?.currency,
    noStock: pickProductStock(product as Product) === 0,
  }), [product,]);

  return (
    <FlexBox
      className={classNames('product-info', className)}
      flexDirection="column"
    >
      <FlexBox justifyContent="space-between" alignItems="flex-start">
        <FlexItem tag="header">
          <Spacer marginBottom={isDesktop ? 6 : 2}>
            <h2 className="caption--dark">{t('video:product.label')}</h2>
          </Spacer>

          {controlsSlot && (
            <Spacer
              marginBottom={isDesktop ? 2 : 10}
              className="small product-info__controls"
            >
              {controlsSlot}
            </Spacer>
          )}
        </FlexItem>
        <FlexItem>
          {error && (
            <Spacer margin={[1, 0, 4]}>
              <Chip role="alert" color={ColorUse['warning-50']}>
                {error}
              </Chip>
            </Spacer>
          )}
          {success && (
            <Spacer margin={[1, 0, 4]}>
              <Chip role="alert" color={ColorUse['confirm-50']}>
                {success}
              </Chip>
            </Spacer>
          )}
        </FlexItem>
      </FlexBox>
      {product && (
        <LocalisationTabs
          initial={initialLocalisation}
          showTabPaneContent={isDesktop}
          status={
            !pickProductIsAvailable(product)
              ? VideoResponseDTO.status.IDLE
              : VideoResponseDTO.status.LIVE
          }
        >
          {supportedLanguages.map((info) => (
            <TabPane
              key={info.iso}
              id={localeTolanguageCodeEnum(info)}
              title={product.name === '' ? t('no_title') : product.name}
              subtitle={product.externalReference!}
              name={localeTolanguageCodeEnum(info)}
              price={pickProductPrice(product as Product)
              }
              noStock={pickProductStock(product as Product) === 0}
            // originalPrice={(product.defaultVariant?.pricing?.discount?.gross?.amount ? product.defaultVariant?.pricing?.discount?.gross?.amount * 100 : undefined)}
            >
              {isDesktop && (
                <>
                  {pickProductShopUrl(product as Product) && (
                    <LabelText label={t('product:shop_url')}>
                      <p className="product-info__text product-info__text--ellipsis">
                        <a
                          className="link"
                          href={pickProductShopUrl(product as Product)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {pickProductShopUrl(product as Product)}
                        </a>
                      </p>
                    </LabelText>
                  )}
                  {/* TODO Callapsible when two much content - collapsible=true */}
                  {
                    <Spacer marginTop={4}>
                      <LabelText
                        label={t('product:info')}
                        collapsible
                        initiallyCollapsed
                      >
                        <LabeledInfoText staticContent={JSON.parse(product?.translation?.description ?? product?.description ?? '{"blocks":[]}')?.blocks} />
                      </LabelText>
                    </Spacer>
                  }
                </>
              )}
            </TabPane>
          ))}
        </LocalisationTabs>
      )}
      {product?.category && (
        <Spacer marginTop={6}>
          <LabelText label={t('product:categories.label')}>
            <Spacer key={product?.category?.id}>
              <CategoryBranch category={product?.category as Category} />
            </Spacer>
          </LabelText>
        </Spacer>
      )}

      {hashtags && hashtags.length > 0 && (
        <Spacer marginTop={4}>
          <LabelText label={t('product:hashtag.label')}>
            {hashtags.map((hashtag, index) => (
              <span className="product-info__category" key={hashtag}>
                {`#${hashtag}${(hashtags?.length || 0) > 1 &&
                  index !== (hashtags?.length || 0) - 1
                  ? ', '
                  : ''
                  }`}
              </span>
            ))}
          </LabelText>
        </Spacer>
      )
      }
      <FlexItem
        tag="footer"
        justifyContent="flex-end"
        paddingTop={isDesktop ? 20 : 10}
        className="small"
        pushBottom
      >
        <FlexItem
          padding={1}
          margin={[0, 1]}
          className="video-detail__product-footer-item"
          pushLeft
        >
          <Link to={`/products/${productId}`} className="icon-link">
            <span className="icon-link__text">{t('product:footer.go')}</span>
          </Link>
        </FlexItem>
        {footerSlot}
      </FlexItem>
    </FlexBox>
  );
};

export default ProductInfoContainer;
