import { Hashtags } from 'features/hashtags';
import { Form, Formik, FormikProps } from 'formik';
import { HashtagEntityDTO, VideoResponseDTO } from 'generated';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import VideoUpdateLanguage from './components/VideoUpdateLanguage';
import VideoUpdateName from './components/VideoUpdateName';
import VideoUpdateType from './components/VideoUpdateType';
import VideoUpdateVisibility from './components/VideoUpdateVisibility';

export interface Props {
  type: VideoResponseDTO.type;
  name: string;
  language: string;
  visibility: VideoResponseDTO.visibility;
  id: number;
}

/**
 * Form to update video details as inline-edit fields.
 */
const VideoUpdateForm = ({ type, name, language, id, visibility }: Props) => {
  const { t } = useTranslation(['translation', 'video']);

  return (
    <Formik
      initialValues={{ type, name, language, visibility }}
      enableReinitialize
      onSubmit={() => { }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .matches(/^(?!\s+$)/, t('form.required'))
          .required(t('form.required')),
      })}
    >
      {(formik: FormikProps<Omit<Props, 'id'>>) => (
        <Form>
          <VideoUpdateType videoId={id} />
          <VideoUpdateName videoId={id} />
          <VideoUpdateLanguage videoId={id} />
          <VideoUpdateVisibility videoId={id} />
          {type !== VideoResponseDTO.type.CHANNEL && (
            <Hashtags
              entityId={id}
              entityType={HashtagEntityDTO.entityType.VIDEO}
              description={t('video:hashtag.video-desc')}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default VideoUpdateForm;
