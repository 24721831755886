// import { gqlHookFetcher } from 'api/GraphQL/gql.fetcher';
import {
  CountableConnection,
  extractFromCountableConnection,
} from 'api/GraphQL/util';

import { productListKeys } from 'features/product-list';
import { PaginatedVideoResponseDTO } from 'generated';
import {
  GetSaleorProductsDocument,
  GetSaleorProductsQueryVariables,
  LanguageCodeEnum,
  Product,
  GetSaleorProductsQuery,
  useGetSaleorProductsQuery,
} from 'generated/gql/gql';

// export const _fetchSaleorProducts = (param: GetSaleorProductsQueryVariables) =>
//   gqlHookFetcher<GetSaleorProductsQuery, GetSaleorProductsQueryVariables>(
//     GetSaleorProductsDocument,
//     {
//       queryKey: productListKeys
//         .getProducts({
//           search: param.languageCode,
//           ids: param.ids as string[],
//         })
//         .join(','),
//     },
//   );

export const handleVideoProducts = async (
  res: PaginatedVideoResponseDTO | PaginatedVideoResponseDTO[] | string[],
  languageCode: LanguageCodeEnum,
  updateRecord: (partial: Record<string, Product>) => void,
  setLoading: (loading: boolean) => void,
  channelSlug: string,
) => {
  try {
    const ids = (
      Array.isArray(res)
        ? typeof res[0] === 'string'
          ? (res as string[])
          : (res as PaginatedVideoResponseDTO[]).flatMap((v) =>
              v.data.map((v) => v.saleorProductId),
            )
        : res.data.map((v) => v.saleorProductId)
    ).filter(Boolean) as string[];
    const { products } =
      ids.length > 0
        ? await useGetSaleorProductsQuery.fetcher({
            ids,
            languageCode,
            saleorChannel: channelSlug,
          })()
        : { products: null };
    const extract = extractFromCountableConnection([
      products as CountableConnection<Product>,
    ]);
    updateRecord(extract);
    setLoading(false);
  } catch (error) {
    console.log('error', error);
    setLoading(false);
  }
};
