/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppPreparePaymentRequestDto = {
    provider: AppPreparePaymentRequestDto.provider;
};

export namespace AppPreparePaymentRequestDto {

    export enum provider {
        SHIP_ROCKET = 'shipRocket',
        STRIPE = 'stripe',
    }


}
