/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddVendorAgentWithRoleWithoutSuperAdminDTO } from '../models/AddVendorAgentWithRoleWithoutSuperAdminDTO';
import type { LoginVendorAgentResponse } from '../models/LoginVendorAgentResponse';
import type { ResetPasswordDTO } from '../models/ResetPasswordDTO';
import type { SetNewPasswordDTO } from '../models/SetNewPasswordDTO';
import type { SwitchVendorDTO } from '../models/SwitchVendorDTO';
import type { UpdateCurrentVendorAgentDTO } from '../models/UpdateCurrentVendorAgentDTO';
import type { UpdatePasswordDTO } from '../models/UpdatePasswordDTO';
import type { UpdateVendorAgentRoleDTO } from '../models/UpdateVendorAgentRoleDTO';
import type { VendorAgentApiKeyDTO } from '../models/VendorAgentApiKeyDTO';
import type { VendorAgentLoginDataDTO } from '../models/VendorAgentLoginDataDTO';
import type { VendorAgentResponseDTO } from '../models/VendorAgentResponseDTO';
import type { VendorAgentResponseWithVendorDTO } from '../models/VendorAgentResponseWithVendorDTO';
import type { VendorAgentRolesDTO } from '../models/VendorAgentRolesDTO';
import type { VerifyTokenDTO } from '../models/VerifyTokenDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VendorAgentsService {

    /**
     * Log in a VendorAgent
     * @param requestBody 
     * @returns LoginVendorAgentResponse 
     * @throws ApiError
     */
    public static loginVendorAgent(
requestBody: VendorAgentLoginDataDTO,
): CancelablePromise<LoginVendorAgentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendors/agents/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Switches the vendor for a superAdmin
     * @param requestBody 
     * @returns VendorAgentResponseWithVendorDTO 
     * @throws ApiError
     */
    public static switchVendor(
requestBody: SwitchVendorDTO,
): CancelablePromise<VendorAgentResponseWithVendorDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendors/agents/switchVendor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Adds a new VendorAgent to a Vendor
     * @param requestBody 
     * @returns VendorAgentResponseDTO 
     * @throws ApiError
     */
    public static addVendorAgent(
requestBody: AddVendorAgentWithRoleWithoutSuperAdminDTO,
): CancelablePromise<VendorAgentResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendors/agents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gets all Agents for the current Vendor
     * @returns VendorAgentResponseDTO 
     * @throws ApiError
     */
    public static getVendorAgents(): CancelablePromise<Array<VendorAgentResponseDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendors/agents',
        });
    }

    /**
     * Updates currently logged in vendorAgent
     * @param requestBody 
     * @returns VendorAgentResponseDTO 
     * @throws ApiError
     */
    public static updateCurrentVendorAgent(
requestBody: UpdateCurrentVendorAgentDTO,
): CancelablePromise<VendorAgentResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/vendors/agents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gets currently authenticated VendorAgent
     * @returns LoginVendorAgentResponse 
     * @throws ApiError
     */
    public static getVendorAgent(): CancelablePromise<LoginVendorAgentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendors/agents/current',
        });
    }

    /**
     * Updates role of VendorAgent with specified id
     * @param id 
     * @param requestBody 
     * @returns VendorAgentResponseDTO 
     * @throws ApiError
     */
    public static updateVendorAgentRole(
id: number,
requestBody: UpdateVendorAgentRoleDTO,
): CancelablePromise<VendorAgentResponseDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/vendors/agents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes VendorAgent with specified id
     * @param id 
     * @returns VendorAgentResponseDTO 
     * @throws ApiError
     */
    public static deleteVendorAgent(
id: number,
): CancelablePromise<VendorAgentResponseDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/vendors/agents/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Gets a permanent API key for authenticated vendorAgent
     * @returns VendorAgentApiKeyDTO 
     * @throws ApiError
     */
    public static getApiKey(): CancelablePromise<VendorAgentApiKeyDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendors/agents/apiKey',
        });
    }

    /**
     * Revokes API key for authenticated vendorAgent
     * @returns VendorAgentResponseDTO 
     * @throws ApiError
     */
    public static revokeApiKey(): CancelablePromise<VendorAgentResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendors/agents/apiKey/revoke',
        });
    }

    /**
     * Updates the password of the current logged in VendorAgent
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static updatePassword(
requestBody: UpdatePasswordDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/vendors/agents/updatePassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sets a new password for in token encoded VendorAgent
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static setNewPassword(
requestBody: SetNewPasswordDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendors/agents/setNewPassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Checks if password reset token is valid
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static verifyToken(
requestBody: VerifyTokenDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendors/agents/setNewPassword/verifyToken',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sends an email with password reset link
     * @param requestBody 
     * @returns any 
     * @throws ApiError
     */
    public static resetPassword(
requestBody: ResetPasswordDTO,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/vendors/agents/resetPassword/email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get available roles for vendor agents
     * @returns VendorAgentRolesDTO 
     * @throws ApiError
     */
    public static getRoles(): CancelablePromise<VendorAgentRolesDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/vendors/agents/roles',
        });
    }

}
