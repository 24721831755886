import { ApiError, VideoResponseDTO } from '../generated';
import { Action, State, ActionType } from '../hooks/useList';

const videoListReducer = (
  state: State<VideoResponseDTO>,
  action: Action<VideoResponseDTO>,
): State<VideoResponseDTO> => {
  switch (action.type) {
    case ActionType.LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionType.RELOAD:
      return {
        ...state,
        items: [],
        loading: true,
      };
    case ActionType.GET_SUCCESS:
      return {
        ...state,
        items:
          state.items && !action.payload.initial
            ? state.items.concat(action.payload.items)
            : action.payload.items,
        error: undefined,
        loading: false,
        totalCount: action.payload.totalCount,
        loadMore: action.payload.next ? true : false,
        initialyLoaded: true,
      };
    case ActionType.GET_ERROR:
      return {
        ...state,
        error: action.payload.error as ApiError,

        loading: false,
      };
    case ActionType.UPDATE:
      return {
        ...state,
        items: state?.items?.map((item) => {
          if (item.id === action.payload.id) {
            return {
              id: action.payload.id,
              ...action.payload.item,
            } as VideoResponseDTO;
          }
          return item;
        }),
        loading: false,
        error: undefined,
      };
    case ActionType.UPDATE_MULTI:
      return {
        ...state,
        items: state?.items?.map((item) => {
          const newItem = (action.payload.items as VideoResponseDTO[]).find(
            (i) => i.id === item.id,
          );
          return newItem ? newItem : item;
        }),
        loading: false,
        error: undefined,
      };
    case ActionType.ADD:
      return {
        ...state,
        items: [action.payload.item, ...(state.items || [])],
        loading: false,
        error: undefined,
      };
    case ActionType.DELETE:
      return {
        ...state,
        items:
          state.items &&
          state.items.filter((item) => item.id !== action.payload.id),
        totalCount: state.totalCount && state.totalCount - 1,
        loading: false,
        error: undefined,
      };
    case ActionType.DELETE_MULTI:
      const newItems =
        state.items &&
        state.items.filter((item) => !action.payload.items?.includes(item.id));
      return {
        ...state,
        items: newItems,
        totalCount: newItems?.length,
        loading: false,
        error: undefined,
      };
    case ActionType.DEACTIVATE:
      return state;
    case ActionType.FINISH_LOADING:
      return {
        ...state,
        loading: false,
      };
  }
  return state;
};

export default videoListReducer;
