import React, { FC } from 'react';
import { Props as PriceType } from '../Price/Price';

export interface TabPaneType {
  name: string;
  title?: string;
  subtitle?: string;
  id: string;
  price?: PriceType;
  select?: {
    options: Array<{ value: string; text: string }>;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  };
  noStock?: boolean;
}

const TabPane: FC<TabPaneType> = ({ children }) => {
  return children ? <div className="tab-pane">{children}</div> : null;
};

export default TabPane;
