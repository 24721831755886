import classNames from 'classnames';
import { FlexBox, FlexItem } from 'components/Layout/Layout';
import Price from 'components/Price/Price';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { Flipped } from 'react-flip-toolkit';
import { useTranslation } from 'react-i18next';
import { GroupBase, OptionProps, components } from 'react-select';
import { ColorUse } from 'types/Color';
import Icon from '../../Icon/Icon';
import { ProductOption as ProductOptionType } from '../ProductSelect';

export interface DefaultOption {
  label: string;
  value: string;
}

export const ProductOption = <
  OptionType extends ProductOptionType,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  optionProps: OptionProps<OptionType, IsMulti, Group>,
) => {
  const { data } = optionProps;
  const { isAvailable, label, subtitle, price, numberOfVideos } = data;
  const { t } = useTranslation(['translation', 'product']);
  const isCreatNewOption = !data.value;
  return (
    <Flipped flipId={data.value}>
      <div
        className={classNames(
          'product-option__wrap',
          optionProps.isSelected && 'product-option__wrap--selected',
          optionProps.isFocused && 'product-option__wrap--focused',
          optionProps.selectProps.appearance === 'dark' &&
          'product-option__wrap--dark',
          isCreatNewOption && 'product-option__wrap--create',
        )}
      >
        <components.Option {...optionProps} className="product-option">
          {!isCreatNewOption ? (
            <FlexBox
              className="product-option__inner"
              justifyContent="space-between"
            >
              <FlexItem flexGrow={1} className="product-option__content">
                {isAvailable ? (
                  <div className="list-item__title">{label}</div>
                ) : (
                  <FlexBox alignItems="center">
                    <StatusIcon color={ColorUse['dark-20']} icon="block" />
                    <FlexItem className="list-item__title" marginLeft={2}>
                      {label}
                    </FlexItem>
                  </FlexBox>
                )}
                {subtitle && (
                  <div className="list-item__subtitle">{subtitle}</div>
                )}
              </FlexItem>

              {(price || numberOfVideos) && (
                <FlexItem
                  display="flex"
                  alignItems="flex-end"
                  flexDirection="column"
                  flexShrink={0}
                >
                  {price?.currency && <Price {...price} />}
                  {!!numberOfVideos && (
                    <FlexBox
                      alignItems="center"
                      className="product-option__number"
                    >
                      <Icon icon="video" />
                      <FlexItem tag="span" marginLeft={2}>
                        {t('product:numberOfVideos', { count: numberOfVideos })}
                      </FlexItem>
                    </FlexBox>
                  )}
                </FlexItem>
              )}
            </FlexBox>
          ) : (
            label
          )}
        </components.Option>
      </div>
    </Flipped>
  );
};

export default ProductOption;
