import Loader from 'components/Loader/Loader';
import { DefaultOption } from 'components/SelectInteractive/components/Option';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import { MouseEvent, useEffect, useLayoutEffect } from 'react';
import InfiniteScroll, { Props as InfiniteScrollProps } from 'react-infinite-scroll-component';
import { GroupBase, MenuListProps } from 'react-select';

export const InfiniteMenuList = <
  OptionType extends DefaultOption,
  IsMulti extends boolean,
  Group extends GroupBase<OptionType>,
>(
  props: MenuListProps<OptionType, IsMulti, Group>,
) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const modal = document.querySelector('.modal__content');
  const sidebar = document.querySelector('.sidebar');
  const infiniteScrollProps = props.selectProps.infiniteScrollProps;
  const menuId =
    props.selectProps.infiniteScrollTargetId ||
    `scroll--${props.selectProps.id}`;
  const handleScroll = (e: any) => {
    const bottom = (e?.target?.scrollHeight - e?.target?.scrollTop) === e?.target?.clientHeight;
    if (bottom && infiniteScrollProps?.hasMore && !props.selectProps.isLoading) {
      infiniteScrollProps.next();
    }
  }
  useLayoutEffect(() => {
    if (
      (infiniteScrollProps?.hasMore &&
        (modal?.scrollHeight || 0) <= (modal?.clientHeight || 0)) &&
      (!isDesktop || props.selectProps.isOpenSelect)
    ) {

      infiniteScrollProps.next();
    }
  }, [infiniteScrollProps, modal, isDesktop, sidebar, props.selectProps.isOpenSelect]);

  return (
    <div className="select-interactive__menu-list">
      {infiniteScrollProps ? (
        <InfiniteScroll
          {...infiniteScrollProps}
          loader={<Loader small />}
          scrollableTarget={menuId}
          onScroll={handleScroll}
        >
          {props.children}
        </InfiniteScroll>
      ) : (
        <div className="select-interactive__scrollContainer">
          {props.children}
        </div>
      )}
    </div>
  );
};

export default InfiniteMenuList;
