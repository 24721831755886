import { InfiniteData, useMutation, useQuery } from '@tanstack/react-query';
// import { productInfoKeys } from 'features/product-info/queries';
import {
  KEYS as VIDEO_LIST_KEYS,
  videoListKeys,
} from 'features/video-list/queries';
import {
  ApiError,
  PaginatedVideoResponseDTO,
  VideoResponseDTO,
  VendorVideoService,
  VideoUpdateDTO,
} from 'generated';
import { queryClient } from 'index';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';

export enum KEYS {
  updateVideo = 'updateVideo',
}

export const videoDetailKeys = {
  getVideo: (id: number, vendorAgentId?: number) => {
    const [key, vendorId] = videoListKeys.getVideos(vendorAgentId);
    return [key, vendorId, id];
  },
};

export const useGetVideo = (id: number) => {
  const vendorAgent = useReduxSelector(selectVendorAgent);
  return useQuery({
    queryKey: videoDetailKeys.getVideo(id, vendorAgent?.currentVendor?.id),
    queryFn: () => VendorVideoService.getVideo(id),
    initialData: () => {
      const data = queryClient.getQueryData<
        InfiniteData<PaginatedVideoResponseDTO>
      >([VIDEO_LIST_KEYS.getVideos, vendorAgent?.currentVendor?.id]);
      return data?.pages
        .map((d) => d.data)
        .flat()
        .find((i) => i.id === id);
    },
    staleTime: 2 * 60 * 1000, // 2 minutes
    // onSuccess: (video) => {
    //   const product = video?.product;
    //   if (product) {
    //     queryClient.setQueryData<ProductResponseDTO>(
    //       productInfoKeys.getProduct(product.id, vendorAgent?.currentVendor?.id),
    //       (old?: ProductResponseDTO) => ({ ...old, ...product }),
    //     );
    //   }
    // },
  });
};

const fieldMutation = (field: string, vendorAgentId?: number) => ({
  mutationKey: [KEYS.updateVideo, field, vendorAgentId],
  onSuccess: (data: VideoResponseDTO, variables: { videoId: number }) => {
    queryClient.setQueryData<VideoResponseDTO>(
      videoDetailKeys.getVideo(variables.videoId, vendorAgentId),
      (old?: VideoResponseDTO) => ({ ...old, ...data }),
    );
    queryClient.invalidateQueries(videoListKeys.getVideos(vendorAgentId));
  },
});

export const useUpdateVideoName = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useMutation<
    VideoResponseDTO,
    ApiError,
    { videoId: number; name: string }
  >({
    ...fieldMutation('name', vendorAgent?.currentVendor?.id),
    mutationFn: ({ videoId, name }) =>
      VendorVideoService.updateVideoName(videoId, { name }),
  });
};

export const useUpdateVideoLanguage = () => {
  const vendorAgent = useReduxSelector(selectVendorAgent);

  return useMutation<
    VideoResponseDTO,
    ApiError,
    { videoId: number; language: string }
  >({
    ...fieldMutation('language', vendorAgent?.currentVendor?.id),
    mutationFn: ({ videoId, language }) =>
      VendorVideoService.updateVideoLanguage(videoId, { language }),
  });
};
