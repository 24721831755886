/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FeedGraphErrorDto = {
    entityKey: string;
    entityType: string;
    description: FeedGraphErrorDto.description;
};

export namespace FeedGraphErrorDto {

    export enum description {
        FEED_ITEM_FOR_SHARING_TOKEN_NOT_FOUND = 'FEED_ITEM_FOR_SHARING_TOKEN_NOT_FOUND',
    }


}
