import classNames from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { VideoResponseDTO } from '../../generated';
import howToImage from '../../images/product-empty.png';
import Error from '../../types/Error';
import HowTo from '../HowTo/HowTo';
import Loader from '../Loader/Loader';
import { Props as ItemProps } from '../MediathekItem/MediathekItem';
import './mediathek.scss';

export interface Props {
  videos?: VideoResponseDTO[];
  title?: string;
  subtitle?: string;
  count?: {
    itemsVisible?: number;
    total?: number;
  };
  className?: string;
  error?: Error;
  loading?: boolean;
  hasMore?: boolean;
  onLoadMore?: () => void;
  children?: Array<ReactElement<ItemProps>>;
  onResetSelection?: () => void;
  onSelectAll?: () => void;
  showHowTo?: boolean;
}

const Mediathek = ({
  videos,
  title,
  subtitle,
  count,
  className,
  error,
  loading,
  children,
  onLoadMore,
  hasMore,
  onResetSelection,
  onSelectAll,
  showHowTo,
}: Props) => {
  const { t } = useTranslation(['translation', 'video', 'channel', 'list']);

  const handleLoadMore = () => {
    if (onLoadMore) {
      onLoadMore();
    }
  };
  return (
    <div className={classNames('mediathek', className)}>
      {(onSelectAll || onResetSelection || count) && (
        <div className="mediathek__controls">
          {count && (
            <div className="mediathek__count">
              {t('list:amount', {
                itemsVisible: count?.itemsVisible || 0,
                typeWithCount: t('list:video', { count: count?.total || 0 }),
              })}
            </div>
          )}
          {onResetSelection && (
            <button
              type="button"
              onClick={onResetSelection}
              className="caption--primary mediathek__reset"
            >
              {t('list:reset')}
            </button>
          )}
        </div>
      )}
      {error && error.message}
      {loading && !children && <Loader />}
      {!loading && showHowTo && (
        <HowTo
          button={{
            link: '/products',
            text: t('channel:mediathek.toProducts'),
            icon: 'arrow-right',
          }}
          text={t('channel:mediathek.empty')}
          sub={t('channel:mediathek.howTo')}
          image={howToImage}
        />
      )}
      {children && (
        <div className="mediathek__container">
          <ul className="mediathek__list">
            <InfiniteScroll
              dataLength={count?.itemsVisible || 0} //This is important field to render the next data
              next={handleLoadMore}
              hasMore={hasMore || false}
              loader={<Loader />}
              scrollableTarget="infiniteScrollTarget"
              className="mediathek__items"
            >
              {children}
            </InfiniteScroll>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Mediathek;
