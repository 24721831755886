import { useTranslation } from 'react-i18next';
import VisibilityAsyncToggle from '../../features/visibility/VisibilityAsyncToggle/VisibilityAsyncToggle';
import { VideoResponseDTO } from '../../generated';
import Color from '../../types/Color';
import Headline from '../Headline/Headline';
import IconButton from '../IconButton/IconButton';
import LabelText from '../LabelText/LabelText';
import ProductInfo from '../ProductInfo/ProductInfo';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './channel-video-info.scss';
import { Product } from 'generated/gql/gql';
import { useSaleorProduct } from 'api/GraphQL/queries.gql';
import { localeStringToLanguageCodeEnum } from 'container/ProductDetailContainer/utils';
import { isEmpty } from 'ramda';
import { pickProductIsAvailable } from 'api/GraphQL/util';

const ChannelVideoInfo = ({
  onShareClick,
  onToggledVisibility,
  error,
  ...video
}: VideoResponseDTO & {
  onToggledVisibility: (updatedVideo: VideoResponseDTO) => void;
  onShareClick?: () => void;
  error?: {
    badge: string;
    text: string;
  };
}) => {
  const { t } = useTranslation(['translation', 'video']);
  const {
    id,
    name,
    language,
    videoItems,
    encodingStatus,
    videoPreviews,
    visibility,
    saleorProductId
  } = video;
  const { jbeProductId, ...saleorProduct } = useSaleorProduct(saleorProductId!, localeStringToLanguageCodeEnum(language));

  return (
    <div className="channel-video-info">
      <div className="channel-video-info__preview">
        <VideoPlayer
          id={id}
          name={name}
          language={language}
          isAvailable={pickProductIsAvailable(saleorProduct as Product)}
          videoItems={videoItems}
          encodingStatus={encodingStatus}
          videoPreviews={videoPreviews}
          error={error}
          isVisible={visibility === VideoResponseDTO.visibility.PUBLIC}
        >
          {onShareClick && (
            <IconButton
              onClick={onShareClick}
              tooltip={t('video:share.title')}
              big
            />
          )}

          <VisibilityAsyncToggle
            videoId={id}
            onToggledVisibility={onToggledVisibility}
          />
        </VideoPlayer>

        <div className="channel-video-info__text">
          {saleorProduct && (
            <Headline
              headingLevel="h2"
              size={2}
              className="channel-video-info__title"
            >
              {saleorProduct.name}
            </Headline>
          )}

          <div className="channel-video-info__subtitle">
            {saleorProduct && (
              <div className="channel-video-info__subtitle-item">{`${t(
                'video:productId',
              )}: ${saleorProduct.externalReference}`}</div>
            )}

            <div className="channel-video-info__subtitle-item">{`${t(
              'video:name.label',
            )}: ${name}`}</div>
          </div>
        </div>
      </div>

      {saleorProductId && (
        <LabelText
          label={t('video:product.label')}
          className="channel-video-info__block"
          collapsible
        >
          <div className="channel-video-info__pro-edit">
            <IconButton
              icon="edit"
              link={`/products/${saleorProductId}`}
              wrapperClassName="channel-video-info__prod-edit-button"
              color={Color.confirm}
              tooltip={t('edit')}
              tooltipPlace="up"
            />
          </div>
          {!isEmpty(saleorProduct) && <ProductInfo
            {...saleorProduct as Product}
            metadata={[]}
            hideHashtags
            hideVideos
            hideChannels
            small
          />}
        </LabelText>
      )}
    </div>
  );
};

export default ChannelVideoInfo;
