/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FirebaseDynLinkResponseDTO } from './FirebaseDynLinkResponseDTO';
import type { LandingPageResponseDTO } from './LandingPageResponseDTO';

export type VendorReferralResponseDto = {
    target?: VendorReferralResponseDto.target | null;
    id: number;
    createdAt: string;
    referralLinkType: VendorReferralResponseDto.referralLinkType;
    referralLinkObjectKey?: string;
    campaignName: string;
    term?: string;
    content?: string;
    source?: string;
    medium?: string;
    landingPage: LandingPageResponseDTO;
    category?: string;
    firebaseDynLink?: FirebaseDynLinkResponseDTO;
    installationCount: number;
    targetId?: string | null;
};

export namespace VendorReferralResponseDto {

    export enum target {
        FEED = 'feed',
        DISCOVER = 'discover',
        CHANNEL_DETAILS = 'channel-details',
        WISHLIST = 'wishlist',
    }

    export enum referralLinkType {
        CHANNEL = 'channel',
        PRODUCT = 'product',
        APP = 'app',
        VIDEOLIST = 'videolist',
    }


}
