import EncodingBadge from 'components/EncodingBadge/EncodingBadge';
import IconButton from 'components/IconButton/IconButton';
import LabelText from 'components/LabelText/LabelText';
import { Grid, GridItem, FlexBox, FlexItem, Spacer } from 'components/Layout';
import Player from 'components/Player/Player';
import Status from 'components/VideoStatus/VideoStatus';
import Tooltip from 'components/Tooltip/Tooltip';
import VideoDownload from 'components/VideoDownload/VideoDownload';
import { useGetVideo } from 'features/video-detail';
import VideoUpdateForm from 'features/video-detail/VideoUpdateForm/VideoUpdateForm';
import { VideoItemDTO, VideoResponseDTO } from 'generated';
import { BREAKPOINTS } from 'global-constants';
import useFormatInTimeZone from 'hooks/useFormatInTimeZone';
import { useMediaQuery } from 'hooks/useMediaquery';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColorUse } from 'types/Color';
import millisecondsToMinutesAndSeconds from 'utils/time';

const VideoDetailUpdate = ({ videoId }: { videoId: number }) => {
  const { data } = useGetVideo(videoId);
  const date = useFormatInTimeZone({ date: data?.updatedAt, formatStr: 'Pp' });
  const navigate = useNavigate();
  const { t } = useTranslation(['translation', 'video']);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);

  return (
    <div className="video-detail__update">
      {isDesktop && (
        <FlexBox justifyContent="center">
          {data?.status && <Status status={data.status} />}
        </FlexBox>
      )}
      <Grid className="video-detail__grid">
        <GridItem className="video-detail__player">
          {data?.encodingStatus !==
            VideoResponseDTO.encodingStatus.PROCESSING ? (
            <Player
              videoItems={data?.videoItems}
              videoPreviews={data?.videoPreviews}
              name={data?.name}
              showPlayButton
            />
          ) : (
            <div className="video-detail__encoding">
              <EncodingBadge
                encodingStatus={data?.encodingStatus}
                appearance="overlay"
              />
            </div>
          )}
        </GridItem>

        <GridItem className="video-detail__controls">
          <FlexBox>
            <FlexItem pushRight>
              <FlexBox>
                <FlexItem marginRight={1}>
                  <IconButton
                    icon="delete"
                    tooltip={t('video:delete.tooltip')}
                    onClick={() => navigate('delete')}
                    big
                  />
                </FlexItem>
                <FlexItem>
                  <IconButton
                    icon="share"
                    color={ColorUse['primary-50']}
                    appearance="filled"
                    tooltip={t('video:share.title')}
                    onClick={() => navigate('share')}
                    big
                  />
                </FlexItem>
                {(data?.videoItems?.length || 0) > 0 && (
                  <FlexItem marginLeft={1}>
                    <VideoDownload
                      color={ColorUse['primary-25']}
                      appearance="filled"
                      items={[
                        VideoItemDTO.streamType.MP4_HIGH,
                        VideoItemDTO.streamType.MP4_MEDIUM,
                        VideoItemDTO.streamType.MP4_LOW,
                      ].map(
                        (type) =>
                          data?.videoItems?.find(
                            (i) => i.streamType === type,
                          ) as VideoItemDTO,
                      )}
                    />
                  </FlexItem>
                )}
              </FlexBox>
            </FlexItem>
          </FlexBox>
        </GridItem>
        <GridItem alignSelf="end" className="video-detail__updated">
          <Spacer marginLeft={isDesktop ? 2 : 4} marginBottom={2}>
            <div className="video-detail__specs">
              <dt>{t('video:meta.length')}: </dt>
              <dd className="small">
                {millisecondsToMinutesAndSeconds(data?.videoDuration)}
              </dd>
            </div>
          </Spacer>
          <Spacer marginLeft={isDesktop ? 2 : 4}>
            <Tooltip
              content={
                data?.type === VideoResponseDTO.type.CHANNEL
                  ? t('video:meta.lastUpdated.desc.channel')
                  : t('video:meta.lastUpdated.desc.default')
              }
            >
              <LabelText label={t('video:updated.label')}>{date}</LabelText>
            </Tooltip>
          </Spacer>
        </GridItem>
      </Grid>
      {data && (
        <Spacer margin={isDesktop ? [12, 0, 8] : [12, 0, 20]}>
          <VideoUpdateForm
            type={data.type}
            name={data.name}
            language={data.language}
            visibility={data.visibility}
            id={videoId}
          />
        </Spacer>
      )}
    </div>
  );
};

export default VideoDetailUpdate;
