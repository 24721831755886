import { TextInfoDTO } from 'generated';
import { MetadataItem, Product } from 'generated/gql/gql';

export const EditorDataType = {
  header: 'header',
  paragraph: 'paragraph',
  list: 'list',
} as const;
export type EditorDataType = typeof EditorDataType[keyof typeof EditorDataType];
export const EditorDataListType = {
  ordered: 'ordered',
  unordered: 'unordered',
} as const;
export type EditorDataListType =
  typeof EditorDataListType[keyof typeof EditorDataListType];
export type EditorData =
  | {
      type: typeof EditorDataType.header | typeof EditorDataType.paragraph;
      data: {
        text: string;
      };
    }
  | {
      type: typeof EditorDataType.list;
      data: {
        items: string[];
      };
      style: EditorDataListType;
    };
// | {
//     label: string;
//     text: string;
//   };
const isEditorData = (data: any): data is EditorData =>
  data?.type === EditorDataType.header ||
  data?.type === EditorDataType.paragraph ||
  data?.type === EditorDataType.list;

const isTextInfo = (data: any): data is TextInfoDTO =>
  data?.id && (data?.label || data?.text);
export const isEmpty = (data: EditorData): boolean =>
  (data.type === EditorDataType.header && !data.data.text) ||
  (data.type === EditorDataType.paragraph && !data.data.text) ||
  (data.type === EditorDataType.list && !data.data.items.length);

export const textInfoToEditorData = (
  textInfo: TextInfoDTO | EditorData,
): EditorData[] => {
  const infos: EditorData[] = [];
  if (isTextInfo(textInfo)) {
    if (textInfo?.label) {
      infos.push({
        type: EditorDataType.header,
        data: {
          text: textInfo.label,
        },
      });
    }
    if (textInfo?.text) {
      infos.push({
        type: EditorDataType.paragraph,
        data: {
          text: textInfo.text,
        },
      });
    }
  } else if (isEditorData(textInfo)) {
    infos.push(textInfo);
  }
  return infos;
};
export const textInfosToEditorData = (textInfos: TextInfoDTO[]): EditorData[] =>
  textInfos?.flatMap(textInfoToEditorData) ?? [];
export const metadataToEditorData = (
  meta?: MetadataItem | MetadataItem[],
): EditorData[] =>
  Array.isArray(meta)
    ? (meta ?? [])
        .map(({ key, value }) => [
          ...(key
            ? [
                {
                  type: EditorDataType.header,
                  data: {
                    text: key,
                  },
                },
              ]
            : []),
          ...(value
            ? [
                {
                  type: EditorDataType.paragraph,
                  data: {
                    text: value,
                  },
                },
              ]
            : []),
        ])
        .flat()
    : [
        ...(meta?.key
          ? [
              {
                type: EditorDataType.header,
                data: {
                  text: meta?.key,
                },
              },
            ]
          : []),
        ...(meta?.value
          ? [
              {
                type: EditorDataType.paragraph,
                data: {
                  text: meta?.value,
                },
              },
            ]
          : []),
      ];
