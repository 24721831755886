import ActionPanel from 'components/ActionPanel/ActionPanel';
import Button from 'components/Button/Button';
import Footer from 'components/Footer/Footer';
import Grid from 'components/Grid/Grid';
import GridItem from 'components/Grid/GridItem';
import Headline from 'components/Headline/Headline';
import HowTo from 'components/HowTo/HowTo';
import IconButton from 'components/IconButton/IconButton';
import IconTabBar, { IconTab } from 'components/IconTabBar/IconTabBar';
import Search from 'components/Search/Search';
import { BREAKPOINTS } from 'global-constants';
import { useMediaQuery } from 'hooks/useMediaquery';
import howToImage from 'images/video-empty.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MediathekContainer from '../MediathekContainer/MediathekContainer';
import { ChannelDetailTab } from './ChannelVideoContainer';
import ChannelVideoInfoShare from './ChannelVideoInfoShare';
import ChannelVideoList from './ChannelVideoList';
import useChannelProductVideos from './queries';

export interface Props {
  channelId?: number;
  tabs: IconTab<ChannelDetailTab>[];
  activeTab: ChannelDetailTab;
  onTabChange: (tab: ChannelDetailTab) => void;
}

const ChannelProductVideoList = ({
  channelId,
  tabs,
  activeTab,
  onTabChange,
}: Props) => {
  const { t } = useTranslation(['video', 'channel']);
  const isXL = useMediaQuery(`(min-width: ${BREAKPOINTS.xl})`);
  const { searchTerm, setSearchTerm, pages, hasNextPage, saleorIds, listState,
    showHowTo, isOpen, open, close, mediaThekProps,
    deleteCount,
    handleRemove,
    handleLoadMore,
    activeItem,
    setMediathekOpen,
    handleOpenDetails,
    handleSelectionChange,
    handleClose,
    handleUpdateShareUrls,
    handleUpdateVisibility, productMap: records, ...rest } = useChannelProductVideos(channelId)
  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchTerm(event.currentTarget.value);
  };

  return (
    <Grid>
      <GridItem templateArea="header">
        <IconTabBar tabs={tabs} activeTab={activeTab} onChange={onTabChange} />
        <Headline headingLevel="h1" size={2} className="page__title">
          {t('channel:video')}
        </Headline>
      </GridItem>

      {showHowTo && (
        <GridItem column={isXL ? '1/3' : '1/4'}>
          <HowTo
            button={{
              onClick: () => setMediathekOpen(true),
              text: t('video:add'),
            }}
            text={t('channel:video_empty')}
            image={howToImage}
          />
          <MediathekContainer {...mediaThekProps} />
        </GridItem>
      )}

      {!showHowTo && (
        <>
          <GridItem templateArea="main">
            <div className="page__controls page__controls--list">
              <Search
                searchTerm={searchTerm}
                onChange={handleSearchTermChange}
                placeholder={t('video:searchPlaceholder')}
              />
              <Button
                text={t('video:add')}
                icon="add"
                onClick={() => setMediathekOpen(true)}
              />
              <MediathekContainer {...mediaThekProps} />
            </div>

            <div className="page__controls-list page__list">
              <IconButton
                tooltip={
                  deleteCount === 0
                    ? t('video:remove_choose')
                    : t('video:remove', { count: deleteCount })
                }
                icon="delete"
                wrapperClassName="page__controls-delete"
                appearance={deleteCount === 0 ? 'ghost' : 'filled'}
                tooltipPlace="up"
                tooltipDelay={0}
                big={true}
                onClick={handleRemove}
                disabled={deleteCount === 0}
              />

              <ChannelVideoList
                listState={listState}
                handleLoadMore={handleLoadMore}
                activeItem={activeItem}
                productRecords={records}
                handleOpenDetails={handleOpenDetails}
                onSelectionChange={handleSelectionChange}
              />
            </div>
          </GridItem>

          <GridItem templateArea="sidebar">
            {activeItem && (
              <ActionPanel onClose={handleClose} open={isOpen} fixed={false}>
                {channelId && (
                  <ChannelVideoInfoShare
                    channelId={channelId}
                    item={activeItem}
                    shareUrls={activeItem.sharingUrls}
                    updateShareUrls={handleUpdateShareUrls}
                    updateVisibility={handleUpdateVisibility}
                  />
                )}
              </ActionPanel>
            )}
          </GridItem>
        </>
      )}
      <GridItem templateArea="footer">
        <Footer />
      </GridItem>
    </Grid>
  );
};

export default ChannelProductVideoList;
