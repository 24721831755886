/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LatestAppVersionDTO } from '../models/LatestAppVersionDTO';
import type { LatestAppVersionResponseDTO } from '../models/LatestAppVersionResponseDTO';
import type { VersionInfoDTO } from '../models/VersionInfoDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VersionService {

    /**
     * Get the current build version
     * @returns VersionInfoDTO 
     * @throws ApiError
     */
    public static getServerVersion(): CancelablePromise<VersionInfoDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/version',
        });
    }

    /**
     * Set the latest JCA versions (ios/android)
     * @param requestBody 
     * @returns LatestAppVersionResponseDTO 
     * @throws ApiError
     */
    public static setLatestAppVersions(
requestBody: LatestAppVersionDTO,
): CancelablePromise<LatestAppVersionResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/version/latest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the latest JCA versions (ios/android)
     * @returns LatestAppVersionResponseDTO 
     * @throws ApiError
     */
    public static getLatestAppVersions(): CancelablePromise<LatestAppVersionResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/version/latest',
        });
    }

}
