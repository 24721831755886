import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { FlexBox, FlexItem, Spacer } from 'components/Layout/Layout';
import List from 'components/List/List';
import ListItemSelect from 'components/ListItemSelect/ListItemSelect';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { isAvailable } from 'features/availability';
import { useGetMinimalChannels } from 'features/channel-list';
import {
  useUpdateVideoChannels,
  useUpdateVideoChannelsWithContext,
} from 'features/video-channels';
import { ChannelMinimalResponseDTO, VideoResponseDTO } from 'generated';
import { useListSelection } from 'hooks/useListSelection';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StepChildProps, StepKeys } from '../VideoType';
import useAppStatus from 'hooks/useAppStatus';
import { queryClient } from 'index';
import { videoDetailKeys } from 'features/video-detail/queries';
import { useReduxSelector } from 'redux/hooks';
import { selectVendorAgent } from 'redux/slices/auth/authSlice';
import ListItemSelectChannel from 'components/ListItemSelectChannel/ListItemSelectChannel';

const selectFirstActiveChannel = (channels?: ChannelMinimalResponseDTO[]) => {
  const activeChannel = channels?.find((i) => {
    if (i.availability) {
      return isAvailable(i.availability);
    }
    return false;
  });

  const channel = activeChannel || channels?.[0];
  return channel ? channel.id : undefined;
};

const SetChannels = ({
  videoId,
  setStep,
  setErrors,
  setChannelTransfer,
}: StepChildProps) => {
  const { t } = useTranslation(['translation', 'video']);
  const navigate = useNavigate();
  const { data: channels, isLoading, isSuccess } = useGetMinimalChannels();
  const { selectedIds, toggleSelectItem, setSelectedItems } = useListSelection(
    [],
  );
  const [selectAll, setSelectAll] = useState(false);
  const mutation = useUpdateVideoChannels();
  const { setAppStatus } = useAppStatus();
  const vendorAgent = useReduxSelector(selectVendorAgent);

  const handleAddChannels = () => {
    if (selectedIds.length > 0) {
      mutation.mutate(
        {
          videoId,
          channels: channels?.filter((i) => selectedIds.includes(i.id)),
        },
        {
          onError: (error, variables, context) => {
            if (error.status === 409) {
              // this normally happens in the mutation, but not for the 409 error to visualize the conflict in the UI
              // If we do not reset the channels here, they will still be visible in the Video-detail UI when the user cancels the dialog
              queryClient.setQueryData<VideoResponseDTO>(
                videoDetailKeys.getVideo(
                  variables.videoId,
                  vendorAgent?.currentVendor?.id,
                ),
                (context as { previousVideo: VideoResponseDTO })?.previousVideo,
              );
              // because we reset the channels here, we need to transfer the wanted list of channels to StepKeys.INTRO_ERROR
              setChannelTransfer({
                [StepKeys.INTRO_ERROR]: variables.channels,
              });
              setErrors({
                [StepKeys.INTRO_ERROR]: error,
              });
              setStep(StepKeys.INTRO_ERROR);
            } else {
              navigate(`/videos/${videoId}`, { state: { from: 'type' } });
              setAppStatus(t('video:type.setChannel.error'), 'error');
            }
          },
          onSuccess: () => {
            navigate(`/videos/${videoId}`, { state: { from: 'type' } });
          },
        },
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const firstChannel = selectFirstActiveChannel(channels);
      if (firstChannel) {
        setSelectedItems([firstChannel]);
      }
    }
  }, [isSuccess]);

  const handleSelectAll = () => {
    setSelectedItems(!selectAll ? channels?.map((i) => i.id) || [] : []);
  };

  useEffect(() => {
    setSelectAll(selectedIds.length === channels?.length);
  }, [selectedIds]);

  return (
    <FlexBox flexDirection="column">
      <FlexItem className="video-type__select-list">
        <Spacer className="video-type__select-header" borderBottom={1}>
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            name="select-all-channels"
            label={
              <h2 className="caption">
                {t('video:type.channel-select', { count: selectedIds.length })}
              </h2>
            }
            wrapperClassName="video-type__select-all"
          />
        </Spacer>
        <List loading={isLoading}>
          {channels?.map(({ id, name, ...rest }) => (
            <ListItemSelectChannel
              key={id}
              selected={selectedIds.includes(id) || false}
              name={`${id}--${name}`}
              id={`${id}--${name}`}
              onChange={() => toggleSelectItem(id)}
              channel={{ id, name, ...rest }}
            />
          ))}
        </List>
      </FlexItem>
      <FlexItem tag="footer" pushBottom className="video-type__footer">
        <FlexBox justifyContent="space-between">
          <Button
            text={t('cancel')}
            onClick={() => navigate(`/videos/${videoId}`)}
            appearance="ghost"
          />
          <Button text={t('save')} onClick={handleAddChannels} />
        </FlexBox>
      </FlexItem>
    </FlexBox>
  );
};

export default SetChannels;
