import classNames from 'classnames';
import { selectInteractiveStyles } from 'components/SelectInteractive';
import SelectInteractive, {
  Props as SelectInteractiveProps,
} from 'components/SelectInteractive/SelectInteractive';
import { DefaultOption } from 'components/SelectInteractive/components/Option';
import { useTranslation } from 'react-i18next';
import { CSSObjectWithLabel } from 'react-select';
import randomString from 'utils/random-string';
import ProductCreateButton from './components/ProductCreateButton';
import ProductOptionComp from './components/ProductOption';
import './product-select.scss';
import { Money, Product, ProductMediaType } from 'generated/gql/gql';
import { pickProductIsAvailable, pickProductName, pickProductPrice } from 'api/GraphQL/util';
import { useGetProductVideos } from 'features/product-list/queries';
import { getProductVideoCount } from 'features/video-type/utils';

export interface ProductOption extends DefaultOption {
  subtitle: string;
  price?: Money
  isAvailable: boolean;
  numberOfVideos?: number;
}

export type Props<isMulti extends boolean> = Omit<
  SelectInteractiveProps<ProductOption, isMulti>,
  | 'hint'
  | 'isOptionDisabled'
  | 'filterOption'
  | 'isValidNewOption'
  | 'isClearable'
  | 'hideSelectedOptions'
  | 'infiniteScrollProps'
  | 'components'
  | 'onCreateOption'
  | 'showNativeSelectOnMobile'
  | 'maxSelectedOptions'
  | 'maxSelectedOptionsMessage'
  | 'options'
> & {
  /** Adds an infinite scroll function for paginated options.
   * It should update the options by adding the newly fetched options of the next page.
   * This will only be called when hasNextPage is true */
  onLoadMore?: () => void;
  /** Decides wether onLoadMore is called again if infinite scroll is used. */
  hasNextPage?: boolean;
  /** Provide a function of what happens when the create button is clicked*/
  onCreateOption?: () => void;
  /** This should update the inputValue prop. */
  products?: Product[];
  language: string;
  /** The product theme, which is default, is usind the confirm color on the custom product options.
   * Choose the select theme to go back to the default select color theme. */
  optionTheme?: 'product' | 'select';
};

const ProductSelect = <isMulti extends boolean>(props: Props<isMulti>) => {
  const { t } = useTranslation();
  const selectId = props.id || `${props.name}--${randomString()}`;
  const {
    products,
    onCreateOption,
    onInputChange,
    language,
    optionTheme = 'product',
    ...rest
  } = props;
  const saleorProductIds = products?.map((p) => p?.id) || [];
  const query = useGetProductVideos({ saleorProductIds });
  const videos = query.data?.pages?.flatMap(page => page.data) ?? [];
  const countMap = getProductVideoCount(videos);
  const infiniteScrollProps = {
    dataLength: products?.length || 0,
    next: () => props.onLoadMore?.(),
    hasMore: props.hasNextPage || false,
  };
  const getOptions = () => {
    if (!Array.isArray(products)) {
      return [];
    }
    return products.map(
      (product) => ({
        value: product.id,
        label: pickProductName(product) || '',//getProductTitleByLanguage(productLocalisations, language),
        price: pickProductPrice(product),//getProductPriceByLanguage(productLocalisations, language),
        isAvailable: pickProductIsAvailable(product),// isAvailable || false,
        subtitle: product.externalReference || '',
        numberOfVideos: countMap[product.id] ?? 0,//getProductVideoCount
        // (product.media ?? []).filter((m) => m.type === ProductMediaType.Video).length,
      }),
    );
  };

  const handleInputChange = (inputText: string, event: any) => {
    // prevent outside click from resetting inputText to "",
    // prevent setting a value cleans the input
    if (
      event.action !== 'input-blur' &&
      event.action !== 'menu-close' &&
      event.action !== 'set-value'
    ) {
      onInputChange?.(inputText, event);
    }
  };

  return (
    <SelectInteractive
      infiniteScrollTargetId="sidebar--scroll-target"
      {...props}
      className={classNames(
        'product-select',
        optionTheme === 'select' && 'product-select--default-theme',
        props.className,
      )}
      options={getOptions()}
      id={selectId}
      label={undefined}
      description={undefined}
      components={{
        Option: ProductOptionComp,
      }}
      infiniteScrollProps={infiniteScrollProps}
      onCreateOption={onCreateOption}
      onInputChange={handleInputChange}
      isValidNewOption={() => true}
      filterOption={props.onLoadMore ? () => true : undefined}
      formatCreateLabel={() => <ProductCreateButton />}
      styles={{
        menu: (provided, state) => ({
          ...selectInteractiveStyles<ProductOption, isMulti>()?.menu?.(
            provided,
            state,
          ),
          position: 'relative',
          backgroundColor: 'transparent',
        }),
        option: (_, state) => {
          if (optionTheme === 'select') {
            return selectInteractiveStyles<ProductOption, isMulti>()?.option?.(
              _,
              state,
            ) as CSSObjectWithLabel;
          }
          if (state.selectProps.appearance === 'dark') {
            if (state.isSelected)
              return {
                color: 'var(--color-dark)',
                backgroundColor: `var(--color-confirm-50)`,
              };
            return {
              color: 'var(--color-white)',
              backgroundColor: state.isFocused
                ? 'hsl(0,0%,25%)'
                : 'var(--color-dark)',
            };
          }
          if (state.isSelected) {
            return {
              color: 'var(--color-dark)',
              backgroundColor: state.isSelected
                ? state.isFocused
                  ? `var(--color-confirm-25)`
                  : `var(--color-confirm-50)`
                : 'var(--color-white)',
            };
          }
          return {
            color: 'var(--color-dark)',
            backgroundColor: state.isFocused
              ? 'var(--color-primary-10)'
              : 'var(--color-white)',
          };
        },
      }}
    />
  );
};

export default ProductSelect;
