import InputField from 'components/Input/InputField';
import LabelText from 'components/LabelText/LabelText';
import { Spacer } from 'components/Layout/Layout';
import Loader from 'components/Loader/Loader';
import LocalisationTabs from 'components/LocalisationTabs/LocalisationTabs';
import TabPane from 'components/LocalisationTabs/TabPane';
import PageCollapse from 'components/PageCollapse/PageCollapse';
import CategoriesField from 'features/categories/Categories/CategoriesField';
import { HashtagAsyncField } from 'features/hashtags';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import useAppStatus from 'hooks/useAppStatus';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ProductFormControls from './ProductFormControls';
import { ProductForm, } from './utils';
import ProductLocalisation from './ProductLocalisation';
import { useProductForm } from 'api/GraphQL/queries.gql';
import ProductTypeForm from 'components/ProductType/ProductTypeForm';
export interface Props {
  edit?: boolean;
  productId?: string;
}

const ProductDetailContainer = ({ edit, productId }: Props) => {
  const { updateProduct, createProduct, isLoading: loading, createStatus, updateStatus, product, initialForm: initial,
  } = useProductForm(edit ? productId : undefined);
  const { setAppStatus } = useAppStatus();
  const { t } = useTranslation(['translation', 'product', 'edit']);
  const loadingSave = createStatus === 'loading' || updateStatus === 'loading';
  const nameSchema = Yup.object().shape({
    productType: Yup.string().required(t('form.required')).ensure(),
    externalReference: Yup.string().required(t('form.required')).ensure(),
    localisations: Yup.array().of(
      Yup.object().shape({
        shopUrl: Yup.string()
          .required(t('form.required'))
          .url(t('form.url'))
          .ensure(),
        price: Yup.number()
          .required(t('form.required'))
          .typeError(t('form.number'))
          .moreThan(0, t('form.numberPositive')),
        originalPrice: Yup.number().optional().nullable(),
        name: Yup.string().required(t('form.required')),

      })
    ),
    category: Yup.object().shape({
      id: Yup.string().required(t('form.required')).ensure(),
      name: Yup.string().required(t('form.required')).ensure(),
    }),
    saleorChannelId: Yup.string(),
    saleorWarehouseId: Yup.string(),

  });

  const handleAddProduct = async (
    values: ProductForm,
    actions: FormikHelpers<ProductForm>,
  ) => {
    try {
      const res = await createProduct(values, actions)

    } catch (error) {
      setAppStatus(t('product:add.error.save'), 'error');
    }
  };

  const handleUpdateProduct = async (
    values: ProductForm,
    actions: FormikHelpers<ProductForm>,
  ) => {
    try {
      const res = await updateProduct(values, actions)
    } catch (error) {
      setAppStatus(t('product:add.error.save'), 'error');
    }
  };

  const handleSubmit = (
    values: ProductForm,
    actions: FormikHelpers<ProductForm>,
  ) => {
    if (edit) {
      handleUpdateProduct(values, actions);
    } else {
      handleAddProduct(values, actions);
    }
  };
  return (
    <section className="page__section">
      <PageCollapse>
        {loading && <Loader />}
        <Formik
          initialValues={initial}
          validationSchema={nameSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<ProductForm>) => (
            <Form>
              <Spacer>
                <ProductTypeForm />
              </Spacer>
              <Spacer>
                <LocalisationTabs hideActiveTabHeadline >
                  {formik.values.localisations.map((locale, index) => (
                    <TabPane
                      key={locale.iso}
                      id={`locale--${index}`}
                      name={locale.iso}
                    >
                      <ProductLocalisation
                        index={index}
                        currency={locale.currency}
                        key={index}
                      />
                    </TabPane>
                  ))}
                </LocalisationTabs>
              </Spacer>

              <Spacer paddingTop={5} marginTop={8} borderTop={1}>
                <LabelText label={`${t('product:vendor_product_id')}*`}>
                  <p className="page__form-desc">{t('product:id_desc')}</p>
                  <Field
                    component={InputField}
                    placeholder={t('product:placeholder.vendor_product_id')}
                    wrapperClassName="form__group"
                    name="externalReference"
                  />
                </LabelText>
              </Spacer>
              <Spacer paddingTop={5} marginTop={8} borderTop={1}>
                <LabelText label={`${t('product:categories.label')}*`}>
                  <Spacer tag="p" marginBottom={4} className="page__form-desc">
                    {t('product:categories.desc')}
                  </Spacer>
                  <CategoriesField />
                </LabelText>
              </Spacer>
              <Spacer paddingTop={5} marginTop={8} borderTop={1}>
                <LabelText label={t('product:hashtag.label')}>
                  <Spacer marginBottom={4} tag="p" className="page__form-desc">
                    {t('product:hashtag.desc')}
                  </Spacer>
                  <HashtagAsyncField />
                </LabelText>
              </Spacer>
              <ProductFormControls
                formik={formik}
                edit={edit}
                productId={productId}
                loadingSave={loadingSave}
              />
            </Form>
          )}
        </Formik>
      </PageCollapse>
    </section>
  );
};

export default ProductDetailContainer;
