/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SummaryDTO = {
    videoName: string;
    videoId: number;
    vendorProductId?: string;
    views: number;
    clicks: number;
    viewRate: number;
    avgViewDuration: number;
    videoDuration: number;
    isDeleted: boolean;
    productIsAvailable?: boolean;
    visibility: SummaryDTO.visibility;
    isLive: boolean;
    type: SummaryDTO.type;
};

export namespace SummaryDTO {

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }

    export enum type {
        PRODUCT = 'product',
        PROMOTION = 'promotion',
        CHANNEL = 'channel',
    }


}
