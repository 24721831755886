import Icon from 'components/Icon/Icon';
import { Spacer } from 'components/Layout';
import React from 'react';
import './category-branch.scss';
import { Category, useCategoryAncestorsQuery } from 'generated/gql/gql';
import CategoryUtils from 'features/categories/Categories/utils';

const CategoryBranch = ({
  category: _category,
  hideRoot,
}: {
  category: Partial<Category>;
  hideRoot?: boolean;
}) => {
  const { data: { category } = {} } = useCategoryAncestorsQuery({ id: _category?.id }, {
    enabled: !!_category?.id,
    retry: false,
  });
  if (!_category || !category) return <></>;
  const ancestors = CategoryUtils.getAncestors((category as Category) ?? _category, !hideRoot)

  const lineage = [...ancestors, ...hideRoot ? [] : [category]].filter(Boolean) as Category[];
  return lineage && ((lineage.length) > 0) ? (
    <p className="category-branch">
      {lineage.map((item, i) => (
        <React.Fragment key={item.id + i}>
          <span className="category-branch__item">{item.name}</span>

          {i < (lineage.length - 1) && (
            <Spacer
              tag="span"
              className="category-branch__item"
              margin={[0, 1]}
            >
              <Icon icon="caret-right" />
            </Spacer>
          )}
        </React.Fragment>
      ))}
    </p>
  ) : (
    <></>
  )

};

export default CategoryBranch;
