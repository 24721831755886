/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VideoImpressionDTO = {
    videoKey: string;
    channelKey: string;
    viewDurationMS: number;
    entryPoint?: VideoImpressionDTO.entryPoint;
    exitPoint?: VideoImpressionDTO.exitPoint;
    uuid?: string;
    eventTimestamp?: number;
};

export namespace VideoImpressionDTO {

    export enum entryPoint {
        APP_START = 'app_start',
        SWIPE_DIRECTION_EAST = 'swipe_direction_east',
        SWIPE_DIRECTION_WEST = 'swipe_direction_west',
        SWIPE_DIRECTION_NORTH = 'swipe_direction_north',
        SWIPE_DIRECTION_SOUTH = 'swipe_direction_south',
        APP_CHANNELS = 'app_channels',
        APP_CATEGORIES = 'app_categories',
        APP_WISHLIST = 'app_wishlist',
        APP_MORE_MENU = 'app_more_menu',
        APP_FEED = 'app_feed',
    }

    export enum exitPoint {
        APP_START = 'app_start',
        SWIPE_DIRECTION_EAST = 'swipe_direction_east',
        SWIPE_DIRECTION_WEST = 'swipe_direction_west',
        SWIPE_DIRECTION_NORTH = 'swipe_direction_north',
        SWIPE_DIRECTION_SOUTH = 'swipe_direction_south',
        APP_CHANNELS = 'app_channels',
        APP_CATEGORIES = 'app_categories',
        APP_WISHLIST = 'app_wishlist',
        APP_MORE_MENU = 'app_more_menu',
        APP_FEED = 'app_feed',
    }


}
