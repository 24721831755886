import classNames from 'classnames';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import ImageInput from 'components/ImageInput/ImageInput';
import Input from 'components/Input/Input';
import Radio from 'components/Radio/Radio';
import Select from 'components/Select/Select';
import SaleorCategories from 'features/categories/Categories/Categories';
// import { useGetCategoriesMinimal } from 'features/categories/queries';
import { SelectSupportedLanguages } from 'features/language';
import { useFormik } from 'formik';
import {
  ChannelMinimalResponseDTO,
  VendorCreateReferralDto,
  UtmMediumResponseDTO,
} from 'generated';
import { useMinimalCategoriesQuery, useInfiniteMinimalCategoriesQuery, Category, useGetCategoryQuery } from 'generated/gql/gql';
import { useGetMinimalChannels } from 'hooks/queries';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReduxSelector } from 'redux/hooks';
import { selectIsDefaultVendor } from 'redux/slices/auth/authSlice';
import { selectLanguageCode } from 'redux/slices/i18n/i18nSlice';
import { selectUtmSources } from 'redux/slices/utm/utmSlice';
import Color from 'types/Color';
import { InputProps } from 'types/InputType';
import QUERY_KEYS from 'types/QueryKeys';
import getFormError from 'utils/get-form-error';
import * as Yup from 'yup';

const JOOLI_SOURCE = 'jooli';

enum LandingPageSettingType {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

const AddRefLinkForm = ({
  submitLoading,
  onSubmit,
}: {
  submitLoading: boolean;
  onSubmit: (values: VendorCreateReferralDto, callback: Function) => void;
}) => {
  const { t } = useTranslation(['translation', 'settings']);
  const [isExtended, setIsExtended] = useState(false);
  const isDefaultVendor = useReduxSelector(selectIsDefaultVendor);
  const [mediums, setMediums] = useState<
    UtmMediumResponseDTO[] | ChannelMinimalResponseDTO[]
  >();
  const { minimalChannelsData: channels } = useGetMinimalChannels();

  const hasChannels = channels && channels.length > 0;
  const sources = useReduxSelector(selectUtmSources);
  const languageIso = useReduxSelector(selectLanguageCode);
  const [landingPageSettingType, setLandingPageSettingType] =
    useState<LandingPageSettingType>(LandingPageSettingType.DEFAULT);

  const initialAddRefLinkValues = {
    referralLinkType:
      isDefaultVendor && hasChannels
        ? VendorCreateReferralDto.referralLinkType.CHANNEL
        : VendorCreateReferralDto.referralLinkType.APP,
    campaignName: '',
    referralLinkObjectKey:
      channels && channels.length > 0 ? channels[0].key : '',
    category: '',
    content: '',
    medium: '',
    source: '',
    term: '',
    landingPageTitle: '',
    landingPageParagraph: '',
    landingPageImage: '',
    landingPageLanguageIso: languageIso,
  };

  const addRefLinkValidationSchema = Yup.object().shape({
    campaignName: Yup.string().required(t('form.required')),
    referralLinkObjectKey: Yup.string(),
    category: Yup.string(),
    content: Yup.string(),
    medium: Yup.string(),
    source: Yup.string(),
    term: Yup.string(),
    landingPageTitle:
      landingPageSettingType === LandingPageSettingType.CUSTOM
        ? Yup.string().required(t('form.required'))
        : Yup.string(),
    landingPageParagraph:
      landingPageSettingType === LandingPageSettingType.CUSTOM
        ? Yup.string().required(t('form.required'))
        : Yup.string(),
    landingPageImage:
      landingPageSettingType === LandingPageSettingType.CUSTOM
        ? Yup.string().required(t('form.required'))
        : Yup.string(),
    landingPageLanguageIso:
      landingPageSettingType === LandingPageSettingType.DEFAULT
        ? Yup.string().required(t('form.required'))
        : Yup.string(),
  });

  const addRefLinkForm = useFormik({
    initialValues: initialAddRefLinkValues,
    validationSchema: addRefLinkValidationSchema,
    onSubmit: (values) => onSubmit(values, () => addRefLinkForm.resetForm()),

    enableReinitialize: true,
  });
  const { data: { category } = {} } = useGetCategoryQuery({
    id:
      addRefLinkForm.values.category
  }, {
    enabled: !!addRefLinkForm.values.category && !!isExtended,
  });
  const campaignNameInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.campaignName`),
    placeholder: t(`settings:addRefLinkForm.placeholders.text`),
    error:
      addRefLinkForm.touched.campaignName && addRefLinkForm.errors.campaignName
        ? t(addRefLinkForm.errors.campaignName)
        : undefined,
    ...addRefLinkForm.getFieldProps('campaignName'),
  };

  const channelInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.channel`),
    error:
      addRefLinkForm.touched.referralLinkObjectKey &&
        addRefLinkForm.errors.referralLinkObjectKey
        ? t(addRefLinkForm.errors.referralLinkObjectKey)
        : undefined,
    ...addRefLinkForm.getFieldProps('referralLinkObjectKey'),
  };

  const sourceInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.source`),
    error:
      addRefLinkForm.touched.source && addRefLinkForm.errors.source
        ? t(addRefLinkForm.errors.source)
        : undefined,
    ...addRefLinkForm.getFieldProps('source'),
  };

  const mediumInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.medium`),
    error:
      addRefLinkForm.touched.medium && addRefLinkForm.errors.medium
        ? t(addRefLinkForm.errors.medium)
        : undefined,
    ...addRefLinkForm.getFieldProps('medium'),
  };

  const termInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.term`),
    error:
      addRefLinkForm.touched.term && addRefLinkForm.errors.term
        ? t(addRefLinkForm.errors.term)
        : undefined,
    ...addRefLinkForm.getFieldProps('term'),
    placeholder: t(`settings:addRefLinkForm.placeholders.text`),
  };

  const contentInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.content`),
    error:
      addRefLinkForm.touched.content && addRefLinkForm.errors.content
        ? t(addRefLinkForm.errors.content)
        : undefined,
    ...addRefLinkForm.getFieldProps('content'),
    placeholder: t(`settings:addRefLinkForm.placeholders.text`),
  };

  const categoryInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.category`),
    error:
      addRefLinkForm.touched.category && addRefLinkForm.errors.category
        ? t(addRefLinkForm.errors.category)
        : undefined,
    ...addRefLinkForm.getFieldProps('category'),
  };

  const landingPageTitleInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.landingPageTitle`),
    error: getFormError(addRefLinkForm, 'landingPageTitle'),
    placeholder: t(`settings:addRefLinkForm.placeholders.text`),
    ...addRefLinkForm.getFieldProps('landingPageTitle'),
  };

  const landingPageParagraphInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.landingPageParagraph`),
    error: getFormError(addRefLinkForm, 'landingPageParagraph'),
    placeholder: t(`settings:addRefLinkForm.placeholders.text`),
    ...addRefLinkForm.getFieldProps('landingPageParagraph'),
  };

  const landingPageImageInputProps: InputProps & {
    placeholder: string;
  } = {
    label: t(`settings:addRefLinkForm.labels.landingPageImage`),
    placeholder: t(`settings:addRefLinkForm.placeholders.landingPageImage`),
    error: getFormError(addRefLinkForm, 'landingPageImage'),
    ...addRefLinkForm.getFieldProps('landingPageImage'),
  };

  const landingPageLanguageIsoInputProps: InputProps = {
    label: t(`settings:addRefLinkForm.labels.landingPageLanguageIso`),
    error: getFormError(addRefLinkForm, 'landingPageLanguageIso'),
    ...addRefLinkForm.getFieldProps('landingPageLanguageIso'),
  };

  let sourceOptions = sources
    ? sources.map((source) => ({
      text: source.name,
      value: source.name,
    }))
    : [];

  if (isDefaultVendor) {
    sourceOptions = [
      ...sourceOptions,
      { text: JOOLI_SOURCE, value: JOOLI_SOURCE },
    ];
  }

  const mediumOptions = mediums
    ? mediums.map((medium) => ({
      text: medium.name,
      value: medium.name,
    }))
    : [];

  const channelOptions = channels
    ? channels.map((channel) => ({
      text: channel.name,
      value: channel.key,
    }))
    : [];


  // useEffect(() => {
  //   addRefLinkForm.setFieldValue('category', category?.id);
  // }, [category])

  const handleSourceChange = (e: React.ChangeEvent<any>) => {
    sourceInputProps.onChange(e);

    const val = e.target.value;

    if (val === JOOLI_SOURCE) {
      setMediums(channels);
      return;
    }

    if (val === '') {
      addRefLinkForm.setFieldValue('medium', '');
      setMediums(undefined);
      return;
    }

    const source = sources?.find((source) => source.name === e.target.value);

    if (!source) {
      console.log('source not found', e.target.value);
      return;
    }

    addRefLinkForm.setFieldValue('medium', '');
    setMediums(source.mediums);
  };

  const handleImageChange = (paths: { fileUrl: string; filePath: string }) => {
    addRefLinkForm.setFieldValue('landingPageImage', paths.fileUrl);
  };

  const handleRadioChange = async (value: LandingPageSettingType) => {
    setLandingPageSettingType(value);
    addRefLinkForm.setErrors(
      await addRefLinkForm.validateForm(addRefLinkForm.values),
    );

    if (value === LandingPageSettingType.CUSTOM) {
      addRefLinkForm.setFieldValue('landingPageLanguageIso', '');
    } else {
      addRefLinkForm.setFieldValue('landingPageLanguageIso', languageIso);
      addRefLinkForm.setFieldValue('landingPageTitle', '');
      addRefLinkForm.setFieldValue('landingPageParagraph', '');
      addRefLinkForm.setFieldValue('landingPageImage', '');
    }
  };

  return (
    <div className="add-ref-link">
      <p className="modal__text">
        {isDefaultVendor
          ? t('settings:addRefLinkForm.text')
          : t('settings:addRefLinkForm.text--sales')}
      </p>

      <form className="settings__form" onSubmit={addRefLinkForm.handleSubmit}>
        <div className="settings__inline-form">
          <div className="settings__block--50">
            <div className="add-ref-link__input">
              <Input {...campaignNameInputProps} />
            </div>
          </div>

          <div className="settings__block--50">
            {isDefaultVendor && (
              <div className="add-ref-link__input">
                <Select
                  options={channelOptions}
                  emptyOptionText={t('settings:addRefLinkForm.no-channel-text')}
                  enableEmptyOption
                  {...channelInputProps}
                />
              </div>
            )}
          </div>
        </div>

        <button
          type="button"
          className="input__label add-ref-link__extend-button"
          onClick={() => setIsExtended((prev) => !prev)}
        >
          <span>{t('settings:addRefLinkForm.extend-button-text')}</span>
          <span className="add-ref-link__extend-icon">
            <Icon
              icon="caret-down"
              style={{
                transform: `rotate(${isExtended ? 180 : 0}deg)`,
              }}
            />
          </span>
        </button>

        {isExtended && (
          <div className="settings__form">
            <div className="settings__inline-form">
              <div className="settings__block--50">
                <div className="add-ref-link__input">
                  <Select
                    options={sourceOptions}
                    enableEmptyOption
                    {...sourceInputProps}
                    onChange={handleSourceChange}
                  />
                </div>
              </div>

              <div className="settings__block--50">
                <div className="add-ref-link__input">
                  <Select
                    disabled={mediums === undefined}
                    options={mediumOptions}
                    enableEmptyOption
                    {...mediumInputProps}
                  />
                </div>
              </div>

              <div className="settings__block--50">
                <div className="add-ref-link__input">
                  <Input {...termInputProps} />
                </div>
              </div>

              <div className="settings__block--50">
                <div className="add-ref-link__input">
                  <Input {...contentInputProps} />
                </div>
              </div>

              <div className="settings__block--50">
                <div className="add-ref-link__input">
                  <div className="input__label">
                    <label
                      className={classNames({
                        input__label: true,
                      })}
                    >
                      {t('product:categories.label')}
                    </label>
                    <SaleorCategories
                      value={category as Category}
                      onSelectChange={
                        cat => addRefLinkForm.setFieldValue('category', cat?.id)

                      }
                      emptyMessage={t('product:categories.search')}
                    />
                  </div>
                  {/* <Select
                    options={categoryOptions}
                    enableEmptyOption
                    {...categoryInputProps}
                  /> */}
                </div>
              </div>
            </div>
            <div className="settings__block input__label">
              <h1 className="modal__caption">
                {t('settings:addRefLinkForm.headlines.h2')}
              </h1>
            </div>
            <div>
              <Radio
                name="landingPageSettingType"
                checked={
                  landingPageSettingType === LandingPageSettingType.DEFAULT
                }
                onChange={() =>
                  handleRadioChange(LandingPageSettingType.DEFAULT)
                }
                label={t('settings:addRefLinkForm.labels.radio-default')}
              />

              <div className="settings__inline-form">
                <div className="settings__block--50">
                  <div className="add-ref-link__input">
                    <SelectSupportedLanguages
                      {...landingPageLanguageIsoInputProps}
                      disabled={
                        landingPageSettingType === LandingPageSettingType.CUSTOM
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Radio
                name="landingPageSettingType"
                checked={
                  landingPageSettingType === LandingPageSettingType.CUSTOM
                }
                onChange={() =>
                  handleRadioChange(LandingPageSettingType.CUSTOM)
                }
                label={t('settings:addRefLinkForm.labels.radio-custom')}
              />

              <div className="settings__inline-form">
                <div className="settings__block--50">
                  <div className="add-ref-link__input">
                    <Input
                      {...landingPageTitleInputProps}
                      disabled={
                        landingPageSettingType ===
                        LandingPageSettingType.DEFAULT
                      }
                    />
                  </div>
                </div>

                <div className="settings__block--50">
                  <div className="add-ref-link__input">
                    <Input
                      {...landingPageParagraphInputProps}
                      disabled={
                        landingPageSettingType ===
                        LandingPageSettingType.DEFAULT
                      }
                    />
                  </div>
                </div>

                <div className="settings__block">
                  <ImageInput
                    {...landingPageImageInputProps}
                    className="ref-links__form-image"
                    onChange={handleImageChange}
                    maxFileSize={3_000_000}
                    disabled={
                      landingPageSettingType === LandingPageSettingType.DEFAULT
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="modal__controls">
          <Button
            loading={submitLoading}
            text={t('add')}
            type="submit"
            color={Color.primary}
            appearance="ghost"
          />
        </div>
      </form>
    </div>
  );
};

export default AddRefLinkForm;
