/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InternalCustomerAddressRequestDto = {
    /**
     * The internal id of the entities. To create a new entities use 0 as a value.
     */
    id: number;
    /**
     * The full qualified country name. Example: USA
     */
    country: string;
    /**
     * The 2 digit iso code. Example: US
     */
    countryCode: string;
    /**
     * The normalized full qualified country name. Example: United States of America
     */
    countryName: string;
    /**
     * The state or province name. Example: Berlin
     */
    province: string;
    /**
     * The state or province code. Example: BE
     */
    provinceCode: string;
    referenceType: InternalCustomerAddressRequestDto.referenceType;
    /**
     * The internal id of the entities.
     */
    referenceId: number;
    phone: string;
    defaultInvoice: boolean;
    defaultShipping: boolean;
    firstName: string;
    lastName: string;
    company: string;
    gstin?: string | null;
    address1: string;
    address2: string;
    city: string;
    zip: string;
};

export namespace InternalCustomerAddressRequestDto {

    export enum referenceType {
        VENDOR = 'vendor',
        APP_CUSTOMER = 'app_customer',
    }


}
