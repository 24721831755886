// TODO move to location where they are used
export enum QUERY_KEYS {
  getVendorAgents = 'getVendorAgents',
  getRoles = 'getRoles',
  getMinimalChannels = 'getMinimalChannels',
  getMinimalCategories = 'getMinimalCategories',
  getCategories = 'getCategories',
  getRefLinks = 'getRefLinks',
  getAppVersions = 'getAppVersions',
  getChannels = 'getChannels',
  getVideos = 'getVideos',
  getProductVideos = 'getProductVideos',
  getVideo = 'getVideo',
  updateVideo = 'updateVideo',
  getSharingLinks = 'getSharingLinks',
  getProducts = 'getProducts',
  getProduct = 'getProduct',
  updateProducts = 'updateProducts',
  updateProductAvailability = 'updateProductAvailability',
  updateCategories = 'updateCategories',
  getHashtags = 'getHashtags',
  getHashtagsForEntity = 'getHashtagsForEntity',
  getHashtagSettings = 'getHashtagSettings',
  addHashtag = 'addHashtag',
  updateHashtagsOfVideo = 'updateHashtagsOfVideo',
  updateHashtagsOfProduct = 'updateHashtagsOfProduct',
  updateHashtagSettings = 'updateHashtagSettings',
  linkHashtagsToProduct = 'linkHashtagsToProduct',
  unlinkHashtagsFromProduct = 'unlinkHashtagsFromProduct',
  linkHashtagsToProducts = 'linkHashtagsToProducts',
  unlinkHashtagsFromProducts = 'unlinkHashtagsFromProducts',
}

export default QUERY_KEYS;
