import { Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionPanel from '../../components/ActionPanel/ActionPanel';
import Button from '../../components/Button/Button';
import Footer from '../../components/Footer/Footer';
import Grid from '../../components/Grid/Grid';
import GridItem from '../../components/Grid/GridItem';
import Headline from '../../components/Headline/Headline';
import HowTo from '../../components/HowTo/HowTo';
import List from '../../components/List/List';
import Loader from '../../components/Loader/Loader';
import VideoListItem from '../../components/VideoListItem/VideoListItem';
import { BREAKPOINTS } from '../../global-constants';
import {
  ChannelMinimalResponseDTO,
  ChannelsService,
  HashtagResponseDTO,
  VideoIdsDTO,
  VideoResponseDTO,
  VendorVideoService,
} from '../../generated';
import useAppStatus from '../../hooks/useAppStatus';
import useList from '../../hooks/useList';
import { useMediaQuery } from '../../hooks/useMediaquery';
import useOpen from '../../hooks/useOpen';
import howToImage from '../../images/video-empty.png';
import videoListReducer from '../../reducer/reducer-video-list';
import { useReduxDispatch, useReduxSelector } from '../../redux/hooks';
import { logOut, selectVendorAgent } from '../../redux/slices/auth/authSlice';
import { selectSupportedLanguages } from '../../redux/slices/i18n/i18nSlice';
import Color from '../../types/Color';
import notAuthenticated from '../../utils/not-authenticated';
import VideoUploadContainer from '../VideoUploadContainer/VideoUploadContainer';
import ProductVideoEdit from './ProductVideoEdit';
import { queryClient } from 'index';
import { videoDetailKeys } from 'features/video-detail/queries';
import { useSaleorProduct, useSaleorProducts } from 'api/GraphQL/queries.gql';
import MediathekContainer from 'container/MediathekContainer/MediathekContainer';
import { localeStringToLanguageCodeEnum } from 'container/ProductDetailContainer/utils';

export interface Props {
  productId?: string;
}

const ProductVideoContainer = ({ productId }: Props) => {
  const { t } = useTranslation(['translation', 'video']);
  const dispatch = useReduxDispatch();
  const { setAppStatus } = useAppStatus();
  // const { jbeProductId, } = useSaleorProduct(productId);
  const [loadingButton, setLoadingButton] = useState<{
    save?: boolean;
    delete?: boolean;
  }>({});
  const [showHowTo, setShowHowTo] = useState<boolean>(false);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.m})`);
  const { listState, listFunctions, activeItem } = useList<VideoResponseDTO>({
    reducer: videoListReducer,
  });
  const { get, setActiveItem, deleteItem, reload } = listFunctions;
  const { record } = useSaleorProducts([...new Set(listState.items?.map(i => i.saleorProductId).filter(Boolean) as string[])],
    localeStringToLanguageCodeEnum(listState.items?.[0]?.language)
  );
  const {
    isOpen: modalIsOpen,
    open: openModal,
    close: closeModal,
  } = useOpen(false);
  const {
    isOpen: panelIsOpen,
    open: openPanel,
    close: closePanel,
  } = useOpen(isDesktop);
  const [allChannels, setAllChannels] = useState<ChannelMinimalResponseDTO[]>();
  const supportedLanguages = useReduxSelector(selectSupportedLanguages);
  const vendorAgent = useReduxSelector(selectVendorAgent);
  const [initialValues, setInitialValues] = useState({
    hashtags: activeItem?.hashtags || [],
  });

  useEffect(() => {
    if (!listState.items && productId) {
      getVideos([productId]);
    }
  }, [productId, listState.items]);
  const getVideos = async (saleorIds?: string[]) => {
    await get({
      service: () => VendorVideoService.getVideos(0, undefined, undefined, undefined, saleorIds),
      props: {
        onSuccess: (res) => {
          if (res.data.length === 0) {
            setShowHowTo(true);
          }

        },
      },
    });
  }
  useEffect(() => {
    const getChannels = async () => {
      try {
        const res = await ChannelsService.getMinimalChannels();
        if (res) {
          setAllChannels(res);
        }
      } catch (error) {
        console.log(error); // TODO
      }
    };
    getChannels();
  }, []);

  useEffect(() => {
    setShowHowTo(listState.items?.length === 0);
  }, [listState.items]);

  useEffect(() => {
    if (activeItem) {
      setInitialValues({ hashtags: activeItem?.hashtags || [] });
    }
  }, [activeItem]);

  const handleUpdateVideo = async (
    channelsToUpdate?: {
      channelsToAdd?: ChannelMinimalResponseDTO[];
      channelsToRemove?: ChannelMinimalResponseDTO[];
    },
    newChannelList?: ChannelMinimalResponseDTO[],
  ) => {
    if (activeItem) {
      setLoadingButton({ delete: false, save: true });
      const updateVideo = async () => {
        const { language, name, type, hashtags, saleorProductId } = activeItem;

        return await VendorVideoService.updateVideo(activeItem.id, {
          language,
          name,
          type,
          hashtagIds: hashtags?.map((i) => i.id),
          saleorProductId: saleorProductId,
        });
      };
      const updateChannels = async (
        channels: ChannelMinimalResponseDTO[],
        action: (id: number, requestBody: VideoIdsDTO) => Promise<VideoIdsDTO>,
      ) => {
        return await Promise.all(
          channels.map(async (c) => {
            return await action(c.id, { videoIds: [activeItem.id] });
          }),
        );
      };
      const addToChannels = async () => {
        return await updateChannels(
          channelsToUpdate?.channelsToAdd || [],
          ChannelsService.addVideos,
        );
      };
      const removeFromChannels = async () => {
        return await updateChannels(
          channelsToUpdate?.channelsToRemove || [],
          ChannelsService.removeVideos,
        );
      };
      // TODO also possible with new updateItem<T> with dependency injection ??
      await Promise.all([updateVideo(), addToChannels(), removeFromChannels()])
        .then(async (responses) => {
          // updateVideoItem({ res: responses[0], newChannelList });
          setLoadingButton({});
          setAppStatus(t('video:saveMsg.success'), 'success');
          queryClient.invalidateQueries(
            videoDetailKeys.getVideo(
              activeItem.id,
              vendorAgent?.currentVendor?.id,
            ),
          );
          reload({ service: () => VendorVideoService.getVideos(0, undefined, undefined, undefined, productId ? [productId] : []), });
        })
        .catch((error) => {
          if (notAuthenticated(error)) {
            dispatch(logOut());
          } else {
            setLoadingButton({});
            setAppStatus(t('video:saveMsg.error'), 'error');
          }
        });
    }
  };

  const handleChangeVideoInputs = (
    field: keyof VideoResponseDTO,
    value: VideoResponseDTO[keyof VideoResponseDTO],
  ) => {
    setActiveItem((prevState) => {
      if (!prevState) {
        return;
      }

      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  const handleLoadMore = () => {
    if (productId) {
      get({
        service: () =>
          // TODO check domi: ProductVideoService zu VendorVideoService korrekt?
          VendorVideoService.getVideos(listState.items?.length, undefined, undefined, undefined, [productId]),
      });
    }
  };

  const handleDelete = () => {
    if (activeItem) {
      setLoadingButton({ delete: true, save: false });

      deleteItem({
        service: () => VendorVideoService.deleteVideo(activeItem.id),
        props: {
          onSuccess: (res) => {
            setLoadingButton({});
            setAppStatus(t('video:delete.success'), 'success');
          },
          onError: (res) => {
            setLoadingButton({});

            if (res.status === 409) {
              setAppStatus(t('video:delete.error--active'), 'error');
            } else {
              setAppStatus(t('video:delete.error'), 'error');
            }
          },
        },
      });
    }
  };

  const handleOpenDetails = (item: VideoResponseDTO) => {
    setActiveItem(item);
    openPanel();
  };

  const handleClose = () => {
    closePanel();
    setTimeout(() => {
      setActiveItem(undefined);
    }, 600);
  };

  useEffect(() => {
    if (!activeItem) {
      closePanel();
    }
  }, [activeItem]);

  const handleOnCloseUploadModal = () => {
    closeModal();

    if (productId) {
      get({
        service: () =>
          VendorVideoService.getVideos(undefined, undefined, undefined, undefined, [productId]),
        props: {
          initial: true,
          onSuccess: (res) => {
            setShowHowTo(res.data.length === 0);
          },
        },
      });
    }
  };

  const languageNotSupported = (language: string) => {
    console.log(!supportedLanguages.some((i) => i.iso === language));
    return !supportedLanguages.some((i) => i.iso === language);
  };

  if (showHowTo) {
    return (
      <Grid style={{ marginTop: '-3.2rem' }}>
        <GridItem column="1 / 4">
          <HowTo
            button={{ onClick: openModal, text: t('video:add') }}
            text={t('product:video_empty')}
            image={howToImage}
          />
          {productId && modalIsOpen && (
            <MediathekContainer onClose={
              handleOnCloseUploadModal
            } open={modalIsOpen} productId={productId}
            />
          )}

        </GridItem>
        <GridItem column="1 / 4">
          <Footer />
        </GridItem>
      </Grid>
    );
  }

  return (
    <Grid>
      {listState.loading && !listState.initialyLoaded && (
        <GridItem className="page__content" column="1/4">
          <Loader />
        </GridItem>
      )}
      <GridItem templateArea="header">
        <div>
          <Headline headingLevel="h1" size={2} className="page__title">
            {t('product:video')}
          </Headline>
          <div className="page__controls">
            <Button text={t('video:manage')} icon="add" onClick={openModal} />
            {productId && modalIsOpen && (
              <MediathekContainer onClose={
                handleOnCloseUploadModal
              } open={modalIsOpen} productId={productId}
              />

            )}
          </div>

          <List
            count={{
              itemsVisible: listState.items?.length,
              total: listState.totalCount,
            }}
            handleLoadMore={handleLoadMore}
            loading={listState.loading}
            // error={error}
            showLoadMore={listState.loadMore}
            className="page__list"
            type="video"
          >
            {listState.items &&
              listState.items.map((item, index) => {
                const listProps = {
                  active: item.id === activeItem?.id,
                  key: index,
                  title: item.name,
                  subtitle: item.saleorProductId
                    ? record?.[item.saleorProductId!].externalReference!
                    : item.meta,
                  image: item.videoPreviews?.find((i) => i.size === 'small')
                    ?.uri,
                  onClick: () => handleOpenDetails(item),
                  language: item.language,
                  deactivated: item.product && !item.product.isAvailable,
                  channels: item.channels,
                  encodingStatus: item.encodingStatus,
                  languageNotSupportedError: !supportedLanguages.some(
                    (i) => i.iso === item.language,
                  ),
                };
                return <VideoListItem {...listProps} />;
              })}
          </List>
        </div>
      </GridItem>

      <GridItem templateArea="sidebar">
        {activeItem && (
          <ActionPanel onClose={handleClose} open={panelIsOpen} fixed={false}>
            {/* TODO manage all values with Formik!*/}
            <Formik
              initialValues={initialValues}
              onSubmit={() => { }}
              enableReinitialize={true}
            >
              {(formik: FormikProps<{ hashtags: HashtagResponseDTO[] }>) => (
                <ProductVideoEdit
                  values={activeItem}
                  allChannels={allChannels}
                  onChange={handleChangeVideoInputs}
                  onSubmit={handleUpdateVideo}
                  onDelete={handleDelete}
                  key={activeItem.id}
                  loadingButton={loadingButton}
                />
              )}
            </Formik>
          </ActionPanel>
        )}
      </GridItem>
      <GridItem templateArea="footer">
        <Footer />
      </GridItem>
    </Grid>
  );
};

export default ProductVideoContainer;
