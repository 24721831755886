import { createSlice } from '@reduxjs/toolkit';
import { init, logIn } from '../auth/authSlice';
import { RootState } from 'redux/store';

export interface AuthState {
  apiBaseUrl?: string;
  saleorChannel?: string;
  saleorChannelId?: string;
  saleorWarehouseId?: string;
}

const initialState: AuthState = {
  apiBaseUrl: undefined,
  saleorChannel: undefined,
  saleorChannelId: undefined,
  saleorWarehouseId: undefined,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(init.fulfilled, (state, action) => {
        state.apiBaseUrl = action.payload.apiBaseUrl;
        state.saleorChannel = action.payload.saleorChannel;
        state.saleorChannelId = action.payload.saleorChannelId;
        state.saleorWarehouseId = action.payload.saleorWarehouseId;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.apiBaseUrl = action.payload.apiBaseUrl;
        state.saleorChannel = action.payload.saleorChannel;
        state.saleorChannelId = action.payload.saleorChannelId;
        state.saleorWarehouseId = action.payload.saleorWarehouseId;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = globalSlice.actions;

export const selectSaleorChannel = (state: RootState) =>
  state.global.saleorChannel!;
export const selectSaleorChannelId = (state: RootState) =>
  state.global.saleorChannelId!;
export const selectSaleorWarehouseId = (state: RootState) =>
  state.global.saleorWarehouseId!;
export default globalSlice.reducer;
